import {Injectable} from '@angular/core';
import {
  Contact,
  Conversation,
  GetOrCreateConversationOptions,
  Session,
  Stream,
  StreamInfo
} from '@apirtc/apirtc';
import {Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {consultationActions} from 'src/app/store/actions';
import {consultationSelectors, userMediaDevicesSelectors} from 'src/app/store/selectors';
import {
  ApiRTCConversationEvents,
  ApiRTCCustomEvents,
  ApirtcCustomEventData,
  ApirtcStreamListeventTypes,
  ConversationHangup,
  ExitConsultationEventData,
  ExitConsultationReasons
} from '../../models/apirtc.models';
import {AppState} from '../../models/app-state.models';
import {AppLoaderService} from '../loader.service';
import {AppStreamsService} from '../streams.service';
import {ApirtcSessionService} from './session.service';
import {take} from 'rxjs';
import {ActivityMonitor} from '../activity.service';

@Injectable()
export class ApirtcConversationService {
  private session: Session;
  private conversationOptions: GetOrCreateConversationOptions = {
    meshModeEnabled: false,
    meshOnlyEnabled: false,
    moderationEnabled: false,
    moderator: false
  };
  private conversation: Conversation = null;
  private conversationPublishedStream: Stream;
  private appointmentUUID: string;
  private replacingStream = false;

  constructor(
    private sessionService: ApirtcSessionService,
    private logger: NGXLogger,
    private streamsService: AppStreamsService,
    private store: Store<AppState>,
    private appLoader: AppLoaderService,
    private activityMonitor: ActivityMonitor
  ) {
    this.store.select(userMediaDevicesSelectors.selectDefaultDevices).subscribe(async (r) => {
      // TODO: this is not necessary when u are doing for first time, check join conversation
      if (this.conversation) {
        this.streamsService.releaseLocalStream();
        const stream = await this.streamsService.createStream(r, true);
        await this.replaceCurrentStream(stream);
      }
    });
  }

  async joinConversation({appointmentUUID}: CreateConversationParams): Promise<Conversation> {
    try {
      this.appointmentUUID = appointmentUUID;
      this.session = this.sessionService.session;
      this.appLoader.showLoader();
      this.conversation = this.session.getOrCreateConversation(appointmentUUID);
      this.setListeners(this.conversation);
      let stream: Stream = this.streamsService.localStream;
      if (!stream) {
        stream = await this.streamsService.createStream();
      }
      const status = await this.conversation.join().catch((error) => {
        this.logger.error('Failed to join the conversation', error);
      });

      await this.publishStreamInConversation(stream);
      this.appLoader.hideLoader();

      this.store.dispatch(
        consultationActions.initConsultation({
          conversationId: this.conversation.getCloudConversationId()
        })
      );
      this.logger.debug('Joined conversation', this.conversation);
      this.activityMonitor.enableActivityMonitor();
      return this.conversation;
    } catch (error) {
      this.appLoader.hideLoader();
      this.logger.error('Failed to join conversation', error);
    }
  }

  async leaveActiveConversation() {
    try {
      if (!this.conversation) {
        return;
      }

      if (this.conversation?.isJoined()) {
        const status = await this.conversation?.leave();
      }
      this.conversation?.destroy();

      // // TODO: Remove all conversation listeners
      this.conversation = null;
    } catch (error) {
      this.conversation?.destroy();
      this.conversation = null;
      this.logger.error('Force destroyed conversation', error);
    }
  }

  sendExitEvent(args: {reason: ExitConsultationReasons}) {
    if (!this.conversation) {
      return;
    }
    const exitConsultationContent: ExitConsultationEventData = {
      reason: args.reason
    };
    this.conversation.sendCustomEvent(ApiRTCCustomEvents.exitConversation, exitConsultationContent);
    this.store.dispatch(consultationActions.completeConversation({reason: args.reason}));
  }

  // Publishes additional stream in the conversation
  private async publishStreamInConversation(stream: Stream) {
    try {
      this.appLoader.showLoader();
      this.conversationPublishedStream = await this.conversation.publish(stream);
      this.appLoader.hideLoader();
      this.streamsService.setNewLocalStream(stream);
      this.logger.debug('Stream published in conversation', this.conversationPublishedStream);
      return this.conversationPublishedStream;
    } catch (error) {
      this.appLoader.hideLoader();
      this.logger.error('Failed to publish stream in conversation', error);
    }
  }

  // Rreplaces the current stream in the conversation
  async replaceCurrentStream(stream: Stream) {
    try {
      const conversationCall = this.conversation.getConversationCall(
        this.conversationPublishedStream
      );
      if (!conversationCall) {
        this.logger.error(
          'Failed to replace stream in conversation, no converstaion',
          conversationCall
        );
        return;
      }
      if (this.replacingStream === true) {
        this.logger.error('Failed to replace stream in process is already ongoing', stream);
        return;
      }
      this.replacingStream = true;
      this.appLoader.showLoader();
      // published stream should be set as local stream to handle release correctly
      setTimeout(async () => {
        this.conversationPublishedStream = await conversationCall.replacePublishedStream(stream);
        this.appLoader.hideLoader();
        this.streamsService.setNewLocalStream(this.conversationPublishedStream);
        this.logger.debug('Stream replaced in conversation', this.conversationPublishedStream);
        this.replacingStream = false;
      }, 100);
    } catch (error) {
      this.appLoader.hideLoader();
      this.logger.error('Failed to publish stream in conversation', error);
    }
  }

  async reconnect() {
    this.appLoader.showLoader();
    await this.leaveActiveConversation();
    await this.streamsService.releaseLocalStream();
    await this.joinConversation({
      appointmentUUID: this.appointmentUUID
    });
    this.appLoader.hideLoader();
  }

  private async streamListChanged(streamInfo: StreamInfo) {
    if (!streamInfo.isRemote) {
      return;
    }
    this.logger.debug(streamInfo.listEventType, 'Remote stream info', streamInfo);
    const streamId = streamInfo.streamId;
    switch (streamInfo.listEventType) {
      case ApirtcStreamListeventTypes.added:
        // after subscribing to stream subscribed stream will be availble in addstream
        await this.conversation
          .subscribeToStream(streamId)
          .catch((error) => this.logger.error('Failed to subscribe to remote stream', error));
        break;
      case ApirtcStreamListeventTypes.removed:
        // when stream is removed, remove from the stream list aswell
        this.conversation.unsubscribeToStream(streamId as any);
        break;
      case ApirtcStreamListeventTypes.updated:
        // when stream is updated, replace the current stream
        // only update the stream info
        this.streamsService.updateRemoteStream(streamInfo);
        break;
      default:
        this.logger.error('Unknown streamListChangeEvent', streamInfo);
        break;
    }
  }

  private remoteStreamAdded(stream: Stream) {
    this.streamsService.addRemoteStream(stream);
    this.store.dispatch(
      consultationActions.particpantJoined({
        userUUID: stream.getContact().getUserData().get('uuid'),
        joinedAt: new Date().toISOString(),
        conferenceID: stream.getContact().getUserData().get('userConfId')
      })
    );
  }

  private remoteStreamRemoved(stream: Stream) {
    this.streamsService.removeRemoteStream(stream);
  }

  private participantLeft(contact: Contact) {
    this.logger.info('Contact left the conversation', contact);
    this.store
      .select(consultationSelectors.selectConsultation)
      .pipe(take(1))
      .subscribe((r) => {
        if (
          r?.participants[contact.getUserData().get('uuid')]?.conferenceID ===
          contact.getUserData().get('userConfId')
        ) {
          this.store.dispatch(
            consultationActions.participantDisconnected({
              conferenceID: contact.getUserData().get('userConfId')
            })
          );
        }
      });
    return;
    // this.store.dispatch(
    //   consultationActions.participantExitedAt({
    //     userUUID: contact.getUserData().get('uuid'),
    //     exitedAt: new Date().toISOString(),
    //   })
    // );
  }

  private customEvent(event: ApirtcCustomEventData<any>) {
    switch (event.event) {
      case ApiRTCCustomEvents.exitConversation:
        const e: ApirtcCustomEventData<ExitConsultationEventData> = event;
        this.store.dispatch(
          consultationActions.participantExitedAt({
            exitedAt: new Date().toISOString(),
            userUUID: e.sender.getUserData().get('uuid')
          })
        );
        this.store.dispatch(consultationActions.completeConversation({reason: e.content.reason}));
        break;
      default:
        this.logger.error('Unknown event received', event);
        break;
    }
  }

  private participantJoined(c: Contact) {
    this.store.dispatch(
      consultationActions.particpantJoined({
        userUUID: c.getUserData().get('uuid'),
        joinedAt: new Date().toISOString(),
        conferenceID: c.getUserData().get('userConfId')
      })
    );
  }

  private async conversationHangup(e: ConversationHangup) {
    try {
      const appointmentUUID = this.conversation.getCloudConversationId();
      this.logger.error('ConversationHangup', e);
      this.logger.info('Conversation join status', this.conversation.isJoined());

      /*
       * when stream is not published leave the conversation and reconnect
       * apiRTC.min.js:5 [2023-01-04T17:19:40.341Z][WARN]apiRTC(ApiCCMCUClient) invite + SDP offer not received -> restart call
       * above issues causes stream to unpublish in the new call
       */
      if (!this.conversation.isPublishedStream(this.conversationPublishedStream)) {
        this.reconnect();
      }
      this.logger.info(
        'Conversation stream publish status',
        this.conversation.isPublishedStream(this.conversationPublishedStream)
      );
    } catch (error) {
      this.logger.error('Failed to republish stream in the conversation after hangup', error);
    }
  }

  private setListeners(conversation: Conversation) {
    conversation
      .on(ApiRTCConversationEvents.streamListChanged, this.streamListChanged.bind(this))
      .on(ApiRTCConversationEvents.streamAdded, this.remoteStreamAdded.bind(this))
      .on(ApiRTCConversationEvents.streamRemoved, this.remoteStreamRemoved.bind(this))
      .on(ApiRTCConversationEvents.contactLeft, this.participantLeft.bind(this)) // Not reliable for determining contact status
      .on(ApiRTCConversationEvents.customEvent, this.customEvent.bind(this))
      .on(ApiRTCConversationEvents.contactJoined, this.participantJoined.bind(this))
      .on(ApiRTCConversationEvents.hangup, this.conversationHangup.bind(this))
      .on(ApiRTCConversationEvents.remoteStreamUpdated, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.remoteStreamUpdated,
          r
        )
      )
      .on(ApiRTCConversationEvents.contactJoinedWaitingRoom, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.contactJoinedWaitingRoom,
          r
        )
      )
      .on(ApiRTCConversationEvents.contactLeftWaitingRoom, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.contactLeftWaitingRoom,
          r
        )
      )
      .on(ApiRTCConversationEvents.availableStreamsUpdated, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.availableStreamsUpdated,
          r
        )
      )
      .on(ApiRTCConversationEvents.disconnectionWarning, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.disconnectionWarning,
          r
        )
      )
      .on(ApiRTCConversationEvents.joined, (...r) =>
        this.logger.debug('(not implemented) conversation:', ApiRTCConversationEvents.joined, r)
      )
      .on(ApiRTCConversationEvents.left, (...r) =>
        this.logger.debug('(not implemented) conversation:', ApiRTCConversationEvents.left, r)
      )
      .on(ApiRTCConversationEvents.localStreamUpdated, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.localStreamUpdated,
          r
        )
      )
      .on(ApiRTCConversationEvents.audioAmplitude, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.audioAmplitude,
          r
        )
      )
      .on(ApiRTCConversationEvents.callStatsUpdate, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.callStatsUpdate,
          r
        )
      )
      .on(ApiRTCConversationEvents.data, (...r) =>
        this.logger.debug('(not implemented) conversation:', ApiRTCConversationEvents.data, r)
      )
      .on(ApiRTCConversationEvents.error, (...r) =>
        this.logger.debug('(not implemented) conversation:', ApiRTCConversationEvents.error, r)
      )
      .on(ApiRTCConversationEvents.message, (...r) =>
        this.logger.debug('(not implemented) conversation:', ApiRTCConversationEvents.message, r)
      )
      .on(ApiRTCConversationEvents.messageNotDelivered, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.messageNotDelivered,
          r
        )
      )
      .on(ApiRTCConversationEvents.moderatorConnected, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.moderatorConnected,
          r
        )
      )
      .on(ApiRTCConversationEvents.newMediaAvailable, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.newMediaAvailable,
          r
        )
      )
      .on(ApiRTCConversationEvents.newWhiteboardSession, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.newWhiteboardSession,
          r
        )
      )
      .on(ApiRTCConversationEvents.participantEjected, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.participantEjected,
          r
        )
      )
      .on(ApiRTCConversationEvents.persistentDataUpdated, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.persistentDataUpdated,
          r
        )
      )
      .on(ApiRTCConversationEvents.pointerLocationChanged, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.pointerLocationChanged,
          r
        )
      )
      .on(ApiRTCConversationEvents.pointerSharingEnabled, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.pointerSharingEnabled,
          r
        )
      )
      .on(ApiRTCConversationEvents.recordingAvailable, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.recordingAvailable,
          r
        )
      )
      .on(ApiRTCConversationEvents.recordingStarted, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.recordingStarted,
          r
        )
      )
      .on(ApiRTCConversationEvents.recordingStopped, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.recordingStopped,
          r
        )
      )
      .on(ApiRTCConversationEvents.roomModeChanged, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.roomModeChanged,
          r
        )
      )
      .on(ApiRTCConversationEvents.slowLink, (...r) =>
        this.logger.debug('(not implemented) conversation:', ApiRTCConversationEvents.slowLink, r)
      )
      .on(ApiRTCConversationEvents.transferBegun, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.transferBegun,
          r
        )
      )
      .on(ApiRTCConversationEvents.transferEnded, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.transferEnded,
          r
        )
      )
      .on(ApiRTCConversationEvents.transferProgress, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.transferProgress,
          r
        )
      )
      .on(ApiRTCConversationEvents.waitingForModeratorAcceptance, (...r) =>
        this.logger.debug(
          '(not implemented) conversation:',
          ApiRTCConversationEvents.waitingForModeratorAcceptance,
          r
        )
      );
  }
}

interface CreateConversationParams {
  appointmentUUID: string;
}
