<div class="wrapper">
  <div class="header">
    <p role="heading">{{ title }}</p>
    <button
      mat-icon-button
      class="btn-icon"
      (click)="onClose()"
      [attr.aria-label]="'DRAWER_LAYOUT.aria_btn_label' | translate">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="body">
    <ng-content></ng-content>
  </div>
</div>
