import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subject, takeUntil} from 'rxjs';
import {EnvURLs, AppLang, AppState} from 'src/app/core/models/app-state.models';
import {sessionSelectors} from 'src/app/store/selectors';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-footer-links',
  templateUrl: 'footer-links.component.html',
  styleUrls: ['footer-links.component.scss']
})
export class FooterLinksComponent implements OnDestroy {
  private onDestroyed$ = new Subject();

  @Input() isPartial = false;
  @Input() mobile = false;
  @Input() smallHeight = false;

  year = new Date().getFullYear();
  urls: EnvURLs[AppLang] = environment.urls[AppLang.de];

  constructor(private store: Store<AppState>) {
    this.store
      .select(sessionSelectors.selectLang)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => (this.urls = environment.urls[r]));
  }
  ngOnDestroy() {
    this.onDestroyed$.next(null);
    this.onDestroyed$.complete();
  }
}
