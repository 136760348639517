import {AppointmentConfiguration} from './appointment.models';

export interface AppConfig {
  clientConfiguration: AppointmentConfiguration;
  snackBarDuration: number;
  baseUrl: string;
  maxWidth: string;
  maxWidthTablet: string;
  isMobile: boolean;
  isTablet: boolean;
  languages: AppConfigLang[];
  isWebview: boolean;
  messageWindowOpen: boolean;
  iOS: boolean;
  android: boolean;
  theme: ThemeValues;
  isDarkTheme: boolean;
  disableTheme: boolean;
}

export enum ThemeValues {
  SYSTEM = 'system',
  DARK = 'dark',
  LIGHT = 'light'
}

export interface AppConfigLang {
  name: string;
  value: string;
}

export enum ClientTokens {
  ALMEDA = 'almeda',
  BARMER = 'barmer',
  GLOBALITY = 'globality',
  AOKPLUS = 'aokplus'
}
