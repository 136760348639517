import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';
import {tap} from 'rxjs/operators';
import {sessionActions} from '../actions';

@Injectable()
export class SessionEffects {
  changeLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sessionActions.changeLang),
        tap((action) => {
          document.documentElement.lang = action.lang;
          this.translate.use(action.lang);
        })
      ),
    {dispatch: false}
  );

  constructor(private actions$: Actions, private translate: TranslateService) {}
}
