import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AppHomeComponent} from './core/components/home/home.component';
import {AppPageNotFoundComponent} from './core/components/page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'a',
    loadChildren: () => import('./modules/patient/patient.module').then((r) => r.PatientModule)
  },
  {
    path: 'c',
    loadChildren: () => import('./modules/doctor/doctor.module').then((r) => r.DoctorModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./modules/report/report.module').then((r) => r.ReportModule)
  },
  {path: '', component: AppHomeComponent},
  {
    path: '**',
    component: AppPageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
