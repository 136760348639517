import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): string {
    // Remove HTML tags using a regular expression
    return value ? value.replace(/<[^>]*>/g, '') : '';
  }
}
