import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {Subject, takeUntil} from 'rxjs';
import {AppLang} from 'src/app/core/models/app-state.models';
import {Appointment} from 'src/app/core/models/appointment.models';
import {ChatMessage, ChatType} from 'src/app/core/models/chat.models';
import {OnlineParticipants} from 'src/app/core/models/participants.models';
import {User} from 'src/app/core/models/user.models';
import {ChatService} from 'src/app/core/services/chat.service';
import {
  appointmentSelectors,
  appSelectors,
  chatSelectors,
  consultationSelectors,
  onlineParticipantsSelectors,
  sessionSelectors
} from 'src/app/store/selectors';
import {HumanNamePipe} from '../../pipes/name.pipe';
import {TranslateService} from '@ngx-translate/core';
import {ConsultationConstraints} from 'src/app/core/models/consultation.models';
import {AppSnackBarService} from 'src/app/core/services/snackbar.service';

@Component({
  selector: 'app-chat',
  templateUrl: 'chat.component.html',
  styleUrls: ['chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy, OnChanges {
  private onDestroyed$ = new Subject();

  @Input() online = false; // probably set to ongoing based on implmentation.
  @Input() user: User = {};

  @Output() fileListRefreshed = new EventEmitter<boolean>();
  @Output() closeChatDrawer = new EventEmitter();

  lang: AppLang = AppLang.de;
  appointment: Appointment = {};
  isMobile = false;
  userMessageHeader: string;

  private onlineParticipants: OnlineParticipants = {};

  chatMessagesList: ChatMessage[];

  consultation: ConsultationConstraints;

  get ChatType() {
    return ChatType;
  }

  constructor(
    private store: Store,
    private renderer: Renderer2,
    private chatService: ChatService,
    private logger: NGXLogger,
    private name: HumanNamePipe,
    private translate: TranslateService,
    private snackBarService: AppSnackBarService
  ) {
    this.store
      .select(sessionSelectors.selectLang)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => (this.lang = r));

    this.store
      .select(appSelectors.appConfigSelectors.selectDeviceForm)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((m) => (this.isMobile = m));

    this.store
      .select(appointmentSelectors.selectAppointment)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((a) => (this.appointment = a));

    this.store
      .select(onlineParticipantsSelectors.selectOnlineParticipants)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((o) => {
        this.onlineParticipants = o;
        this.updateOnlineStatus(this.onlineParticipants);
      });

    this.store
      .select(consultationSelectors.selectConsultation)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => (this.consultation = r));
  }

  ngOnInit() {
    this.userMessageHeader =
      this.name.transform(this.user, 'noSalute') + ' ' + this.translate.instant('CHAT.at');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user.currentValue) {
      this.updateOnlineStatus(this.onlineParticipants);
      this.selectUserMessages(this.user);
    }
  }

  private selectUserMessages(user: User) {
    if (!user) {
      return;
    }

    this.store
      .select(chatSelectors.selectAllUserChatMessages({userUUID: user.user_uid}))
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((m) => (this.chatMessagesList = m));
  }

  private updateOnlineStatus(o: OnlineParticipants) {
    if (!o) {
      return (this.online = false);
    }
    const userSessionIds = o[this.user?.user_uid];
    if (!userSessionIds) {
      return (this.online = false);
    }

    if (Object.keys(userSessionIds).length > 0) {
      return (this.online = true);
    }
  }

  onClose() {
    this.closeChatDrawer.emit(true);
  }

  trackByIndex(i: number, obj: any) {
    return i;
  }

  sendTextMsg(msg: string) {
    if (!this.onlineParticipants) {
      this.logger.debug('No participants online');
      return;
    }
    if (!msg.trim()) {
      return;
    }
    const userSessionIds = this.onlineParticipants[this.user?.user_uid];
    this.chatService.sendText(Object.keys(userSessionIds), msg);
  }

  sendTextMsgEnter(inputEvent) {
    inputEvent.preventDefault();
    const srcEl = inputEvent.srcElement;
    this.sendTextMsg(srcEl.value);
    this.renderer.setProperty(srcEl, 'value', '');
    document.getElementById('textInputEl').focus();
  }

  sendTextMsgBtn(inputEl) {
    this.sendTextMsg(inputEl.value);
    this.renderer.setProperty(inputEl, 'value', '');
    document.getElementById('textInputEl').focus();
  }

  resetAndSelectFiles(fileInEl) {
    this.renderer.setProperty(fileInEl, 'value', '');
    fileInEl.click();
  }

  fileChangeHandler(fileInput) {
    if (!this.consultation.canShareFiles) {
      this.snackBarService.createSnackBar(this.translate.instant('CHAT.no_file_permission'), '');
      return;
    }
    this.fileListRefreshed.next(true);
    const userSessionIds = this.onlineParticipants[this.user?.user_uid];
    const userIDs = Object.keys(userSessionIds);
    const filesList = Object.values(fileInput?.target?.files);
    filesList.forEach(async (f: File) => {
      this.chatService.sendFile(userIDs, f);
    });
    this.renderer.setProperty(fileInput.target, 'value', '');
  }

  sendFileShareRequest() {
    const userSessionIds = this.onlineParticipants[this.user?.user_uid];
    const userIDs = Object.keys(userSessionIds);
    this.chatService.sendFileShareRequest(userIDs);
  }

  denyFileShareRequest() {
    const userSessionIds = this.onlineParticipants[this.user?.user_uid];
    const userIDs = Object.keys(userSessionIds);
    this.chatService.sendFileShareRequestDeny(userIDs);
  }

  ngOnDestroy() {
    this.onDestroyed$.next(null);
    this.onDestroyed$.complete();
  }
}
