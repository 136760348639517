import {createAction, props} from '@ngrx/store';
import {ThemeValues} from 'src/app/core/models/app-config.models';
import {AppointmentConfiguration} from 'src/app/core/models/appointment.models';

export const updateIsMobile = createAction(
  '[Breakpoint Service] Device type',
  props<{status: boolean}>()
);

export const updateIsTablet = createAction(
  '[Breakpoint Service] Device type tablet',
  props<{status: boolean}>()
);

export const updateClientConfig = createAction(
  'Update client config',
  props<{clientConfig: AppointmentConfiguration}>()
);

export const updateWebviewStatus = createAction(
  'Updated webview status',
  props<{value: boolean}>()
);

export const messageWindowOpen = createAction(
  'Updated message window status',
  props<{value: boolean}>()
);

export const updateTheme = createAction('Theme changed', props<{theme: ThemeValues}>());
export const darkThemeStatus = createAction(
  'Dark theme status changed',
  props<{isDarkTheme: boolean}>()
);

export const iOSStatus = createAction('Updated iOS status', props<{value: boolean}>());

export const androidStatus = createAction('Updated android status', props<{value: boolean}>());

export const disableThemes = createAction('Disable themes');
export const enableThemes = createAction('Enable themes');
