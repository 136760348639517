<div class="instructions">
  <mat-tab-group>
    <mat-tab label="{{ 'SAFARI_PERMISSIONS.computer_tab' | translate }}">
      <mat-list-item>
        <ol>
          <li [innerHTML]="'SAFARI_PERMISSIONS.mac_step_one' | translate"></li>
          <ul>
            <li [innerHTML]="'SAFARI_PERMISSIONS.mac_step_one_yes' | translate"></li>
            <li>{{ "SAFARI_PERMISSIONS.mac_step_one_no" | translate }}</li>
          </ul>
          <img
            class="mb-2 mt-2 w-100"
            src="assets/img/safari-mac-preferences.png"
            role="presentation"
            alt="safari-computer-permissions" />
          <li [innerHTML]="'SAFARI_PERMISSIONS.mac_step_two' | translate"></li>
          <li [innerHTML]="'SAFARI_PERMISSIONS.mac_step_three' | translate"></li>
          <img
            class="mb-2 mt-2 w-100"
            src="assets/img/safari-mac-permission.png"
            role="presentation"
            alt="safari-computer-permissions" />
          <li [innerHTML]="'SAFARI_PERMISSIONS.mac_step_four' | translate"></li>
          <li [innerHTML]="'SAFARI_PERMISSIONS.mac_step_five' | translate"></li>
        </ol>
      </mat-list-item>
    </mat-tab>

    <mat-tab label="{{ 'SAFARI_PERMISSIONS.ios_tab' | translate }}">
      <mat-list-item [innerHTML]="'SAFARI_PERMISSIONS.ios_heading' | translate"> </mat-list-item>
      <mat-list-item>
        <ol>
          <li [innerHTML]="'SAFARI_PERMISSIONS.ios_step_one' | translate"></li>
          <li [innerHTML]="'SAFARI_PERMISSIONS.ios_step_two' | translate"></li>
          <li [innerHTML]="'SAFARI_PERMISSIONS.ios_step_three' | translate"></li>
          <img
            class="mt-2 mb-2 iosPermission"
            src="assets/img/safari-ios-permission.png"
            role="presentation"
            alt="safari-ios-permission" />
        </ol>
      </mat-list-item>
    </mat-tab>
  </mat-tab-group>
</div>
