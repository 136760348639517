import {createReducer, Action, on} from '@ngrx/store';
import {consultationActions} from '../actions';
import {ConsultationConstraints} from 'src/app/core/models/consultation.models';

const initialState: ConsultationConstraints = {};

export function reducer(state: ConsultationConstraints, action: Action) {
  return createReducer(
    initialState,
    on(consultationActions.rejoinConversation, (state, action) => ({
      ...state,
      completed: false,
      waitingForRemote: true,
      participants: {}
    })),
    on(consultationActions.completeConversation, (state, action) => ({
      ...state,
      waitingForRemote: true,
      completed: true,
      endTime: new Date().toISOString(),
      disconnectReason: action.reason ? action.reason : state.disconnectReason
    })),
    on(consultationActions.initConsultation, (state, action) => ({
      conversationId: action.conversationId,
      waitingForRemote: state?.participants
        ? Object.keys(state.participants).length > 0
          ? false
          : true
        : true,
      participants: state?.participants || {},
      completed: state?.completed === true ? true : false,
      startTime: state?.startTime || null,
      newMessages: state?.newMessages ? state.newMessages : false,
      requestedFileSharing: state?.requestedFileSharing ? state.requestedFileSharing : false,
      acceptFiles: state?.acceptFiles ? state.acceptFiles : false,
      canShareFiles: state?.canShareFiles ? state.canShareFiles : false,
      requestedFileSharingCount: state?.requestedFileSharingCount
        ? state.requestedFileSharingCount
        : 0
    })),
    on(consultationActions.updateRemoteUserStreamConstraints, (state, action) => {
      const streams =
        state?.participants &&
        state?.participants[action.userUUID] &&
        state?.participants[action.userUUID]?.streams;
      return {
        ...state,
        startTime: state?.startTime ? state.startTime : new Date().toISOString(),
        participants: {
          ...state?.participants,
          [action.userUUID]: {
            ...state?.participants[action.userUUID],
            streams: {
              ...streams,
              [action.streamId]: action.constraints
            }
          }
        }
      };
    }),
    on(consultationActions.removeRemoteUserStreamConstraints, (state, action) => {
      let streams =
        state?.participants &&
        state?.participants[action.userUUID] &&
        state?.participants[action.userUUID]?.streams;
      if (streams) {
        streams = {...streams};
        delete streams[action.streamId];
      } else {
        streams = {};
      }
      return {
        ...state,
        participants: {
          ...state?.participants,
          [action.userUUID]: {
            ...state?.participants[action.userUUID],
            streams: {...streams}
          }
        }
      };
    }),
    on(consultationActions.participantExitedAt, (state, action) => ({
      ...state,
      participants: {
        ...state.participants,
        [action.userUUID]: {
          ...state.participants[action.userUUID],
          exitedAt: action.exitedAt
        }
      }
    })),
    on(consultationActions.particpantRejoined, (state, action) => ({
      ...state,
      waitingForRemote: false,
      completed: false,
      startTime: state?.startTime ? state.startTime : new Date().toISOString(),
      participants: {
        ...state.participants,
        [action.userUUID]: {
          ...state.participants[action.userUUID],
          exitedAt: null
        }
      }
    })),
    on(consultationActions.particpantJoined, (state, action) => ({
      ...state,
      waitingForRemote: false,
      completed: false,
      startTime: state?.startTime ? state.startTime : new Date().toISOString(),
      participants: {
        ...state?.participants,
        [action.userUUID]: {
          ...(state?.participants ? state?.participants[action.userUUID] : {}),
          joindedAt: action.joinedAt,
          exitedAt: null,
          conferenceID: action.conferenceID
        }
      }
    })),
    on(consultationActions.participantDisconnected, (state, action) => ({
      ...state,
      waitingForRemote: true,
      completed: true
    })),
    on(consultationActions.newMessages, (state, action) => ({
      ...state,
      newMessages: true
    })),
    on(consultationActions.clearNewMessages, (state, action) => ({
      ...state,
      newMessages: false
    })),
    on(consultationActions.requestSnapshot, (state, action) => ({
      ...state,
      requestedSnapshot: action.requestedSnapshot
    })),
    on(consultationActions.requestFileSharing, (state, action) => ({
      ...state,
      requestedFileSharing: action.requestedFileSharing
    })),
    on(consultationActions.filesAccepted, (state, action) => ({
      ...state,
      acceptFiles: true
    })),
    on(consultationActions.filesDenied, (state, action) => ({
      ...state,
      acceptFiles: false
    })),
    on(consultationActions.fileSharingAllowed, (state, action) => ({
      ...state,
      canShareFiles: true
    })),
    on(consultationActions.fileSharingDenied, (state, action) => ({
      ...state,
      canShareFiles: false
    })),
    on(consultationActions.fileSharingRequestsIncrement, (state, action) => ({
      ...state,
      requestedFileSharingCount: state?.requestedFileSharingCount
        ? state?.requestedFileSharingCount + 1
        : 1
    }))
  )(state, action);
}
