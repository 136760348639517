import {createSelector} from '@ngrx/store';
import {AppState} from 'src/app/core/models/app-state.models';
import {UsersChats} from 'src/app/core/models/chat.models';
import {chatsAdapter} from '../reducers/chat.reducer';

export const selectUserChat = (props: {userUUID: string}) =>
  createSelector(
    (state: AppState) => state.chats,
    (c: UsersChats) => c[props?.userUUID]
  );

export const selectAllUserChatMessages = (props: {userUUID: string}) =>
  createSelector(selectUserChat({userUUID: props.userUUID}), (c) => {
    if (c) {
      return selectAll(c);
    }
    return [];
  });

export const selectUserTotalChatMessages = (props: {userUUID: string}) =>
  createSelector(selectUserChat({userUUID: props.userUUID}), (c) => {
    if (c) {
      return selectTotal(c);
    }
    return 0;
  });

// get the selectors
const {
  selectIds, // select the array of chats ids
  selectEntities, // select the dictionary of chats entities
  selectAll, // select the array of chats
  selectTotal // select the total chats count
} = chatsAdapter.getSelectors();
