<div class="wrapper">
  <app-header [src]="logo" *ngIf="showHeader"></app-header>

  <div class="contentContainer row m-0">
    <div class="col-xl-6 p-0">
      <div class="textContainer">
        <p class="heading">{{ "HOME.heading" | translate }}</p>
        <p class="description">{{ "HOME.description" | translate }}</p>
      </div>

      <div class="doctorInfoBanner">
        <p class="doctorInfo">{{ "HOME.doc_info" | translate }}</p>
        <button mat-button class="alm-btn-success" (click)="goToDoctorPortal()">
          {{ "HOME.btn_to_doctor_portal" | translate }}
        </button>
      </div>
    </div>

    <div class="col-xl-6">
      <div class="imgContainer mb-4">
        <img src="./assets/img/svg/appointment.svg" alt="" srcset="" class="injectable" />
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
