import {createAction, props} from '@ngrx/store';
import {UserDataExtended} from 'src/app/core/models/apirtc.models';
import {OnlineParticipants} from 'src/app/core/models/participants.models';

// export const addParticipantId = createAction(
//   '[Contact List] Add online participant ID',
//   props<{ userUUID: UserDataExtended }>()
// );

export const participantIdList = createAction(
  '[Contact List] Update online participant IDs as list',
  props<{onlineParticipants: OnlineParticipants}>()
);
