<app-drawer-layout title="{{ 'MEDIA_DRAWER.device_test' | translate }}" (close)="closeComponent()">
  <ng-container *ngIf="!iOS">
    <div class="videoContainer">
      <video #testMedia playsInline="true" autoplay="true" id="local-container"></video>
    </div>
    <p class="msg-success">
      <mat-icon>check_circle</mat-icon>{{ "MEDIA_DRAWER.device_test_result" | translate }}
    </p>
  </ng-container>
  <div class="formWrapper">
    <form [formGroup]="mediaDeviceSelectionGroup">
      <form-select
        label="{{ 'DRAWER_LAYOUT.aria_label_camera' | translate }}"
        formControlName="video"
        [parentForm]="mediaDeviceSelectionGroup"
        controlName="video"
        [options]="videoOptions"
        width="100%"
        placeholder="{{ 'MEDIA_DRAWER.camera' | translate }}"
        icon="videocam">
      </form-select>
      <form-select
        label="{{ 'DRAWER_LAYOUT.aria_label_mic' | translate }}"
        formControlName="audio"
        [parentForm]="mediaDeviceSelectionGroup"
        controlName="audio"
        [options]="audioOptions"
        width="100%"
        placeholder="{{ 'MEDIA_DRAWER.mic' | translate }}"
        icon="mic">
      </form-select>
    </form>

    <form [formGroup]="langSelectionGroup">
      <form-select
        label="{{ 'DRAWER_LAYOUT.aria_label_language' | translate }}"
        formControlName="language"
        [parentForm]="langSelectionGroup"
        controlName="language"
        [options]="langOptions"
        width="100%"
        placeholder="{{ 'MEDIA_DRAWER.set_language' | translate }}"
        customIcon="/img/svg/icons/alm_language.svg">
      </form-select>
    </form>

    <div class="joinBtn">
      <button
        mat-button
        appClientStyle
        [defaultStyles]="'mat-button alm-btn-success w-100'"
        (click)="saveSettings()"
        [attr.aria-label]="'COMMON.btn_save' | translate">
        {{ "COMMON.btn_save" | translate }}
      </button>
    </div>
  </div>
</app-drawer-layout>
