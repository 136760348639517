<form [formGroup]="langForm">
  <form-select
    label="{{ 'LANG_SELECTOR.a_label_lang_selector' | translate }}"
    formControlName="lang"
    [parentForm]="langForm"
    controlName="lang"
    [options]="options"
    placeholder="{{ 'LANG_SELECTOR.a_label_lang_selector' | translate }}">
  </form-select>
</form>
