export enum AppErrors {
  Duplicate_session = 'duplicate_session',
  Contact_offline = 'contact_offline',
  Failed_to_send_text = 'failed_to_send_text',
  Failed_to_send_file = 'failed_to_send_file',
  Failed_to_send_snap_request = 'failed_to_send_snap_request',
  Failed_to_send_snap_response = 'failed_to_send_snap_response',
  Failed_to_convert_file_base64 = 'failed_to_convert_file_base64',
  Invalid_appointment_uuid = 'invalid_appointment_uuid',
  Failed_to_send_file_share_request = 'failed_to_send_file_share_request',
  Failed_to_send_file_share_request_deny = 'failed_to_send_file_share_request_deny',
  Failed_to_send_file_share_request_allow = 'failed_to_send_file_share_request_allow',
  Failed_to_send_file_share_status = 'failed_to_send_file_share_status',
  Failed_to_request_file_share_status = 'failed_to_request_file_share_status'
}
