import {createReducer, Action, on} from '@ngrx/store';
import {CCSConnectionStatus} from 'src/app/core/models/apirtc.models';
import {AppStatus} from 'src/app/core/models/app-status.models';
import {appStatusActions} from '../actions';

const appStatusInitialState: AppStatus = {
  session: 'DISCONNECTED',
  userAgent: CCSConnectionStatus.DISCONNECTED,
  user: 'AVAILABLE'
};

export const appStatusReducer = createReducer(
  appStatusInitialState,
  on(appStatusActions.userAgentStatusChanged, (state, action) => ({
    ...state,
    userAgent: action.status
  })),
  on(appStatusActions.sessionStatusChanged, (state, action) => ({
    ...state,
    session: action.status
  })),
  on(appStatusActions.userStatusChanged, (state, action) => ({
    ...state,
    user: action.status
  }))
);

export function reducer(state: AppStatus | undefined, action: Action) {
  return appStatusReducer(state, action);
}
