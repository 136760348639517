import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {Subject, fromEvent} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppLang, AppState} from 'src/app/core/models/app-state.models';
import {appSelectors} from 'src/app/store/selectors';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private onDestroyed$ = new Subject();

  lang: AppLang = AppLang.de;

  @Input() hideBrand = false;
  @Input() hideLangSelector = false;
  @Input() src = environment.logo.almeda;
  @Input() homeRoute = ['/'];
  @Input() disableHideOnScroll = false;

  constructor(private store: Store<AppState>, private elRef: ElementRef) {
    this.store
      .select(appSelectors.sessionSelectors.selectLang)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => (this.lang = r));
  }

  ngOnInit() {
    const parentElement = this.elRef.nativeElement.closest('.page-wrapper');

    if (parentElement) {
      fromEvent(parentElement, 'scroll')
        .pipe(takeUntil(this.onDestroyed$))
        .subscribe((ev: Event) => {
          if (!this.disableHideOnScroll) {
            this.hideBrand = (ev.target as Element).scrollTop > 20;
          }
        });
    }
  }

  ngOnDestroy() {
    this.onDestroyed$.next(void 0);
    this.onDestroyed$.complete();
  }
}
