import {Injectable} from '@angular/core';
import * as apiRTC from '@apirtc/apirtc';
import {CreateStreamOptions, Stream, StreamInfo} from '@apirtc/apirtc';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {Subject, takeUntil} from 'rxjs';
import {consultationActions, mediaDeviceActions} from 'src/app/store/actions';
import {selectUserMediaDevices} from 'src/app/store/selectors/media-devices.selectors';
import {AppState} from '../models/app-state.models';
import {DefaultMediaDevicesID, StreamConstraints} from '../models/media-devices.models';
import {ApiRTCService} from './apirtc/apirtc.service';
import {AppLoaderService} from './loader.service';
import {MatDialog} from '@angular/material/dialog';
import {AppDialogComponent} from 'src/app/shared/components/dialog/app-dialog.component';

@Injectable()
export class AppStreamsService {
  private defaultMediaDevices: DefaultMediaDevicesID = {
    videoInputId: null,
    audioInputId: null
  };
  private displayMediaStreamConstraints = {
    video: {
      cursor: 'always'
    },
    audio: {
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100
    }
  };
  private _localStream: Stream = null;
  private _localStreamEvents$ = new Subject<LocalStreamEvents>();
  private _remoteStreamEvents$ = new Subject<RemoteStreamEvents>();
  private conversationStreams: {[streamID: string | number]: Stream} = {};
  private _screenshareStream: Stream;
  private streamConstraints: StreamConstraints;

  get localStreamEvents$() {
    return this._localStreamEvents$;
  }

  get localStream() {
    return this._localStream;
  }

  get remoteStreamEvents$() {
    return this._remoteStreamEvents$;
  }

  get remoteStreams() {
    return this.conversationStreams;
  }

  get screenShareStream() {
    return this._screenshareStream;
  }

  constructor(
    private store: Store<AppState>,
    private apirtc: ApiRTCService,
    private logger: NGXLogger,
    private appLoader: AppLoaderService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    this.store.select(selectUserMediaDevices).subscribe((r) => {
      if (r) {
        this.defaultMediaDevices.audioInputId = r.default.audioInputId;
        this.defaultMediaDevices.videoInputId = r.default.videoInputId;
        this.streamConstraints = r.constraints;
      }
    });
  }

  private setStreamConstraints(stream: Stream) {
    if (!this.streamConstraints) {
      return;
    }
    if (this.streamConstraints.video) {
      stream.enableVideo();
    } else {
      stream.disableVideo();
    }

    if (this.streamConstraints.audio) {
      stream.enableAudio();
    } else {
      stream.disableAudio();
    }
  }

  setNewLocalStream(stream: Stream) {
    if (!stream) {
      this.logger.error('No stream for localstream provided', stream);
      return;
    }
    this.releaseLocalStream();

    this.setStreamConstraints(stream);
    this._localStream = stream;
    this.updateStreamConstraintsState(this._localStream);
    this._localStreamEvents$.next(LocalStreamEvents.CREATED);
  }

  releaseLocalStream() {
    if (this._localStream) {
      this._localStream.release();
      this._localStreamEvents$.next(LocalStreamEvents.RELEASED);
    }
    this._localStream = null;
  }

  enableLocalStreamVideo() {
    this._localStream?.enableVideo();
    this.updateStreamConstraintsState(this._localStream);
  }

  disableLocalStreamVideo() {
    this._localStream?.disableVideo();
    this.updateStreamConstraintsState(this._localStream);
  }

  enableLocalStreamAudio() {
    this._localStream?.enableAudio();
    this.updateStreamConstraintsState(this._localStream);
  }

  disableLocalStreamAudio() {
    this._localStream?.disableAudio();
    this.updateStreamConstraintsState(this._localStream);
  }

  toggleLocalStreamVideo() {
    this._localStream?.isVideoEnabled()
      ? this.disableLocalStreamVideo()
      : this.enableLocalStreamVideo();
  }
  toggleLocalStreamAudio() {
    this._localStream?.isAudioEnabled()
      ? this.disableLocalStreamAudio()
      : this.enableLocalStreamAudio();
  }

  private updateStreamConstraintsState(stream: Stream) {
    this.store.dispatch(
      mediaDeviceActions.updateStreamConstraints({
        constraints: {
          audio: stream?.isAudioEnabled(),
          video: stream?.isVideoEnabled(),
          screensharing: stream?.isScreensharing()
        }
      })
    );
  }

  addRemoteStream(stream: Stream) {
    const userUUID = stream.getContact().getUserData().get('uuid');
    this.conversationStreams[userUUID] = stream;
    this.store.dispatch(
      consultationActions.updateRemoteUserStreamConstraints({
        constraints: {
          audio: stream?.isAudioEnabled(),
          video: stream?.isVideoEnabled(),
          screensharing: !stream.isScreensharing()
        },
        userUUID: userUUID,
        streamId: String(stream.streamId)
      })
    );
    this.remoteStreamEvents$.next(RemoteStreamEvents.ADDED);
  }

  updateRemoteStream(info: StreamInfo) {
    this.store.dispatch(
      consultationActions.updateRemoteUserStreamConstraints({
        constraints: {
          audio: !info.isAudioMuted,
          video: !info.isVideoMuted,
          screensharing: info.isScreensharing
        },
        userUUID: info.contact.getUserData().get('uuid'),
        streamId: String(info.streamId)
      })
    );
  }

  removeRemoteStream(stream: Stream) {
    const userUUID = stream.getContact().getUserData().get('uuid');
    delete this.conversationStreams[userUUID];
    this.store.dispatch(
      consultationActions.removeRemoteUserStreamConstraints({
        userUUID: userUUID,
        streamId: String(stream.streamId)
      })
    );
    this.remoteStreamEvents$.next(RemoteStreamEvents.REMOVED);
  }

  /*
   * should also handle the "stopped" by consumer on screenStream
   */
  async changeLocalStreamToScreenshare() {
    try {
      if (this._screenshareStream) {
        this._screenshareStream.release();
        this._screenshareStream = null;
      }

      this.appLoader.showLoader();
      const screenStream = await apiRTC.Stream.createDisplayMediaStream(
        this.displayMediaStreamConstraints,
        false
      );

      let replacedVideoStream = await this.apirtc.userAgent
        .createStream({
          constraints: {
            audio: true,
            video: true
          },
          audioInputId: this.defaultMediaDevices.audioInputId
        })
        .catch((error) => {
          this.logger.error(
            'Failed to create replacing stream, releasing screenstream, no changes made to localstream'
          );
          screenStream.release();
          throw error;
        });

      // replace the audio and screen streams to create single stream
      const screenTracks = screenStream.getData().getVideoTracks();
      const videoTracks = replacedVideoStream.getData().getVideoTracks();

      replacedVideoStream.getData().removeTrack(videoTracks[0]);
      videoTracks[0].stop();
      replacedVideoStream.getData().addTrack(screenTracks[0]);

      // set screensharing property
      Object.getPrototypeOf(replacedVideoStream).isScreensharing = () => true;
      this._screenshareStream = replacedVideoStream;

      // Update the constraints
      this.store.dispatch(
        mediaDeviceActions.updateStreamConstraints({
          constraints: {
            video: this._screenshareStream.isVideoEnabled(),
            audio: this._screenshareStream.isAudioEnabled(),
            screensharing: this._screenshareStream.isScreensharing()
          }
        })
      );
      this.appLoader.hideLoader();
      return {stream: this._screenshareStream, screenStream};
    } catch (error) {
      this.logger.error('Failed to create screensharing stream', error);
      this.appLoader.hideLoader();
      return null;
    }
  }
  async changeScreenshareToLocalStream(): Promise<Stream> {
    // this.store.dispatch(consultationActions.isScreensharing({ status: false }));
    if (this._screenshareStream) {
      this._screenshareStream.release();
    }
    this._screenshareStream = null;

    const stream = await this.createStream();
    return stream;
  }

  // Only creates the stream as per the given configuration
  async createStream(options?: CreateStreamOptions, forceNew = false): Promise<Stream | null> {
    try {
      const audioInputId = options?.audioInputId || this.defaultMediaDevices.audioInputId;
      const videoInputId = options?.videoInputId || this.defaultMediaDevices.videoInputId;

      if (
        this._localStream?.videoInput === videoInputId &&
        this._localStream?.audioInput === audioInputId &&
        !forceNew
      ) {
        return this._localStream;
      }

      // create new stream
      this.appLoader.showLoader();
      const streamOptions: CreateStreamOptions = {
        audioInputId: audioInputId,
        videoInputId: videoInputId
      };
      const stream = await this.apirtc.userAgent.createStream(streamOptions);
      Object.getPrototypeOf(stream).isScreensharing = () => false;
      this.appLoader.hideLoader();
      return stream;
    } catch (error) {
      this.logger.debug('Failed to create stream', error);
      this.appLoader.hideLoader();

      const mediaConfirm = this.dialog.open(AppDialogComponent, {
        ariaLabel: this.translate.instant('COMMON.error_dialog_aria_label'),
        data: {
          icon: 'error',
          text: this.translate.instant('STREAMS_SERVICE.create_stream_error'),
          showConfirmButton: true,
          confirmButtonText: this.translate.instant('STREAMS_SERVICE.support'),
          confirmButtonStyle: 'alm-btn-outlined'
        }
      });

      throw error;
    }
  }
}

export enum LocalStreamEvents {
  CREATED = 'created',
  RELEASED = 'released'
}

export enum RemoteStreamEvents {
  ADDED = 'added',
  REMOVED = 'removed'
}
