<div class="footerBanner">
  <div class="footerContainer">
    <div>
      <a target="_blank" [href]="urls?.imprint"
        >{{ "COMMON.imprints" | translate }}
        <mat-icon
          class="externalLinkImg"
          [attr.aria-label]="'COMMON.aria_label_external_link' | translate"
          >open_in_new</mat-icon
        ></a
      >
    </div>
    <div>
      <a target="_blank" [href]="urls?.term"
        >{{ "COMMON.terms_of_use" | translate }}
        <mat-icon
          class="externalLinkImg"
          [attr.aria-label]="'COMMON.aria_label_external_link' | translate"
          >open_in_new</mat-icon
        ></a
      >
    </div>
    <div>
      <a target="_blank" [href]="urls?.privacy"
        >{{ "COMMON.data_protection" | translate }}
        <mat-icon
          class="externalLinkImg"
          [attr.aria-label]="'COMMON.aria_label_external_link' | translate"
          >open_in_new</mat-icon
        ></a
      >
    </div>
    <app-theme-selector></app-theme-selector>
  </div>
</div>
