import {Pipe, PipeTransform, Injectable} from '@angular/core';

@Pipe({name: 'prettySec'})
export class PrettySecPipe implements PipeTransform {
  constructor() {}

  transform(sec: any) {
    let paddedMin = `${Math.floor(sec / 60)}`;
    let paddedSec = `${Math.floor(sec % 60)}`;
    if (parseInt(paddedMin) < 10) {
      paddedMin = `0${paddedMin}`;
    }
    if (parseInt(paddedSec) < 10) {
      paddedSec = `0${paddedSec}`;
    }

    return `${paddedMin}:${paddedSec}`;
  }
}
