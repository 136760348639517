import {Pipe, PipeTransform, Injectable} from '@angular/core';
import * as moment from 'moment';

@Pipe({name: 'timeDiff'})
export class TimeDiffPipe implements PipeTransform {
  constructor() {}

  transform(start: string, end: string): number {
    if (!start || !end) {
      return 0;
    }
    let diffInSeconds = moment(start).diff(moment(end), 'seconds');
    diffInSeconds = Math.abs(diffInSeconds);
    return diffInSeconds;
  }
}
