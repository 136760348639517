<div class="scrollable">
  <div class="instructions mt-3">
    <p>{{ "MEDIA_PERMISSIONS.no_media_access" | translate }}</p>
    <p>{{ "MEDIA_PERMISSIONS.enable_media_access" | translate }}</p>
    <p *ngIf="browser !== 'default'">
      {{ "MEDIA_PERMISSIONS.steps" | translate: {browserName: browser} }}
    </p>
    <p
      class="pb-4 pt-4"
      *ngIf="browser === 'default'"
      [innerHTML]="'MEDIA_PERMISSIONS.default_browser' | translate"></p>

    <div *ngIf="isChrome">
      <app-chrome-permissions></app-chrome-permissions>
    </div>

    <div *ngIf="isMozilla">
      <app-firefox-permissions></app-firefox-permissions>
    </div>

    <div *ngIf="isSafari">
      <app-safari-permissions></app-safari-permissions>
    </div>

    <p class="note mb-1 mt-4">
      {{ "MEDIA_PERMISSIONS.note" | translate }}
    </p>
  </div>
</div>
