import {Injectable} from '@angular/core';
import {ReceivedInvitation} from '@apirtc/apirtc';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {chatActions, consultationActions} from 'src/app/store/actions';
import {appConfigSelectors, consultationSelectors} from 'src/app/store/selectors';
import {
  ApirtcDataChannelDataReceived,
  ApirtcTransferInformation,
  DataChannelChatAckEvent,
  DataChannelData,
  DataChannelDataEvents,
  DataChannelDeclineReason,
  DataChannelEvents,
  DataChannelFileEvent,
  DataChannelTextEvent,
  ExtendedDataChannel,
  FileShareStatusEvent
} from '../../models/apirtc.models';
import {AppState} from '../../models/app-state.models';
import {ChatMessageStatus, ChatType} from '../../models/chat.models';
import {AppSnackBarService} from '../snackbar.service';
import {ChatNotificationsService} from '../notifications.service';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ApirtcDataChannelService {
  private messageWindowOpen = false;
  private documentShared$ = new BehaviorSubject<boolean>(false);

  constructor(
    private logger: NGXLogger,
    private store: Store<AppState>,
    private snackBarService: AppSnackBarService,
    private translate: TranslateService,
    private notifications: ChatNotificationsService
  ) {
    this.store
      .select(appConfigSelectors.selectMessageWindowStatus)
      .subscribe((r) => (this.messageWindowOpen = r));
  }

  get isDocumentShared() {
    return this.documentShared$;
  }

  private documentSharedInChat(value: boolean) {
    this.documentShared$.next(value);
  }

  private textReceived(
    messageId: string,
    data: DataChannelData<DataChannelTextEvent>,
    dataChannel: ExtendedDataChannel
  ) {
    this.store.dispatch(
      chatActions.createChatMessage({
        chatMessage: {
          apirtcSessionId: dataChannel.getContact().getId(),
          id: messageId,
          isRemote: true,
          msg: data.msg,
          sentAt: null,
          status: ChatMessageStatus.received,
          readAt: new Date().toISOString(),
          chatType: data.chatType
        },
        userUUID: dataChannel.getContact().getUserData().get('uuid')
      })
    );
    let acknowledgeChatReceived: DataChannelData<DataChannelChatAckEvent> = {
      chatType: ChatType.ack,
      event: DataChannelDataEvents.ackRecivied,
      messageId: messageId,
      readAt: new Date().toISOString()
    };
    this.notifications.updateChatNotificationStatus();
    dataChannel.sendData(JSON.stringify(acknowledgeChatReceived));
  }

  private fileReceived(
    messageId: string,
    data: DataChannelData<DataChannelFileEvent>,
    dataChannel: ExtendedDataChannel
  ) {
    this.store.dispatch(
      chatActions.createChatMessage({
        chatMessage: {
          apirtcSessionId: dataChannel.getContact().getId(),
          id: messageId,
          isRemote: true,
          msg: data.base64,
          sentAt: null,
          status: ChatMessageStatus.received,
          readAt: new Date().toISOString(),
          chatType: data.chatType,

          fileName: data.fileName,
          fileSize: data.fileSize,
          fileType: data.fileType
        },
        userUUID: dataChannel.getContact().getUserData().get('uuid')
      })
    );
    let acknowledgeChatReceived: DataChannelData<DataChannelChatAckEvent> = {
      chatType: ChatType.ack,
      event: DataChannelDataEvents.ackRecivied,
      messageId: messageId,
      readAt: new Date().toISOString()
    };
    this.documentSharedInChat(true);
    this.notifications.updateChatNotificationStatus();
    dataChannel.sendData(JSON.stringify(acknowledgeChatReceived));
  }

  private snapRequestReceieved() {
    this.store.dispatch(consultationActions.requestSnapshot({requestedSnapshot: true}));
  }

  private fileShareRequestReceieved() {
    this.store.dispatch(consultationActions.requestFileSharing({requestedFileSharing: true}));
  }

  private fileShareRequestAllowed() {
    this.store.dispatch(consultationActions.fileSharingAllowed());
    this.snackBarService.createSnackBar(
      this.translate.instant('DATA_CHANNEL_SERVICE.file_request_allowed'),
      ''
    );
  }

  private fileShareRequestDenied() {
    this.store.dispatch(consultationActions.fileSharingDenied());
    this.snackBarService.createSnackBar(
      this.translate.instant('DATA_CHANNEL_SERVICE.file_request_denied'),
      ''
    );
  }

  private fileShareRequestStatus(contactId: string) {
    this.store.dispatch(consultationActions.requestFileSharingStatus({requesterID: contactId}));
  }

  private fileShareStatus(status: FileShareStatusEvent) {
    status.canShare
      ? this.store.dispatch(consultationActions.filesAccepted())
      : this.store.dispatch(consultationActions.filesDenied());
    status.accepted
      ? this.store.dispatch(consultationActions.fileSharingAllowed())
      : this.store.dispatch(consultationActions.fileSharingDenied());
  }

  private snapResponseDenyReceieved() {
    this.snackBarService.createSnackBar(
      this.translate.instant('DATA_CHANNEL_SERVICE.snap_request_denied'),
      ''
    );
  }

  async acceptDataChannel(invitation: ReceivedInvitation) {
    try {
      const userUUID = invitation.getSender().getUserData().get('uuid');
      if (!userUUID) {
        this.logger.error(
          'Invalid User UUID for the invited user declining datachannel request',
          invitation.decline(DataChannelDeclineReason.UUID_NULL)
        );
        return;
      }
      //   TODO: implement error handling and reconnection if data channel invite failed to accept
      const dataChannel = (await invitation.accept()) as ExtendedDataChannel;

      dataChannel
        .on(DataChannelEvents.dataReceived, (data: ApirtcDataChannelDataReceived) => {
          // this.logger.debug(
          //   'DataChannel:',
          //   DataChannelEvents.dataReceived,
          //   data
          // );
          const eventData: DataChannelData<any> = JSON.parse(data.data);
          switch (eventData.event) {
            case DataChannelDataEvents.chat:
              this.textReceived(data.uuid, eventData, dataChannel);
              break;
            case DataChannelDataEvents.file:
              this.fileReceived(data.uuid, eventData, dataChannel);
              break;
            case DataChannelDataEvents.snapshot_request:
              this.snapRequestReceieved();
              break;
            case DataChannelDataEvents.snapshot_response_deny:
              this.snapResponseDenyReceieved();
              break;
            case DataChannelDataEvents.file_share_request:
              this.fileShareRequestReceieved();
              break;
            case DataChannelDataEvents.file_share_response_allow:
              this.fileShareRequestAllowed();
              break;
            case DataChannelDataEvents.file_share_response_deny:
              this.fileShareRequestDenied();
              break;
            case DataChannelDataEvents.file_share_status_request:
              this.fileShareRequestStatus(invitation.getSender().getUserData().get('id'));
              break;
            case DataChannelDataEvents.file_share_status_response:
              this.fileShareStatus(invitation.getSender().getUserData().get('id'));
              break;

            default:
              this.logger.debug('(not impelemented) DataChannel DataEvent:', eventData.event);
              break;
          }
        })
        .on(DataChannelEvents.transferProgress, (i: ApirtcTransferInformation) => {
          this.logger.debug(
            '(not implemented) DataChannel:',
            DataChannelEvents.transferProgress,
            dataChannel,
            i
          );
          // this.store.dispatch(
          //   chatActions.transferProgress({
          //     messageId: i?.uuid,
          //     userUUID: userUUID,
          //     progress: i.percentage,
          //   })
          // );
        })
        .on(DataChannelEvents.opened, (e) => {
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.opened, e);
        })
        .on(DataChannelEvents.accepted, (a) => {
          this.logger.debug(
            '(not implemented) DataChannel:',
            DataChannelEvents.accepted,
            dataChannel
          );
        })
        .on(DataChannelEvents.response, (...e) =>
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.response, e)
        )
        .on(DataChannelEvents.declined, (...e) =>
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.declined, e)
        )
        .on(DataChannelEvents.closed, (...e) =>
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.closed, e)
        )
        .on(DataChannelEvents.ended, (...e) =>
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.ended, e)
        )
        .on(DataChannelEvents.transferComplete, (...e) =>
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.transferComplete, e)
        )
        .on(DataChannelEvents.error, (...e) =>
          this.logger.debug('(not implemented) DataChannel:', DataChannelEvents.error, e)
        );
    } catch (error) {
      this.logger.error('Failed to accept data channel invitation', error);
    }
  }
}
