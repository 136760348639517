<div
  class="footer-links"
  [ngClass]="{
    partial: isPartial,
    'mobile-footer': mobile,
    'small-height': smallHeight
  }">
  <div *ngIf="!smallHeight">
    <app-lang-selector></app-lang-selector>
  </div>
  <div>
    <a target="_blank" [href]="urls.imprint"
      >{{ "COMMON.imprints" | translate }}
      <mat-icon
        class="externalLinkImg"
        [attr.aria-label]="'COMMON.aria_label_external_link' | translate"
        >open_in_new</mat-icon
      >
    </a>
  </div>
  <div class="spacer" *ngIf="!mobile"></div>
  <div>
    <a target="_blank" [href]="urls.term"
      >{{ "COMMON.terms_of_use" | translate }}
      <mat-icon
        class="externalLinkImg"
        [attr.aria-label]="'COMMON.aria_label_external_link' | translate"
        >open_in_new</mat-icon
      ></a
    >
  </div>
  <div>
    <a target="_blank" [href]="urls.privacy"
      >{{ "COMMON.data_protection" | translate }}
      <mat-icon
        class="externalLinkImg"
        [attr.aria-label]="'COMMON.aria_label_external_link' | translate"
        >open_in_new</mat-icon
      ></a
    >
  </div>
  <div>
    <a target="_blank" href="https://www.shl-telemedizin.de/almeda/"
      >{{ "COMMON.copyright" | translate: {year: year} }}
      <mat-icon
        class="externalLinkImg"
        [attr.aria-label]="'COMMON.aria_label_external_link' | translate"
        >open_in_new</mat-icon
      ></a
    >
  </div>
</div>
