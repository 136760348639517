import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {lastValueFrom} from 'rxjs';
import {AppLang} from 'src/app/core/models/app-state.models';

@Directive({selector: '[ariaDurationLabel]'})
export class AriaDurationLabelDirective implements AfterViewInit, OnChanges {
  @Input() duration: number = 0;
  @Input() lang: AppLang = AppLang.de;
  constructor(private el: ElementRef, private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateDuration(
      changes?.duration?.currentValue || this.duration,
      changes?.lang?.currentValue || this.lang
    );
  }

  ngAfterViewInit(): void {
    this.updateDuration(this.duration, this.lang);
  }

  private updateDuration(duration, lang) {
    this.getDurationFormat(duration).then((r) => {
      this.el.nativeElement.ariaLabel = r;
    });
  }

  private async getDurationFormat(duration: number) {
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);

    const callDuration = await lastValueFrom(
      this.translate.get('DIRECTIVES.DURATION.call_duration')
    );
    const minuteWord = await lastValueFrom(this.translate.get('DIRECTIVES.DURATION.minute'));
    const minutesWord = await lastValueFrom(this.translate.get('DIRECTIVES.DURATION.minutes'));
    const secondWord = await lastValueFrom(this.translate.get('DIRECTIVES.DURATION.second'));
    const secondsWord = await lastValueFrom(this.translate.get('DIRECTIVES.DURATION.seconds'));

    let format = '';

    if (minutes == 1 && seconds == 1) {
      format = callDuration + ` ${minutes} ` + minuteWord + ` ${seconds} ` + secondWord;
    } else if (minutes == 1 && seconds != 1) {
      format = callDuration + ` ${minutes} ` + minuteWord + ` ${seconds} ` + secondsWord;
    } else if (minutes != 1 && seconds == 1) {
      format = callDuration + ` ${minutes}  ` + minutesWord + ` ${seconds} ` + secondWord;
    } else {
      format = callDuration + ` ${minutes} ` + minutesWord + ` ${seconds} ` + secondsWord;
    }
    return format;
  }
}
