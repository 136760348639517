import {Injectable} from '@angular/core';
import {MediaDeviceList} from '@apirtc/apirtc';
import {Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {mediaDeviceActions} from 'src/app/store/actions';
import {AppState} from '../models/app-state.models';
import {UserMediaDevices} from '../models/media-devices.models';

@Injectable()
export class MediaDevicesService {
  constructor(private store: Store<AppState>, private logger: NGXLogger) {}

  updateMediaDeviceList(rawDevices: MediaDeviceList) {
    let devices: UserMediaDevices = {
      audioInputs: {},
      audioOutputs: {},
      videoInputs: {},
      default: {
        audioInputId: null,
        audioOutputId: null,
        videoInputId: null
      }
    };

    for (const i of Object.keys(rawDevices.audioinput)) {
      devices.audioInputs[rawDevices.audioinput[i].id] = rawDevices.audioinput[i];
    }

    for (const i of Object.keys(rawDevices.audiooutput)) {
      devices.audioOutputs[rawDevices.audiooutput[i].id] = rawDevices.audiooutput[i];
    }

    for (const i of Object.keys(rawDevices.videoinput)) {
      devices.videoInputs[rawDevices.videoinput[i].id] = rawDevices.videoinput[i];
    }

    this.store.dispatch(mediaDeviceActions.updateMediaDevices({devices}));
  }

  checkMediaPermissions(): Promise<boolean> {
    return navigator.mediaDevices
      .getUserMedia({audio: true, video: true})
      .then((r) => {
        r.getTracks().forEach((track) => {
          track.stop();
        });
        return true;
      })
      .catch((err) => {
        this.logger.error('Media Permission Error', err);
        return false;
      });
  }
}
