<div class="wrapper" [ngClass]="{'mobile-footer': mobile, 'small-height': smallHeight}">
  <div *ngIf="showFooter">
    <div class="imgContainer" *ngIf="!smallHeight">
      <img [src]="agentImageURL" alt="" role="presentation" />
    </div>
    <div class="details" *ngIf="!smallHeight">
      <p class="title" role="heading">{{ "COMMON.support_title" | translate }}</p>
      <p>
        {{ "COMMON.support_line_1" | translate }}
        <br />
        <span
          [innerHTML]="
            'COMMON.support_line_2' | translate: {aHref: 'tel:' + aHref, aHrefText: aHrefText}
          ">
        </span>
      </p>
    </div>
  </div>

  <div class="links-wrapper">
    <app-footer-links [mobile]="mobile" [smallHeight]="smallHeight"></app-footer-links>
  </div>
</div>
