export const environment = {
  title: 'DEV: almeda Videosprechstunde',
  production: false,
  base_api: '/api',
  apiRTC_key: 'apzkey:d8acec4ee2eb8e7930438def7d912cc5',
  environment: 'development',
  logo: {
    default: './assets/img/logo/logo.png',
    almeda: './assets/img/logo/logo.png'
  },

  urls: {
    supportHome: 'https://support.docted.de/support/home',
    doctorPortal: 'https://docted-dev.almeda.de',
    en: {
      privacy: '/assets/documents/datenschutzerklaerung_en.pdf',
      consent: '/assets/documents/einwilligungserklaerungbeistart_en.pdf',
      term: '/assets/documents/nutzungsbedingungen_en.pdf',
      imprint: '/assets/documents/impressum_en.pdf'
    },
    de: {
      privacy: '/assets/documents/datenschutzerklaerung.pdf',
      consent: '/assets/documents/einwilligungserklaerungbeistart.pdf',
      term: '/assets/documents/nutzungsbedingungen.pdf',
      imprint: '/assets/documents/impressum.pdf'
    }
  },

  notifier: {
    hostname: 'https://notify.almeda.de',
    instance: 'vp-dev.almeda.de'
  },

  patientHelpline: '+4980033355406',
  patientHelplineFormatted: '+49 800 333 554 06',

  agentImageURL: './assets/img/support-girl.png',

  allowedFramingDomain: '*'
};
