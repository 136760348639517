import {Injectable} from '@angular/core';
import {UserAgent} from '@apirtc/apirtc';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {mediaDeviceActions} from 'src/app/store/actions';
import {AppState} from '../../models/app-state.models';
import {AppStreamsService} from '../streams.service';
import {ApiRTCService} from './apirtc.service';
import {MatDialog} from '@angular/material/dialog';
import {AppDialogComponent} from 'src/app/shared/components/dialog/app-dialog.component';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class WebRTCValidate {
  constructor(
    private logger: NGXLogger,
    private translate: TranslateService,
    private apirtcService: ApiRTCService,
    private store: Store<AppState>,
    private streamsService: AppStreamsService,
    private dialog: MatDialog
  ) {}

  async checkMediaDevices(): Promise<boolean> {
    const userAgent: UserAgent = this.apirtcService.userAgent;

    if (!userAgent) {
      this.logger.error('WebRTC - Media devices userAgent is not defined yet', userAgent);
    }

    // permission check
    try {
      const stream = await this.streamsService.createStream({}, true);
      if (stream) {
        stream.release();
      }
      this.store.dispatch(mediaDeviceActions.setMediaDevicePermission({status: true}));
      this.logger.debug('WebRTC - Media devices Media permissions is granted');
    } catch (error) {
      this.store.dispatch(mediaDeviceActions.setMediaDevicePermission({status: false}));
      this.logger.error('WebRTC - Media devices permission error', error);

      // timeout
      if (error === 'timeout') {
        const timeoutDialog = this.dialog.open(AppDialogComponent, {
          ariaLabel: this.translate.instant('COMMON.info_dialog_aria_label'),
          data: {
            icon: '/assets/img/media-permissions.png',
            text: this.translate.instant('WEBRTC_VALIDATION_SERVICE.permission_timeout'),
            showConfirmButton: true,
            confirmButtonText: this.translate.instant('WEBRTC_VALIDATION_SERVICE.support'),
            confirmButtonStyle: 'alm-btn-outlined',
            showCloseButton: true
          }
        });
        return lastValueFrom(timeoutDialog.afterClosed()).then((r) => {
          return true;
        });
      } else {
        const mediaErrorDialog = this.dialog.open(AppDialogComponent, {
          ariaLabel: this.translate.instant('COMMON.error_dialog_aria_label'),
          data: {
            icon: 'error',
            text: this.translate.instant('WEBRTC_VALIDATION_SERVICE.permission_error'),
            showConfirmButton: true,
            confirmButtonText: this.translate.instant('WEBRTC_VALIDATION_SERVICE.support'),
            confirmButtonStyle: 'alm-btn-outlined',
            showCloseButton: true
          }
        });

        return lastValueFrom(mediaErrorDialog.afterClosed()).then((r) => {
          return true;
        });
      }
    }

    const mediaDevices = userAgent.getUserMediaDevices();

    if (Object.keys(mediaDevices.audioinput).length === 0) {
      this.dialog.open(AppDialogComponent, {
        ariaLabel: this.translate.instant('COMMON.error_dialog_aria_label'),
        data: {
          icon: 'error',
          text: this.translate.instant('WEBRTC_VALIDATION_SERVICE.no_microphone')
        }
      });
    }

    if (Object.keys(mediaDevices.videoinput).length === 0) {
      this.dialog.open(AppDialogComponent, {
        ariaLabel: this.translate.instant('COMMON.error_dialog_aria_label'),
        data: {
          icon: 'error',
          text: this.translate.instant('WEBRTC_VALIDATION_SERVICE.no_cam')
        }
      });
    }

    this.logger.debug('WebRTC - Media devices', mediaDevices);
  }
}
