import {Component, Input, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['footer.component.scss']
})
export class FooterComponent {
  @Input() mobile = false;
  @Input() smallHeight = false;
  @Input() aHref = environment.patientHelpline;
  @Input() aHrefText = environment.patientHelplineFormatted;
  @Input() showFooter = true;
  @Input() agentImageURL = environment.agentImageURL;
}
