import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Contact, Session, UserAgent} from '@apirtc/apirtc';
import {AppState, SessionStorageKeys} from '../../models/app-state.models';
import {User} from '../../models/user.models';
import {AppLoaderService} from '../loader.service';
import {ApiRTCService} from './apirtc.service';
import {appStatusActions} from 'src/app/store/actions';
import {NGXLogger} from 'ngx-logger';
import {ApiRTCSessionEvents, ApiRTCSessionStatus} from '../../models/apirtc.models';
import {SessionListenersService} from './session-listeners.service';
import {AppErrors} from '../../models/errors.models';
import * as moment from 'moment';
import {ParentWindowMessagingService} from '../parent-messaging.service';
import {MatDialog} from '@angular/material/dialog';
import {AppDialogComponent} from 'src/app/shared/components/dialog/app-dialog.component';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ApirtcSessionService {
  private userAgent: UserAgent;
  private _apirtcSession: Session;
  private registrationOngoing = false;
  private startedAt = moment();

  private sessionUser: User;
  private appointmentUUID: string;

  constructor(
    private logger: NGXLogger,
    private apirtcService: ApiRTCService,
    private store: Store<AppState>,
    private appLoader: AppLoaderService,
    private translate: TranslateService,
    private sessionListeners: SessionListenersService,
    private parentWindowMessage: ParentWindowMessagingService,
    private dialog: MatDialog
  ) {}

  get session() {
    return this._apirtcSession;
  }

  /*
   * This will register the session to connnect to chat or video calls
   */
  async register({user, token, appointmentUUID}: SessionRegistrationDetails) {
    try {
      if (this.registrationOngoing) {
        return;
      }

      // If session already connected
      this.userAgent = this.apirtcService.userAgent;
      const isRegistered = this.userAgent.isRegistered();
      if (isRegistered) {
        this._apirtcSession = this.userAgent.getCurrentSession();
        return this._apirtcSession;
      }

      this.sessionUser = user;
      this.appointmentUUID = appointmentUUID;

      // create a new session
      this.appLoader.showLoader();
      this.registrationOngoing = true;
      this.store.dispatch(
        appStatusActions.sessionStatusChanged({
          status: ApiRTCSessionStatus.RETRY
        })
      );

      const regiserationID = this.getRegistrationID(user.user_uid, this.startedAt);
      this._apirtcSession = await this.userAgent
        .register({
          id: regiserationID,
          userData: {
            title: user.title,
            salute: user.salute,
            firstname: user.firstname,
            lastname: user.lastname,
            startedAt: this.startedAt.toISOString(),
            uuid: user.user_uid
          },
          token: token,
          groups: [appointmentUUID], // Presence groups
          subscribeTo: [appointmentUUID]
        })
        .catch((error) => {
          if (error?.error?.message === 'register() - Session already ON') {
            this._apirtcSession = this.userAgent.getCurrentSession();
          }
          throw error;
        });

      //multiple sessions will throw error in all sessions 
      this._apirtcSession.on(ApiRTCSessionEvents.contactListUpdate, (e: any) => {
        this.verifyDuplicateSession();
      });

      this.logger.debug('apiRTC session', this._apirtcSession);

      // If multiple calls is disabled conversation won't subscribe to the added streams
      // this._apirtcSession.allowMultipleCalls(false);
      // this._apirtcSession.setUsername(name);
      this.sessionListeners.setSessionListeners(this._apirtcSession, appointmentUUID);
      this.statusSessionConnected();
      this.registrationOngoing = false;
      this.appLoader.hideLoader();
      return this._apirtcSession;
    } catch (error) {
      this.appLoader.hideLoader();
      this.registrationOngoing = false;
      this.logger.error('Failed to register a session', error);
      this.statusSessionDisconnected();
    }
  }

  private async verifyDuplicateSession() {
    const duplicateContact = this.getDuplicatesContact(
      this._apirtcSession.getOnlineContactsArray(this.appointmentUUID),
      this.sessionUser.user_uid
    );

    if (duplicateContact) {
      await this.unregister();
      const dialogRef = this.dialog.open(AppDialogComponent, {
        maxWidth: '600px',
        disableClose: true,
        ariaLabel: this.translate.instant('COMMON.warning_dialog_aria_label'),
        data: {
          icon: 'warning',
          text: this.translate.instant('SESSION_SERVICE.duplicate_session'),
          showConfirmButton: this.sessionUser.is_moderator,
          confirmButtonText: this.translate.instant('SESSION_SERVICE.close'),
          confirmButtonStyle: 'alm-btn-outlined'
        }
      });

      await lastValueFrom(dialogRef.afterClosed()).then((r) => {
        if (this.sessionUser.is_moderator) {
          this.parentWindowMessage.closeFrame();
        }
      });
      throw new Error(AppErrors.Duplicate_session);
    }
  }

  private getDuplicatesContact(contactsList: Contact[], matchUUID: string): Contact | null {
    this.logger.debug('Checking for duplicates', contactsList, matchUUID);

    const currentUserStartTime = new Date(this.startedAt.toISOString());

    for (let i = 0; i < contactsList.length; i++) {
      const contact = contactsList[i];
      if (contact.getUserData().get('uuid') === matchUUID) {
        //Throw error for the recent connection as duplicate
        const contactSessionStartTime = new Date(contact.getUserData().get('startedAt'));
        if (contactSessionStartTime.getTime() < currentUserStartTime.getTime()) {
          return contact;
        }
      }
    }
    return null;
  }

  private getRegistrationID(userUID, timestamp) {
    const currentUserUID = window.sessionStorage.getItem(SessionStorageKeys.USER_UUID);
    const currentSessionTimestamp = window.sessionStorage.getItem(
      SessionStorageKeys.SESSION_TIMESTAMP
    );

    if (currentUserUID && currentSessionTimestamp) {
      if (currentUserUID === userUID) {
        return `${userUID}_${currentSessionTimestamp}`;
      }
    }
    window.sessionStorage.setItem(SessionStorageKeys.USER_UUID, userUID);
    window.sessionStorage.setItem(SessionStorageKeys.SESSION_TIMESTAMP, String(timestamp.unix()));
    return `${userUID}_${timestamp.unix()}`;
  }

  async unregister() {
    try {
      const status = await this.userAgent.unregister();
      this.logger.debug('Unregistered session');
      return true;
    } catch (error) {
      if (error?.error?.message == 'unregister() - no current session') {
        return true;
      }
      this.logger.error('Failed to unregister the useragent session', error);
    }
  }

  private statusSessionConnected() {
    this.store.dispatch(
      appStatusActions.sessionStatusChanged({
        status: ApiRTCSessionStatus.CONNECTED
      })
    );
  }

  private statusSessionDisconnected() {
    this.store.dispatch(
      appStatusActions.sessionStatusChanged({
        status: ApiRTCSessionStatus.DISCONNECTED
      })
    );
  }
}

interface SessionRegistrationDetails {
  user: User;
  appointmentUUID: string;
  token: string;
}
