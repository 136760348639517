import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-drawer-layout',
  templateUrl: 'drawer-layout.component.html',
  styleUrls: ['drawer-layout.component.scss']
})
export class DrawerLayoutComponent {
  @Input() title = 'Title';
  @Output() close = new EventEmitter();

  constructor() {}

  onClose() {
    this.close.emit();
  }
}
