import {NgModule} from '@angular/core';
import {AppPageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {ApiRTCService} from './services/apirtc/apirtc.service';
import {ChatService} from './services/chat.service';
import {ApirtcConversationService} from './services/apirtc/conversation.service';
import {ApirtcDataChannelService} from './services/apirtc/data-channels.service';
import {ContactsUpdateService} from './services/apirtc/events/contacts-update.service';
import {SessionListenersService} from './services/apirtc/session-listeners.service';
import {MediaDevicesService} from './services/media-devices.service';
import {ParentWindowMessagingService} from './services/parent-messaging.service';
import {AppStreamsService} from './services/streams.service';
import {AppHomeComponent} from './components/home/home.component';
import {SharedModule} from '../shared/shared.module';
import {AppUtilsService} from './services/utils.service';
import {CanActivateMediaPermission} from './guards/activate-media-permission.guard';
import {ChatNotificationsService} from './services/notifications.service';

@NgModule({
  declarations: [AppPageNotFoundComponent, AppHomeComponent],
  imports: [SharedModule],
  exports: [],
  providers: [
    ApiRTCService,
    MediaDevicesService,
    SessionListenersService,
    ContactsUpdateService,
    AppStreamsService,
    ApirtcConversationService,
    ParentWindowMessagingService,
    ApirtcDataChannelService,
    ChatService,
    AppUtilsService,
    CanActivateMediaPermission,
    ChatNotificationsService
  ]
})
export class CoreModule {}
