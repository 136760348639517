<mat-drawer-container>
  <mat-drawer
    #matDrawer
    [mode]="mode"
    position="end"
    [(opened)]="drawerState"
    (closedStart)="clearLocalMediaDeviceTesting()"
    (openedChange)="drawerStateOpenChange($event)">
    <ng-container *ngIf="showChat">
      <app-chat
        [user]="oppositeParticipant"
        (closeChatDrawer)="closeDrawer()"
        (fileListRefreshed)="fileListRefreshed($event)"></app-chat>
    </ng-container>
    <ng-container *ngIf="showMediaControls">
      <media-drawer (close)="closeDrawer()"></media-drawer>
    </ng-container>
  </mat-drawer>

  <mat-drawer-content [attr.aria-hidden]="drawerState && mode === 'over'">
    <ng-content></ng-content>

    <ng-container *ngTemplateOutlet="callControlsTemp"></ng-container>

    <app-video [showSelf]="isFullScreen"></app-video>

    <!-- meeting room footer -->
    <appointment-footer *ngIf="!isWebview"></appointment-footer>
  </mat-drawer-content>
</mat-drawer-container>

<!-- If controls adds more functionality separate it to component -->
<ng-template #callControlsTemp>
  <div class="callControlsContainer" cdkDragBoundary="mat-drawer-content" cdkDrag>
    <div class="upperBar">
      <p class="mb-0">{{ oppositeParticipant | humanname: "noSalute" }}</p>
    </div>
    <div class="lowerBar">
      <div class="iconButtonsContainer">
        <button
          mat-icon-button
          (click)="openChatDrawer()"
          *ngIf="isFullScreen"
          [attr.aria-label]="'CONSULTATION.btn_chat' | translate">
          <div class="newChatStatus" *ngIf="newMessagesAvailable"></div>
          <mat-icon>chat</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="toggleVideoCam()"
          [attr.aria-label]="
            (localStreamConstraints?.video
              ? 'CONSULTATION.btn_camera_on'
              : 'CONSULTATION.btn_camera_off'
            ) | translate
          ">
          <mat-icon *ngIf="localStreamConstraints?.video">videocam</mat-icon>
          <mat-icon *ngIf="!localStreamConstraints?.video">videocam_off</mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="toggleAudio()"
          [attr.aria-label]="
            (localStreamConstraints?.audio ? 'CONSULTATION.btn_mic_on' : 'CONSULTATION.btn_mic_off')
              | translate
          ">
          <mat-icon *ngIf="localStreamConstraints?.audio">mic</mat-icon>
          <mat-icon *ngIf="!localStreamConstraints?.audio">mic_off</mat-icon>
        </button>
        <ng-container *ngIf="showFullScreenCtrl">
          <ng-container *ngTemplateOutlet="fullscreenBtn"></ng-container>
        </ng-container>
        <ng-container *ngIf="isFullScreen">
          <ng-container *ngIf="!isMobile">
            <ng-container *ngTemplateOutlet="screenshareBtn"></ng-container>
            <button
              *ngIf="showScreenshotBtn"
              mat-icon-button
              [disabled]="waitingForRemote || !isRemoteMediaEnabled"
              (click)="requestSnapshot()"
              matTooltip="{{ 'CONSULTATION.screenshot_tooltip' | translate }}"
              [matTooltipDisabled]="isRemoteMediaEnabled"
              [attr.aria-label]="'CONSULTATION.btn_screenshot' | translate">
              <mat-icon>crop</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="openMediaDrawer()"
              [attr.aria-label]="'CONSULTATION.btn_audio_video_settings' | translate">
              <mat-icon>settings</mat-icon>
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isFullScreen">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            *ngIf="isMobile"
            role="menu"
            [attr.aria-label]="'CONSULTATION.btn_meat_ball_menu' | translate">
            <mat-icon>more_vert</mat-icon>
          </button>
        </ng-container>
      </div>
      <div class="callEndButton">
        <span
          appClientStyle
          [defaultStyles]="'duration'"
          role="text"
          ariaDurationLabel
          [duration]="counter"
          [lang]="lang">
          {{ counter | prettySec }}
        </span>
        <button
          mat-button
          appClientStyle
          [defaultStyles]="'mat-button'"
          (click)="hangup()"
          [attr.aria-label]="'CONSULTATION.btn_end_call' | translate">
          {{ "VIDEO.end_call" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<mat-menu #menu="matMenu">
  <button
    *ngIf="showScreenshotBtn"
    mat-menu-item
    [disabled]="waitingForRemote || !isRemoteMediaEnabled"
    (click)="requestSnapshot()"
    matTooltip="{{ 'CONSULTATION.screenshot_tooltip' | translate }}"
    [matTooltipDisabled]="isRemoteMediaEnabled"
    [attr.aria-label]="'CONSULTATION.btn_screenshot' | translate">
    <mat-icon>crop</mat-icon>
  </button>
  <button
    mat-menu-item
    (click)="openMediaDrawer()"
    [attr.aria-label]="'CONSULTATION.btn_audio_video_settings' | translate">
    <mat-icon>settings</mat-icon>
  </button>
</mat-menu>

<ng-template #screenshareBtn>
  <button
    mat-icon-button
    [disabled]="waitingForRemote"
    (click)="enableScreensharing()"
    *ngIf="!localStreamConstraints?.screensharing"
    [attr.aria-label]="'CONSULTATION.btn_screenshare_off' | translate">
    <mat-icon>screen_share</mat-icon>
  </button>
  <button
    mat-icon-button
    (click)="disableScreensharing()"
    *ngIf="localStreamConstraints?.screensharing"
    [attr.aria-label]="'CONSULTATION.btn_screenshare_on' | translate">
    <mat-icon>stop_screen_share</mat-icon>
  </button>
</ng-template>

<ng-template #fullscreenBtn>
  <button
    mat-icon-button
    *ngIf="!isFullScreen"
    (click)="enableFullScreen()"
    [attr.aria-label]="'CONSULTATION.btn_fullscreen_on' | translate">
    <mat-icon>fullscreen</mat-icon>
  </button>

  <button
    mat-icon-button
    *ngIf="isFullScreen"
    (click)="disableFullScreen()"
    [attr.aria-label]="'CONSULTATION.btn_fullscreen_off' | translate">
    <mat-icon>fullscreen_exit </mat-icon>
  </button>
</ng-template>
