import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {lastValueFrom} from 'rxjs';
import {AppLang} from 'src/app/core/models/app-state.models';

@Directive({selector: '[ariaTimeLabel]'})
export class AriaTimeLabelDirective implements AfterViewInit, OnChanges {
  @Input() timestamp: string = '';
  @Input() lang: AppLang = AppLang.en;

  constructor(private el: ElementRef, private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateTimeLabel(
      changes?.timestamp?.currentValue || this.timestamp,
      changes?.lang?.currentValue || this.lang
    );
  }

  ngAfterViewInit(): void {
    this.updateTimeLabel(this.timestamp, this.lang);
  }

  private updateTimeLabel(date, lang) {
    this.getFormat(date, lang).then((r) => {
      this.el.nativeElement.ariaLabel = r;
    });
  }

  private async getFormat(date: string, lang: AppLang) {
    const minutes = moment(date).locale(lang).format('mm').toString();
    const hours = moment(date).locale(lang).format('HH').toString();
    const hoursInWords = await lastValueFrom(this.translate.get(`DIRECTIVES.TIME.HOURS.${hours}`));
    const minsInWords = await lastValueFrom(
      this.translate.get(`DIRECTIVES.TIME.MINUTES.${minutes}`)
    );
    let format = '';

    if (lang === AppLang.en) {
      format = `${hoursInWords} ${minsInWords}`;
    } else {
      format = `${hoursInWords} ${this.translate.instant('COMMON.hour_2')} ${minsInWords}`;
    }
    return format;
  }
}
