import {createSelector} from '@ngrx/store';
import {AppState} from 'src/app/core/models/app-state.models';
import {AppConfig} from 'src/app/core/models/app-config.models';

export const selectAppConfig = createSelector(
  (state: AppState) => state.appConfig,
  (appConfig: AppConfig) => appConfig
);

export const selectMaxWidth = createSelector(selectAppConfig, (config) => config.maxWidth);

export const selectMaxWidthTablet = createSelector(
  selectAppConfig,
  (config) => config.maxWidthTablet
);

export const selectDeviceForm = createSelector(selectAppConfig, (config) => config.isMobile);

export const selectDeviceFormTablet = createSelector(selectAppConfig, (config) => config.isTablet);

export const selectClientConfig = createSelector(
  selectAppConfig,
  (config) => config.clientConfiguration
);

export const selectWebviewStatus = createSelector(selectAppConfig, (config) => config.isWebview);

export const selectMessageWindowStatus = createSelector(
  selectAppConfig,
  (config) => config.messageWindowOpen
);

export const iOSStatus = createSelector(selectAppConfig, (config) => config.iOS);

export const selectAndroidStatus = createSelector(selectAppConfig, (config) => config.android);

export const selectTheme = createSelector(selectAppConfig, (config) => config.theme);

export const isDarkTheme = createSelector(selectAppConfig, (config) => config.isDarkTheme);

export const disableTheme = createSelector(selectAppConfig, (config) => config.disableTheme);
