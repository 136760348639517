import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {Store} from '@ngrx/store';
import {ClientTokens} from 'src/app/core/models/app-config.models';
import {appConfigSelectors} from 'src/app/store/selectors';

@Directive({
  selector: '[appClientStyle]'
})
export class ClientStyleDirective implements OnInit {
  @Input() defaultStyles: string = '';
  private clientToken = ClientTokens;

  constructor(private el: ElementRef, private renderer: Renderer2, private store: Store) {}

  ngOnInit(): void {
    this.store.select(appConfigSelectors.selectClientConfig).subscribe((c) => {
      this.clientStyle(c?.token);
    });
  }

  private clientStyle(client: ClientTokens) {
    const clientSpecificStyle = client === this.clientToken.BARMER ? 'a11y-btn-style' : '';
    const newStyle = `${this.defaultStyles} ${clientSpecificStyle}`.trim();

    this.renderer.setAttribute(this.el.nativeElement, 'class', newStyle);
  }
}
