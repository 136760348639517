import {createAction, props} from '@ngrx/store';
import {ChatMessage} from 'src/app/core/models/chat.models';

export const createChatMessage = createAction(
  '[Chat] New Message',
  props<{chatMessage: ChatMessage; userUUID: string}>()
);

export const messageSent = createAction(
  '[Chat] Chat Message status update as sent',
  props<{messageId: string; userUUID: string}>()
);

export const messageAck = createAction(
  '[Chat] Chat Message ack update',
  props<{messageId: string; userUUID: string; readAt: string}>()
);

export const transferProgress = createAction(
  '[Chat] Chat Message transfer progress update',
  props<{messageId: string; userUUID: string; progress: number}>()
);
