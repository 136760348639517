import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {fromEvent, Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {HTMLAutocomplete} from 'src/app/core/models/autocompelte.models';
import {OptionArray} from 'src/app/core/models/form.models';
import {FormElementComponent} from '../base/form-element';

@Component({
  selector: 'form-input',
  templateUrl: 'input.component.html',
  styleUrls: ['input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInputComponent),
      multi: true
    }
  ]
})
export class FormInputComponent
  extends FormElementComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy, OnChanges
{
  private onDestroyed$ = new Subject();

  @ViewChild('inputEl') inputEl: ElementRef;

  @Input() placeholder = '';
  @Input() type = 'text';
  @Input() maxLength = '524288';
  @Input() minLength;
  @Input() isPassword;
  @Input() icon;
  @Input() options;
  @Input() hint = '';
  @Input() filter: Function;
  @Input() autocomplete: HTMLAutocomplete = HTMLAutocomplete.off;

  private numbersOnlyFilter = function (value: string) {
    return value.replace(/\D+/g, '');
  };

  value: string = '';
  changed = (value: any) => {};
  touched = () => {};
  disabled: boolean;
  onInput = function (ev: Event) {
    let input = ev.target as HTMLInputElement;
    if (this.filter) {
      input.value = this.filter(input.value);
    }
    this.changed(input.value);
  };

  filteredOptions: OptionArray[];

  input$: Observable<OptionArray[]>;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.type === 'number') {
      this.filter = this.numbersOnlyFilter;
      this.type = 'text';
    }
  }

  ngAfterViewInit() {
    this.input$ = fromEvent(this.inputEl.nativeElement, 'input');

    this.input$
      .pipe(
        takeUntil(this.onDestroyed$),
        map((event: any) => this._filter(event.srcElement.value))
      )
      .subscribe((values: any) => {
        this.filteredOptions = values;
      });
  }

  get getControl() {
    if (!this.parentForm) return null;
    if (!this.controlName) return null;
    return this.parentForm.get(this.controlName) as FormControl;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.options = changes.options.currentValue;
      this.filteredOptions = this.options;
    }
  }

  writeValue(value: any): void {
    this.value = value ? value : '';
    if (this.inputEl) {
      if (this.inputEl.nativeElement.value != this.value) {
        this.inputEl.nativeElement.value = this.value;
      }
    }
  }
  registerOnChange(fn: any): void {
    this.changed = fn;
  }
  registerOnTouched(fn: any): void {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  togglePassword() {
    this.type = this.type === 'text' ? 'password' : 'text';
  }

  private _filter(value: string): OptionArray {
    const filterValue = value.toLowerCase();
    if (this.options) {
      return this.options.filter((option) => option.value.includes(filterValue));
    } else {
      return;
    }
  }

  optionSelected(event) {
    this.changed(event.option.value);
  }

  ngOnDestroy() {
    this.onDestroyed$.next(null);
    this.onDestroyed$.complete();
  }
}
