import {EntityState} from '@ngrx/entity';

export interface UsersChats {
  [userUUID: string]: EntityState<ChatMessage>;
}

export interface ChatMessage {
  apirtcSessionId?: string;
  id: string;
  msg?: string;
  sentAt?: string;
  errorMsg?: string | null;
  readAt?: string | null;
  status?: ChatMessageStatus;
  isRemote?: boolean;
  chatType?: ChatType;

  //
  fileName?: string;
  fileType?: string;
  fileSize?: number;
  transferProgress?: number;
}

export enum ChatMessageStatus {
  sending = 'sending',
  sent = 'sent',
  error = 'error',
  retry = 'retry',
  received = 'received',
  downloading = 'downloading'
}

export enum KnownImageTypes {
  'image/png' = 'image/png',
  'image/gif' = 'image/gif',
  'image/jpeg' = 'image/jpeg'
}

export enum ChatType {
  file = 'file',
  image = 'image',
  text = 'text',
  ack = 'ack',
  snapshot = 'snapshot',
  file_request = 'file_request'
}
