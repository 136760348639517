import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, take} from 'rxjs';
import {ChatService} from 'src/app/core/services/chat.service';
import {consultationActions} from '../actions';
import {consultationSelectors} from '../selectors';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/core/models/app-state.models';

@Injectable()
export class ConsultationEffects {
  private fileShareStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(consultationActions.requestFileSharingStatus),
        map((action) => {
          this.store
            .select(consultationSelectors.selectConsultation)
            .pipe(take(1))
            .subscribe((r) => {
              this.chatService.sendFileShareStatus([action.requesterID], {
                canShare: r?.canShareFiles || false,
                accepted: r?.acceptFiles || false
              });
            });
        })
      ),
    {dispatch: false}
  );
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private chatService: ChatService
  ) {}
}
