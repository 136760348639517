import {AppConfig} from './app-config.models';
import {Session} from './session.models';
import {UserMediaDevices} from './media-devices.models';
import {AppStatus} from './app-status.models';
import {Appointment, AppointmentPatientSession} from './appointment.models';
import {ConsultationConstraints} from './consultation.models';
import {OnlineParticipants} from './participants.models';
import {UsersChats} from './chat.models';

export interface AppState {
  router: any;
  appConfig: AppConfig;
  session: Session;
  mediaDevices: UserMediaDevices;
  status: AppStatus;
  appointment: AppointmentPatientSession | Appointment | null;
  consultation: ConsultationConstraints | null;
  onlineParticipants: OnlineParticipants | null;
  chats: UsersChats;
}

export enum AppLang {
  'de' = 'de',
  'en' = 'en'
}

export interface TranslatedString {
  en: string;
  de: string;
}

export enum SessionStorageKeys {
  CLIENT = 'client',
  HEADER = 'header',
  LANG = 'lang',
  LAST_KNOWN_MSG_AT = 'last_known_message_at',
  USER_UUID = 'user_uuid',
  SESSION_TIMESTAMP = 'session_timestamp',
  LAST_CONSENT_TIMESTAMP = 'last_consent_timestamp',
  THEME = 'theme'
}



export interface DefaultListParams<T> {
  offset?: number;
  limit?: number;
  sort_by?: string;
  sortable_columns?: T;
  total?: number;
  order?: string;
}

export interface EnvURLs {
  supportHome?: string;
  doctorPortal?: string;
  [AppLang.en]?: {
    privacy?: string;
    consent?: string;
    term?: string;
    imprint?: string;
  };
  [AppLang.de]?: {
    privacy?: string;
    consent?: string;
    term?: string;
    imprint?: string;
  };
}
