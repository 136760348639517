import {createAction, props} from '@ngrx/store';
import {ExitConsultationReasons} from 'src/app/core/models/apirtc.models';
import {StreamConstraints} from 'src/app/core/models/media-devices.models';

export const initConsultation = createAction(
  '[Consultation] Updated consultation details successfully',
  props<{conversationId: string | number}>()
);

export const completeConversation = createAction(
  '[Consultation] Exit consultation',
  props<{
    reason: ExitConsultationReasons;
  }>()
);

export const rejoinConversation = createAction('[Consultation] Join consultation');

export const updateRemoteUserStreamConstraints = createAction(
  '[Consultation] Updated remote user stream constraints',
  props<{
    constraints: StreamConstraints;
    userUUID: string;
    streamId: string;
  }>()
);

export const removeRemoteUserStreamConstraints = createAction(
  '[Consultation] Removed remote user stream constraints',
  props<{
    userUUID: string;
    streamId: string;
  }>()
);

export const participantExitedAt = createAction(
  '[Consultation] Participant exited conversation',
  props<{exitedAt: string; userUUID: string}>()
);

export const particpantRejoined = createAction(
  '[Consultation] Participant rejoined conversation',
  props<{userUUID: string}>()
);

export const particpantJoined = createAction(
  '[Consultation] Participant joined conversation',
  props<{userUUID: string; joinedAt: string; conferenceID: string}>()
);

export const participantDisconnected = createAction(
  '[Consultation] Participant disconnected conversation',
  props<{conferenceID: string}>()
);

export const newMessages = createAction('[Consultation] New messages available from participants');

export const clearNewMessages = createAction(
  '[Consultation] Cleared new messages from participants'
);

export const requestSnapshot = createAction(
  '[Consultation] Requested screenshot from the participant',
  props<{requestedSnapshot: boolean}>()
);

export const requestFileSharingStatus = createAction(
  '[Consultation] Requested file sharing status from the participant',
  props<{requesterID: string}>()
);

export const requestFileSharing = createAction(
  '[Consultation] Requested file sharing access from the participant',
  props<{requestedFileSharing: boolean}>()
);

export const filesAccepted = createAction('[Consultation] Files accepting');
export const filesDenied = createAction('[Consultation] Files denied');

export const fileSharingAllowed = createAction('[Consultation] File sharing permission allowed');
export const fileSharingDenied = createAction('[Consultation] File sharing permission denied');

export const fileSharingRequestsIncrement = createAction(
  '[Consultation] File sharing permission requested'
);
