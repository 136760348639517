<div class="wrapper">
  <div
    class="sr-only"
    role="text"
    [attr.aria-label]="'CHAT.chat_window' | translate"
    tabindex="0"></div>
  <div class="chatHeaderContainer">
    <div class="profileContainer">
      <button
        mat-icon-button
        *ngIf="isMobile"
        (click)="onClose()"
        [attr.aria-label]="'CHAT.btn_back' | translate">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <div class="profile">
        <div class="statusDot" role="status">
          <div
            *ngIf="!online"
            role="text"
            [attr.aria-label]="'CHAT.participant_offline' | translate">
            <mat-icon svgIcon="user_offline"></mat-icon>
          </div>
          <div *ngIf="online" role="text" [attr.aria-label]="'CHAT.participant_online' | translate">
            <mat-icon svgIcon="user_online"></mat-icon>
          </div>
        </div>
        <div class="userInfo">
          <p class="name">{{ user | humanname: "noSalute" }}</p>
          <div class="schedule">
            <p role="text" ariaDateLabel [date]="appointment?.schedule" [lang]="lang">
              {{ appointment?.schedule | date: "dd.MM.yyyy" }}
            </p>
            <p role="text" ariaTimeLabel [timestamp]="appointment?.schedule" [lang]="lang">
              {{ appointment?.schedule | date: "HH:mm" }}
              <span aria-hidden="true">
                {{ "CHAT.hour" | translate }}
              </span>
            </p>
            <p class="duration">{{ appointment?.duration }} {{ "CHAT.minutes" | translate }}</p>
          </div>
        </div>
      </div>
    </div>
    <button
      mat-icon-button
      *ngIf="!isMobile"
      (click)="onClose()"
      [attr.aria-label]="'CHAT.btn_close' | translate">
      <mat-icon *ngIf="!isMobile">close</mat-icon>
    </button>
  </div>

  <div class="fileSharingStatus pl-4 pr-4">
    <p class="mb-0" *ngIf="consultation?.acceptFiles && !consultation?.canShareFiles">
      {{ "CHAT.files_accepted" | translate }}
    </p>
    <p class="mb-0" *ngIf="consultation?.canShareFiles && !consultation?.acceptFiles">
      {{ "CHAT.files_can_share" | translate }}
    </p>
    <p class="mb-0" *ngIf="consultation?.acceptFiles && consultation?.canShareFiles">
      {{ "CHAT.files_share_receive" | translate }}
    </p>
    <button
      mat-button
      class="w-100 alm-btn-primary"
      (click)="sendFileShareRequest()"
      [disabled]="!online"
      [attr.aria-label]="'CHAT.btn_file_share_request' | translate"
      *ngIf="!consultation?.canShareFiles">
      {{ "CHAT.btn_file_share_request" | translate }}
      <mat-icon> cloud_sync </mat-icon>
    </button>
    <button
      mat-button
      class="w-100 alm-btn-primary"
      (click)="denyFileShareRequest()"
      [disabled]="!online"
      [attr.aria-label]="'CHAT.btn_deny_files' | translate"
      *ngIf="consultation?.acceptFiles">
      {{ "CHAT.btn_deny_files" | translate }}
    </button>
  </div>
  <div
    class="chatMessagesContainer cusScrollBar"
    #scrollMe
    [scrollTop]="scrollMe.scrollHeight"
    role="log"
    tabindex="0">
    <div class="innerScroll">
      <ng-container *ngFor="let message of chatMessagesList; trackBy: trackByIndex">
        <span
          class="d-flex"
          [ngClass]="{
            localMessageTimestamp: !message.isRemote,
            remoteMessageTimestamp: message.isRemote
          }">
          <div class="d-flex" *ngIf="!message.isRemote && message?.sentAt">
            <p role="text" [attr.aria-label]="'CHAT.you_at' | translate">
              <span class="pr-1" aria-hidden="true">{{ "CHAT.you_at" | translate }}</span>
            </p>
            <p role="text" ariaTimeLabel [timestamp]="message.sentAt" [lang]="lang">
              {{ message?.sentAt | date: "HH:mm" }}
            </p>
          </div>
          <div class="d-flex" *ngIf="message.isRemote">
            <p role="text" [attr.aria-label]="userMessageHeader">
              <span class="pr-1" aria-hidden="true">{{ user | humanname: "noSalute" }} at</span>
            </p>
            <p role="text" ariaTimeLabel [timestamp]="message.readAt" [lang]="lang">
              {{ message?.readAt | date: "HH:mm" }}
            </p>
          </div>
        </span>
        <div
          class="d-flex localMessageContainer"
          [ngClass]="{
            localMessageContainer: !message.isRemote,
            remoteMessageContainer: message.isRemote
          }">
          <ng-container *ngIf="message.chatType === ChatType.text">
            <app-chat-text [msg]="message"></app-chat-text>
          </ng-container>
          <ng-container *ngIf="message.chatType === ChatType.file">
            <app-chat-file [msg]="message"></app-chat-file>
          </ng-container>
          <ng-container *ngIf="message.chatType === ChatType.image">
            <app-chat-file [msg]="message"></app-chat-file>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!online">
        <div class="offlineMessage">
          <p>{{ "CHAT.offline_text1" | translate }}</p>
          <p>{{ "CHAT.offline_text2" | translate }}</p>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="chatFooterContainer">
    <div class="fileUpload">
      <button
        mat-icon-button
        class="alm-btn"
        (click)="resetAndSelectFiles(msgFileInput)"
        [disabled]="!online"
        role="menu"
        [attr.aria-haspopup]="isMobile ? true : false"
        [attr.aria-label]="'CHAT.attachment_btn' | translate">
        <mat-icon *ngIf="!isMobile">attach_file</mat-icon>
        <mat-icon *ngIf="isMobile">add</mat-icon>
      </button>
    </div>
    <div class="msgInput">
      <textarea
        matInput
        cdkTextareaAutosize
        id="textInputEl"
        #txtMsgEl
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="4"
        class="cusScrollBar"
        autocomplete="off"
        placeholder=""
        value=""
        (keydown.enter)="sendTextMsgEnter($event)"
        [disabled]="!online"
        [attr.aria-label]="'CHAT.text_input_label' | translate">
      </textarea>
    </div>
    <div class="sendBtn">
      <button
        mat-icon-button
        (click)="sendTextMsgBtn(txtMsgEl)"
        [disabled]="!online"
        [attr.aria-label]="'CHAT.btn_send' | translate">
        <mat-icon>send</mat-icon>
      </button>
    </div>
  </div>
</div>

<input
  type="file"
  #msgFileInput
  (change)="fileChangeHandler($event)"
  name=""
  class="d-none"
  [multiple]="true" />
