import {createSelector} from '@ngrx/store';
import {AppState} from 'src/app/core/models/app-state.models';
import {Appointment, AppointmentPatientSession} from 'src/app/core/models/appointment.models';

export const selectAppointment = createSelector(
  (state: AppState) => state.appointment,
  (a: Appointment | AppointmentPatientSession) => a
);

export const selectAppointmentDoctor = createSelector(
  selectAppointment,
  (a: Appointment | AppointmentPatientSession) =>
    a ? a.participants.find((u) => u.is_moderator) : null
);

export const selectAppointmentPatient = createSelector(
  selectAppointment,
  (a: Appointment | AppointmentPatientSession) =>
    a ? a.participants.find((u) => !u.is_moderator) : null
);

export const selectAppointmentMessages = createSelector(
  selectAppointment,
  (a: Appointment | AppointmentPatientSession) => {
    if (!a?.appointment_messages) {
      return [];
    }
    let msgs = [...a.appointment_messages];
    return msgs.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
  }
);
