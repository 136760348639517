<div class="wrapper">
  <div id="remote-stream" class="remoteStreamContainer"></div>

  <div
    [ngClass]="{
      localStreamContainer: waitingForRemote === true,
      localStreamRemoteContainer: waitingForRemote === false,
      'd-none': !showSelf
    }"
    cdkDragBoundary=".wrapper"
    cdkDrag>
    <video
      #localStreamMediaEl
      id="localStreamVideo"
      playsInline="true"
      autoplay="true"
      role="presentation"
      [controls]="false"
      controlslist="nofullscreen nodownload noremoteplayback noplaybackrate"
      aria-hidden="true"></video>
    <button
      matRipple
      tabindex="0"
      id="changeCameraSrc"
      (click)="onChangeCameraSrc()"
      *ngIf="videoInputsArray.length > 1"
      [disabled]="isScreenSharing"
      [attr.aria-label]="'VIDEO.label_switch_camera' | translate"
      [matTooltip]="'VIDEO.switch_camera' | translate">
      <img class="w-100 h-100" src="./assets/img/svg/icons/change_cam.svg" alt="" />
    </button>
  </div>
</div>

<canvas id="localStreamCanvas" class="d-none" width="500" height="500"></canvas>
