import {createReducer, Action, on} from '@ngrx/store';
import {appointmentActions} from '../actions';
import {Appointment} from 'src/app/core/models/appointment.models';

const initialState: Appointment = null;

export function reducer(state: Appointment | undefined | null, action: Action) {
  return createReducer(
    initialState,
    on(appointmentActions.updateAppointmentSuccess, (state, action) => ({
      ...action.appointment
    }))
  )(state, action);
}
