'use strict;';
import {createReducer, Action, on} from '@ngrx/store';
import {EntityAdapter, createEntityAdapter} from '@ngrx/entity';
import {ChatMessage, ChatMessageStatus, UsersChats} from '../../core/models/chat.models';
import {chatActions} from '../actions';

export const chatsAdapter: EntityAdapter<ChatMessage> = createEntityAdapter<ChatMessage>({
  selectId: (i: ChatMessage) => i.id
});

const intialState: UsersChats = {};

export function reducer(state: UsersChats | undefined, action: Action) {
  return createReducer(
    intialState,
    on(chatActions.createChatMessage, (state, action) => ({
      ...state,
      [action.userUUID]: chatsAdapter.upsertOne(
        action.chatMessage,
        state && state[action.userUUID] ? state[action.userUUID] : chatsAdapter.getInitialState()
      )
    })),
    on(chatActions.messageSent, (state, action) => ({
      ...state,
      [action.userUUID]: chatsAdapter.updateOne(
        {
          id: action.messageId,
          changes: {
            status: ChatMessageStatus.sent
          }
        },
        state && state[action.userUUID] ? state[action.userUUID] : chatsAdapter.getInitialState()
      )
    })),
    on(chatActions.messageAck, (state, action) => ({
      ...state,
      [action.userUUID]: chatsAdapter.updateOne(
        {
          id: action.messageId,
          changes: {
            readAt: action.readAt
          }
        },
        state && state[action.userUUID] ? state[action.userUUID] : chatsAdapter.getInitialState()
      )
    })),
    on(chatActions.transferProgress, (state, action) => ({
      ...state,
      [action.userUUID]: chatsAdapter.upsertOne(
        {
          id: action.messageId,
          transferProgress: action.progress
        },
        state && state[action.userUUID] ? state[action.userUUID] : chatsAdapter.getInitialState()
      )
    }))
  )(state, action);
}
