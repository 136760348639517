import {Injectable} from '@angular/core';

@Injectable()
export class AppUtilsService {
  constructor() {}

  downloadFromFile(filesList: File[]) {
    filesList.forEach((f) => {
      var link = document.createElement('a');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.href = window.URL.createObjectURL(f);
      link.download = f.name;
      link.click();
      document.body.removeChild(link);
    });
  }

  downloadFromBase64(filesList: {fileName: string; fileData: string}[]) {
    filesList.forEach((m) => {
      let link = document.createElement('a');
      document.body.appendChild(link);
      link.href = m.fileData;
      link.download = m.fileName;
      link.click();
      document.body.removeChild(link);
    });
  }
}
