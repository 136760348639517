import {createAction} from '@ngrx/store';
import * as fromAppConfigActions from './app-config.actions';
import * as fromSessionActions from './session.actions';
import * as fromMediaDeviceActions from './media-devices.actions';
import * as fromAppStatus from './app-status.actions';
import * as fromAppointmentActions from './appointment.actions';
import * as fromConsultationActions from './consultation.actions';
import * as fromOnlineParticpants from './online-particpants.actions';
import * as fromChat from './chat.actions';

export const appConfigActions = fromAppConfigActions;
export const sessionActions = fromSessionActions;
export const mediaDeviceActions = fromMediaDeviceActions;
export const appStatusActions = fromAppStatus;
export const appointmentActions = fromAppointmentActions;
export const consultationActions = fromConsultationActions;
export const onlineParticipantsActions = fromOnlineParticpants;
export const chatActions = fromChat;

export const noOpAction = createAction('[App] No Operation');
