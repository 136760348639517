import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'form-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCheckboxComponent),
      multi: true
    }
  ]
})
export class FormCheckboxComponent implements OnInit, ControlValueAccessor {
  @ViewChild('checkBox') checkBoxElementRef!: MatCheckbox;

  @Input() parentForm!: FormGroup;
  @Input() controlName!: string;

  value: string = '';
  changed = (value: any) => {};
  touched = () => {};
  disabled?: boolean;

  constructor() {}

  ngOnInit() {}

  writeValue(value: any): void {
    this.value = value ? value : false;
  }

  registerOnChange(fn: any): void {
    this.changed = fn;
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changedStatus(event: MatCheckboxChange) {
    this.writeValue(event.checked);

    this.changed(event.checked);
    this.touched();
  }
}
