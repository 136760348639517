import {createAction, props} from '@ngrx/store';
import {
  DefaultMediaDevicesID,
  StreamConstraints,
  UserMediaDevices
} from 'src/app/core/models/media-devices.models';

export const updateMediaDevices = createAction(
  '[APIRTC Service] Media Devices List Updated',
  props<{devices: UserMediaDevices}>()
);

export const setDefaultAudioInput = createAction(
  '[Media devices] Updated Default Audio Input',
  props<{id: string}>()
);

export const setDefaultAudioOutput = createAction(
  '[Media devices] Updated Default Audio Output',
  props<{id: string}>()
);

export const setDefaultVideoInput = createAction(
  '[Media devices] Updated Default Video Input',
  props<{id: string}>()
);

export const setMediaDevicePermission = createAction(
  '[Media devices] Update media devices permission',
  props<{status: boolean}>()
);

export const setDefaultMediaDevices = createAction(
  '[Media Devices] Set Default Media Devices',
  props<{defaultDevices: DefaultMediaDevicesID}>()
);

export const updateStreamConstraints = createAction(
  '[Media devices] Update local stream constraints',
  props<{constraints: StreamConstraints}>()
);

// calls method from streams, no reducer
export const enableLocalStreamAudio = createAction(
  '[Media devices] Enabling localstream Audio if available'
);

// calls method from streams, no reducer
export const disableLocalStreamAudio = createAction(
  '[Media devices] Disabling localstream Audio if available'
);

// calls method from streams, no reducer
export const enableLocalStreamVideo = createAction(
  '[Media devices] Enabling localstream Video if available'
);

// calls method from streams, no reducer
export const disableLocalStreamVideo = createAction(
  '[Media devices] Disabling localstream Video if available'
);
