import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'phoneFormat'
})
export class PhoneFormatPipe implements PipeTransform {
  transform(
    value: {
      country_code?: string;
      phone_number?: string;
    },
    type?: any
  ): string {
    if (!value) return '';

    const countryCode = value.country_code;
    const number = value.phone_number;
    const length = number.length;

    if (type === 'phone') {
      return countryCode + number;
    }

    if (type === 'formattedPhone') {
      return (
        countryCode +
        ' ' +
        number.slice(0, 3) +
        ' ' +
        number.slice(3, 6) +
        ' ' +
        number.slice(6, 9) +
        ' ' +
        number.slice(9, length)
      );
    }
  }
}
