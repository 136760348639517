import {Contact, DataChannel, Session, UserAgent, UserAgentOptions, UserData} from '@apirtc/apirtc';
import {ChatType} from './chat.models';

export enum UserAgentEvents {
  ccsConnectionStatus = 'ccsConnectionStatus',
  externalJsLoadingStatus = 'externalJsLoadingStatus',
  mediaDeviceChanged = 'mediaDeviceChanged'
}

export enum CCSConnectionStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  ERROR = 'error',
  RETRY = 'retry'
}

export enum ApiRTCSessionStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  RETRY = 'retry'
}

export enum ApiRTCSessionEvents {
  conversationMessage = 'conversationMessage',
  conversationData = 'conversationData',
  contactMessage = 'contactMessage',
  contactData = 'contactData',
  rawData = 'rawData',
  conversationInvitation = 'conversationInvitation',
  incomingCall = 'incomingCall',
  disconnect = 'disconnect',
  error = 'error',
  reconnection = 'reconnection',
  reconnecting = 'reconnecting',
  contactListUpdate = 'contactListUpdate',
  conversationJoinRequest = 'conversationJoinRequest',
  whiteboardInvitation = 'whiteboardInvitation',
  whiteboardRoomMemberUpdate = 'whiteboardRoomMemberUpdate',
  fileTransferInvitation = 'fileTransferInvitation',
  dataChannelInvitation = 'dataChannelInvitation',
  newMediaAvailableFromContact = 'newMediaAvailableFromContact',
  customEvent = 'customEvent',
  incomingScreenSharingCall = 'incomingScreenSharingCall',
  pointerSharingInvitation = 'pointerSharingInvitation',
  pointerLocationChanged = 'pointerLocationChanged',
  incomingScreenSharingCallInvitation = 'incomingScreenSharingCallInvitation',
  cloudEventOccurred = 'cloudEventOccurred'
}

export enum ApiRTCConversationEvents {
  audioAmplitude = 'audioAmplitude',
  availableStreamsUpdated = 'availableStreamsUpdated',
  callStatsUpdate = 'callStatsUpdate',
  contactJoined = 'contactJoined',
  contactJoinedWaitingRoom = 'contactJoinedWaitingRoom',
  contactLeft = 'contactLeft',
  contactLeftWaitingRoom = 'contactLeftWaitingRoom',
  customEvent = 'customEvent',
  data = 'data',
  disconnectionWarning = 'disconnectionWarning',
  error = 'error',
  hangup = 'hangup',
  joined = 'joined',
  left = 'left',
  localStreamUpdated = 'localStreamUpdated',
  message = 'message',
  messageNotDelivered = 'messageNotDelivered',
  moderatorConnected = 'moderatorConnected',
  newMediaAvailable = 'newMediaAvailable',
  newWhiteboardSession = 'newWhiteboardSession',
  participantEjected = 'participantEjected',
  persistentDataUpdated = 'persistentDataUpdated',
  pointerLocationChanged = 'pointerLocationChanged',
  pointerSharingEnabled = 'pointerSharingEnabled',
  recordingAvailable = 'recordingAvailable',
  recordingStarted = 'recordingStarted',
  recordingStopped = 'recordingStopped',
  remoteStreamUpdated = 'remoteStreamUpdated',
  roomModeChanged = 'roomModeChanged',
  slowLink = 'slowLink',
  streamAdded = 'streamAdded',
  streamListChanged = 'streamListChanged',
  streamRemoved = 'streamRemoved',
  transferBegun = 'transferBegun',
  transferEnded = 'transferEnded',
  transferProgress = 'transferProgress',
  waitingForModeratorAcceptance = 'waitingForModeratorAcceptance'
}

export enum ApirtcStreamListeventTypes {
  added = 'added',
  removed = 'removed',
  updated = 'updated'
}

export interface RemoteStreamUpdated {
  callId: string;
  streamId: number;
  audio: boolean;
  audioMuted: boolean;
  audioSilent: boolean;
  video: boolean;
  videoMuted: boolean;
  videoSilent: boolean;
  cause: 'adaptation' | 'userAction';
}

export enum ApiRTCCustomEvents {
  exitConversation = 'exitConversation'
}

export interface ExitConsultationEventData {
  reason: ExitConsultationReasons;
}

export enum ExitConsultationReasons {
  EXIT = 'exit',
  DUPLICATE = 'duplicate',
  TERMINATED_BY_DOC = 'terminatedByDoctor',
  TERMINATED_BY_PATIENT = 'terminatedByPatient'
}

export interface UserDataExtended extends UserData {
  title: string | null;
  salute: string | null;
  firstname: string;
  lastname: string;
  startedAt: string;
  uuid: string;
}

export interface ApirtcCustomEventData<T extends object> {
  event: ApiRTCCustomEvents;
  content: T & {event: ApiRTCCustomEvents; roomname: string};
  sender: Contact;
}

export enum DataChannelEvents {
  response = 'response',
  accepted = 'accepted',
  declined = 'declined',
  opened = 'opened',
  closed = 'closed',
  ended = 'ended',
  transferProgress = 'transferProgress',
  transferComplete = 'transferComplete',
  dataReceived = 'dataReceived',
  error = 'error'
}

export enum ApirtcDataChannelStatus {
  DATACHANNEL_STATUS_WAITING = 'Symbol(DATACHANNEL_STATUS_WAITING)',
  DATACHANNEL_STATUS_ONGOING = 'Symbol(DATACHANNEL_STATUS_ONGOING)',
  DATACHANNEL_STATUS_ENDED = 'Symbol(DATACHANNEL_STATUS_ENDED)'
}

export interface ExtendedDataChannel extends DataChannel {
  sendData: (data: string) => Promise<string>;
  close: () => void;
}

export interface ApirtcTransferInformation {
  uuid: string; // identifier
  fileSize: number; // total size of the file being transfered
  remainingSize: number; // remaining data size to be transfered
  percentage: number; // progress percentage of the transfer
  sendChunkNb: number; // transfer chunk number
  transferDuration: number; // current duration of the transfer
  lastPacket: boolean; // is this the last packet?
}

export interface ApirtcDataChannelEndedEventData {
  from: 'remote' | 'local'; //When contact hangs up from 1-1 datachannel, 'from' property indicates which side initiated the ended
}

export enum DataChannelDataEvents {
  chat = 'chat',
  file = 'file',
  snapshot_request = 'snapshot_request',
  snapshot_response_deny = 'snapshot_response_deny',
  file_share_request = 'file_request',
  file_share_response_deny = 'file_response_deny',
  file_share_response_allow = 'file_response_allow',
  file_share_status_request = 'file_share_status_request',
  file_share_status_response = 'file_share_status_response',
  ackRecivied = 'ackReceived'
}

export interface ApirtcDataChannelDataReceived<T = string> {
  uuid: string;
  data: T;
}

export type DataChannelData<T> = T & {
  event: DataChannelDataEvents;
  chatType: ChatType;
};

export interface DataChannelTextEvent {
  msg: string;
}

export interface DataChannelFileEvent {
  fileName: string;
  fileType: string;
  fileSize: number;
  base64: string;
}

export interface DataChannelChatAckEvent {
  messageId: string;
  readAt: string;
}

export enum DataChannelDeclineReason {
  UUID_NULL = 'uuid_null'
}

export interface ConversationHangup {
  from: 'local' | 'remote';
  reason: 'Hangup_from_Callee' | null | string;
  streamId: number | string;
}

export interface ConversationError {
  streamId: number | string;
  streamInfo: [];
  errorCode: string;
  errorInfo:
    | 'ICE_CONNECTION_STATE_DISCONNECTED'
    | 'ICE_CONNECTION_STATE_FAILED'
    | 'DISTANT_ICE_CONNECTION_STATE_DISCONNECTED'
    | 'DISTANT_ICE_CONNECTION_STATE_FAILED'
    | 'NOT_MATCHING_CALLID_ON_200OK'
    | 'CALL_NOT_FOUND_ON_UPDATE'
    | 'CALL_NOT_FOUND_ON_200UPDATE'
    | 'CALL_ABORTED'
    | 'SET_LOCAL_DESCRIPTION_FAILURE'
    | 'SET_REMOTE_DESCRIPTION_FAILURE'
    | 'PARAMETER_ERROR_SETUNIDIRCALL'
    | 'PARAMETER_ERROR_SETNTONCONF'
    | 'PARAMETER_ERROR_SETRTPMEDIA'
    | 'PARAMETER_ERROR_SETMEDIAROUTINGMODE'
    | 'PARAMETER_ERROR_SETUSERACCEPTONINCOCALL'
    | 'UNKNOWN_EVENT_ON_ADDLISTENER'
    | 'PARAMETER_ERROR_SETALLOWMULTIPLECALLS'
    | 'START_RECORD_ROOM_NOT_FOUND'
    | 'START_RECORD_CALLER_NOT_FOUND'
    | 'START_RECORD_MEDIA_FOUND'
    | 'START_RECORD_ALREADY_ON'
    | 'STOP_RECORD_ROOM_NOT_FOUND'
    | 'STOP_RECORD_CALLER_NOT_FOUND'
    | 'STOP_RECORD_MEDIA_FOUND'
    | 'STOP_RECORD_ENDPOINT_NOT_FOUND'
    | 'STOP_RECORD_NOT_STARTED'
    | 'STOP_RECORD_RECORDER_NOT_READY'
    | 'IOS_AUTOPLAY_PREVENTED'
    | 'MEDIA_UPDATE_ERROR'
    | 'USER_MEDIA_ERROR'
    | 'STREAM_NOT_AVAILABLE';
  descriptor: ConversationIceErrorDescriptor;
}

export interface ConversationIceErrorDescriptor {
  iceState: 'failed' | 'disconnected';
  previousIceState: 'disconnected' | undefined;
  mode: 'mesh' | 'sfu';
  type: 'publish' | 'subscribe';
  clientId: string;
  remoteId: string;
  mesh: {
    callerId: string;
    callerCallId: string;
    calleeId: string;
    calleeCallId: string;
  };
  stream: {
    attributes: {
      callerId: {};
      callId: {};
    };
  };
}

export interface FileShareStatusEvent {
  accepted: boolean;
  canShare: boolean;
}
