import {EventEmitter, Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {interval, lastValueFrom, takeWhile} from 'rxjs';
import {AppDialogComponent} from 'src/app/shared/components/dialog/app-dialog.component';
import {AppDialogData} from '../models/app-dialog.model';
import {TranslateService} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class ActivityMonitor {
  private disableMonitor = false;
  disableMonitorEvent = new EventEmitter();

  constructor(private dialog: MatDialog, private translate: TranslateService) {}

  public enableActivityMonitor(durationInMin = 20) {
    let durationInSec = durationInMin * 60; // Default to 1 minute if no input
    this.disableMonitor = false;

    // Start the interval
    interval(durationInSec * 1000) // Convert to milliseconds
      .pipe(takeWhile(() => !this.disableMonitor))
      .subscribe(async () => {
        const dialogRef = this.dialog.open(AppDialogComponent, {
          data: {
            icon: 'warning',
            text: this.translate.instant('ACTIVITY_MONITOR.confirmation_text'),
            showConfirmButton: true,
            confirmButtonText: this.translate.instant('ACTIVITY_MONITOR.yes')
          } as AppDialogData
        });

        // Set a timeout to automatically disable monitoring after 30 seconds
        const timeout = setTimeout(() => {
          dialogRef.close(); // Close the dialog programmatically
          this.disableActivityMonitor(); // Call disableActivityMonitor
        }, 30000); // 30 seconds

        // Wait for the dialog to close
        const result = await lastValueFrom(dialogRef.afterClosed());

        // Clear the timeout if dialog is closed by user action
        clearTimeout(timeout);
      });
  }

  public disableActivityMonitor() {
    this.disableMonitor = true;
    this.disableMonitorEvent.emit(true);
  }
}
