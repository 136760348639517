import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-media-permissions',
  templateUrl: 'media-permissions.component.html',
  styleUrls: ['media-permissions.component.scss']
})
export class AppMediaPermissionsComponent {
  @Output() backToConsult = new EventEmitter<boolean>();

  browser = 'default';
  isChrome = false;
  isMozilla = false;
  isSafari = false;

  constructor() {
    let ua = navigator.userAgent;

    if (ua.match(/Edg|EdgA|EdgiOS/i)) {
      this.browser = 'default';
    } else if (ua.match(/chrome|chromium|crios/i)) {
      this.isChrome = true;
      this.browser = 'Chrome';
    } else if (ua.match(/firefox|fxios/i)) {
      this.isMozilla = true;
      this.browser = 'Mozilla Firefox';
    } else if (ua.match(/safari/i)) {
      this.isSafari = true;
      this.browser = 'Safari';
    } else {
      this.browser = 'default';
    }
  }
}
