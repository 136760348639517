import {Component, Input, OnInit, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppLang, AppState} from 'src/app/core/models/app-state.models';
import {SelectOption} from 'src/app/core/models/form.models';
import {appConfigSelectors, appSelectors, sessionSelectors} from 'src/app/store/selectors';
import {FormElementComponent} from '../base/form-element';

@Component({
  selector: 'form-select',
  templateUrl: 'select.component.html',
  styleUrls: ['select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectComponent),
      multi: true
    }
  ]
})
export class FormSelectComponent
  extends FormElementComponent
  implements OnInit, ControlValueAccessor
{
  private onDestroyed$ = new Subject();

  @Input() width: any;
  @Input() default: any;
  @Input() deselectable: boolean = false;
  @Input() options?: SelectOption[];
  @Input() sort: boolean = false;
  @Input() lang: AppLang = AppLang.de;
  @Input() icon: string = '';
  @Input() customIcon: string = '';
  @Input() placeholder: string = '';
  @Input() label: string = '';

  value: string = '';
  changed = (value: any) => {};
  touched = () => {};
  disabled?: boolean;

  isiOS = false;

  constructor(private store: Store<AppState>, private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.store
      .select(sessionSelectors.selectLang)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((l) => {
        this.lang = l;
        if (this.sort) {
          this.options?.sort((a, b) => {
            if (typeof a.label === 'string' && typeof b.label === 'string') {
              return this.compareStrings(a.label, b.label);
            } else if (a.label.hasOwnProperty(AppLang.de) && b.label.hasOwnProperty(AppLang.de)) {
              return this.compareStrings(a.label[l], b.label[l]);
            }
          });
        }
      });

    this.store
      .select(appConfigSelectors.iOSStatus)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((s) => (this.isiOS = s));
  }

  compareStrings(a: string, b: string): number {
    let x = a.toLowerCase();
    let y = b.toLowerCase();
    if (x < y) {
      return -1;
    }
    if (x > y) {
      return 1;
    }
    return 0;
  }

  writeValue(value: any): void {
    this.value = value ? value : '';
  }
  registerOnChange(fn: any): void {
    this.changed = fn;
  }
  registerOnTouched(fn: any): void {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectionChanged(e) {
    this.writeValue(e.value);

    this.changed(e.value);
    this.touched();
  }

  getLabel(option: SelectOption) {
    if (typeof option.label === 'string') {
      return this.translate.instant(option.label);
    }
    if (option.label.hasOwnProperty(AppLang.de)) {
      return option.label[this.lang];
    }
  }

  useIcon() {
    return this.icon !== '';
  }

  useCustomIcon() {
    return this.customIcon !== '';
  }
}
