<button
  mat-icon-button
  [matMenuTriggerFor]="themeOptions"
  id="themeSelectBtn"
  [ngClass]="{'d-none':disableTheme}">
  <mat-icon>dark_mode</mat-icon>
</button>

<mat-menu #themeOptions="matMenu">
  <button mat-menu-item (click)="setTheme(ThemeValues.SYSTEM)">
    {{ "APP.theme_system" | translate }}
  </button>
  <button mat-menu-item (click)="setTheme(ThemeValues.DARK)">
    {{ "APP.theme_dark" | translate }}
  </button>
  <button mat-menu-item (click)="setTheme(ThemeValues.LIGHT)">
    {{ "APP.theme_light" | translate }}
  </button>
</mat-menu>
