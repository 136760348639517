import * as fromAppConfig from './app-config.selectors';
import * as fromSession from './session.selectors';
import * as fromUserMediaDevices from './media-devices.selectors';
import * as fromAppStatus from './app-status.selectors';
import * as fromAppointment from './appointment.selectors';
import * as fromConsultation from './consultation.selectors';
import * as fromOnlineParticpants from './online-participants.selectors';
import * as fromChatSelectors from './chat.selectors';

export const appConfigSelectors = fromAppConfig;
export const sessionSelectors = fromSession;
export const userMediaDevicesSelectors = fromUserMediaDevices;
export const appStatusSelectors = fromAppStatus;
export const appointmentSelectors = fromAppointment;
export const consultationSelectors = fromConsultation;
export const onlineParticipantsSelectors = fromOnlineParticpants;
export const chatSelectors = fromChatSelectors;

export const appSelectors = {
  appConfigSelectors,
  sessionSelectors,
  userMediaDevicesSelectors,
  appStatusSelectors,
  appointmentSelectors,
  consultationSelectors,
  onlineParticipantsSelectors,
  chatSelectors
};
