import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {MaterialModule} from '../material.module';
import {AppointmentFooterComponent} from './components/appointment-footer/appointment-footer.component';
import {ChatFileComponent} from './components/chat-file/chat-file.component';
import {ChatTextComponent} from './components/chat-text/chat-text.component';
import {ChatComponent} from './components/chat/chat.component';
import {AppChromePermissions} from './components/chrome-permissions/chrome-permissions.component';
import {AppConnectionComponent} from './components/connection/connection.component';
import {AppConsultationComponent} from './components/consultation/consultation.component';
import {DoctorBannerComponent} from './components/doctor-banner/doctor-banner.component';
import {DrawerLayoutComponent} from './components/drawer-layout/drawer-layout.component';
import {AppFirefoxPermissions} from './components/firefox-permissions/firefox-permissions.component';
import {FooterLinksComponent} from './components/footer-links/footer-links.component';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {AppLangSelectorComponent} from './components/lang-selector/lang-selector.component';
import {AppLoaderComponent} from './components/loader/loader.component';
import {MediaDrawerComponent} from './components/media-drawer/media-drawer.component';
import {AppMediaPermissionsComponent} from './components/media-permissions/media-permissions.component';
import {AppSafariPermissions} from './components/safari-permissions/safari-permissions.component';
import {VideoComponent} from './components/video/video.component';
import {FormCheckboxComponent} from './form-elements/checkbox/checkbox.component';
import {FieldErrorComponent} from './form-elements/field-error/field-error.component';
import {FormInputComponent} from './form-elements/input/input.component';
import {FormSelectComponent} from './form-elements/select/select.component';
import {HumanNamePipe} from './pipes/name.pipe';
import {CustomPipesModule} from './pipes/pipes.module';
import {AriaDateLabelDirective} from './directives/aria-date.directive';
import {AriaTimeLabelDirective} from './directives/aria-time.directive';
import {AriaDurationLabelDirective} from './directives/aria-duration.directive';
import {AppDialogComponent} from './components/dialog/app-dialog.component';
import {ClientStyleDirective} from './directives/client-style.directive';
import {AppThemeSelectorComponent} from './components/theme-selector/theme-selector.component';

@NgModule({
  declarations: [
    AppLoaderComponent,
    DrawerLayoutComponent,
    HeaderComponent,
    FooterComponent,
    FooterLinksComponent,
    AppLangSelectorComponent,
    FormCheckboxComponent,
    FieldErrorComponent,
    FormSelectComponent,
    MediaDrawerComponent,
    ChatComponent,
    ChatTextComponent,
    ChatFileComponent,
    VideoComponent,
    AppConnectionComponent,
    AppointmentFooterComponent,
    AppConsultationComponent,
    MediaDrawerComponent,
    DoctorBannerComponent,
    AppMediaPermissionsComponent,
    AppChromePermissions,
    AppFirefoxPermissions,
    AppSafariPermissions,
    FormInputComponent,
    AriaDateLabelDirective,
    AriaTimeLabelDirective,
    AriaDurationLabelDirective,
    AppDialogComponent,
    ClientStyleDirective,
    AppThemeSelectorComponent
  ],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    CommonModule,
    CustomPipesModule,
    RouterModule
  ],

  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MaterialModule,
    CommonModule,
    CustomPipesModule,
    RouterModule,
    AppLoaderComponent,
    DrawerLayoutComponent,
    HeaderComponent,
    FooterComponent,
    FooterLinksComponent,
    AppLangSelectorComponent,
    FormCheckboxComponent,
    FieldErrorComponent,
    FormSelectComponent,
    MediaDrawerComponent,
    ChatComponent,
    ChatFileComponent,
    ChatTextComponent,
    VideoComponent,
    AppConnectionComponent,
    AppointmentFooterComponent,
    AppConsultationComponent,
    DoctorBannerComponent,
    AppMediaPermissionsComponent,
    AppChromePermissions,
    AppFirefoxPermissions,
    AppSafariPermissions,
    FormInputComponent,
    AriaDateLabelDirective,
    AriaTimeLabelDirective,
    AriaDurationLabelDirective,
    AppDialogComponent,
    ClientStyleDirective,
    AppThemeSelectorComponent
  ],

  providers: [HumanNamePipe]
})
export class SharedModule {}
