import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Router, ActivatedRoute} from '@angular/router';
import {Stream} from '@apirtc/apirtc';
import {Store} from '@ngrx/store';
import {TranslateCompiler, TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {Subject, takeUntil} from 'rxjs';
import {AppLang, AppState} from 'src/app/core/models/app-state.models';
import {SelectOption} from 'src/app/core/models/form.models';
import {DefaultMediaDevicesID, UserMediaDevices} from 'src/app/core/models/media-devices.models';
import {WebRTCValidate} from 'src/app/core/services/apirtc/webrtc-validation.service';
import {AppLoaderService} from 'src/app/core/services/loader.service';
import {AppStreamsService} from 'src/app/core/services/streams.service';
import {mediaDeviceActions, sessionActions} from 'src/app/store/actions';
import {
  appConfigSelectors,
  appSelectors,
  sessionSelectors,
  userMediaDevicesSelectors
} from 'src/app/store/selectors';
import {AppDialogComponent} from '../dialog/app-dialog.component';

@Component({
  selector: 'media-drawer',
  templateUrl: 'media-drawer.component.html',
  styleUrls: ['media-drawer.component.scss']
})
export class MediaDrawerComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroyed$ = new Subject();

  @ViewChild('testMedia') videoRef: ElementRef;
  @Output() close = new EventEmitter<boolean>();

  mediaDeviceSelectionGroup = this.fb.group({
    video: ['', []],
    audio: ['', []]
  });

  private defaultMedia: DefaultMediaDevicesID = {
    videoInputId: '',
    audioInputId: ''
  };

  private previewMedia: DefaultMediaDevicesID = {
    videoInputId: '',
    audioInputId: ''
  };

  videoOptions: SelectOption[] = [];
  audioOptions: SelectOption[] = [];

  // TODO: create a app config and add these options over there
  langOptions: SelectOption[] = [
    {
      label: 'English',
      value: AppLang.en
    },
    {
      label: 'Deutsch',
      value: AppLang.de
    }
  ];

  langSelectionGroup = this.fb.group({
    language: ['', []]
  });

  private isScreenSharing = false;
  private previewStream: Stream = null;
  iOS = false;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private webrtcValidate: WebRTCValidate,
    private streamsService: AppStreamsService,
    private translate: TranslateService,
    private appLoader: AppLoaderService,
    private logger: NGXLogger,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.store
      .select(userMediaDevicesSelectors.selectMediaConstraints)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((c) => {
        if (c?.screensharing) {
          this.isScreenSharing = true;
        } else {
          this.isScreenSharing = false;
        }
      });
  }

  ngOnInit() {
    this.store
      .select(appSelectors.userMediaDevicesSelectors.selectUserMediaDevices)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((m) => {
        this.updateMediaDevices(m);
      });

    this.store
      .select(sessionSelectors.selectLang)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((lang) => this.langSelectionGroup.patchValue({language: lang}));

    this.store
      .select(appConfigSelectors.iOSStatus)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((s) => (this.iOS = s));

    this.webrtcValidate.checkMediaDevices().then((r) => {
      if (r) {
        this.router.navigate(['media-permissions'], {
          relativeTo: this.activatedRoute
        });
      }
    });

    this.initMediaFormSelection();
  }

  ngAfterViewInit(): void {
    this.updatePreviewStream();
  }

  private initMediaFormSelection(): void {
    this.mediaDeviceSelectionGroup
      .get('video')
      ?.valueChanges.pipe(takeUntil(this.onDestroyed$))
      .subscribe((value) => {
        this.previewMedia.videoInputId = value;
        this.updatePreviewStream();
      });

    this.mediaDeviceSelectionGroup
      .get('audio')
      ?.valueChanges.pipe(takeUntil(this.onDestroyed$))
      .subscribe((value) => {
        this.previewMedia.audioInputId = value;
        this.updatePreviewStream();
      });
  }

  closeComponent() {
    this.releasePreviewStream();
    if (!this.isScreenSharing) {
      this.store.dispatch(
        mediaDeviceActions.setDefaultMediaDevices({
          defaultDevices: this.defaultMedia
        })
      );
    }
    this.close.emit(true);
  }

  releasePreviewStream() {
    if (this.previewStream) {
      this.previewStream?.release();
    }
  }

  private updateMediaDevices(m: UserMediaDevices) {
    this.audioOptions = Object.values(m.audioInputs).map((r) => ({
      label: r.getLabel(),
      value: r.id
    }));
    this.videoOptions = Object.values(m.videoInputs).map((r) => ({
      label: r.getLabel(),
      value: r.id
    }));

    this.mediaDeviceSelectionGroup.patchValue(
      {
        video: m.default?.videoInputId,
        audio: m.default?.audioInputId
      },
      {emitEvent: false}
    );

    this.previewMedia = {
      videoInputId: m.default.videoInputId,
      audioInputId: m.default.audioInputId
    };

    this.defaultMedia = {
      videoInputId: m.default.videoInputId,
      audioInputId: m.default.audioInputId
    };
  }

  async updatePreviewStream() {
    try {
      if (this.iOS) {
        this.logger.info('Detected iOS, disabled preview stream');
        return;
      }
      this.releasePreviewStream();
      this.previewStream = await this.streamsService.createStream(this.previewMedia, true);

      const testVideoEl: any = this.videoRef.nativeElement;
      if (testVideoEl) {
        testVideoEl.autoplay = true;
        testVideoEl.muted = true;
        this.previewStream.attachToElement(testVideoEl);
      } else {
        this.previewStream.release();
      }
    } catch (error) {
      this.logger.error('Patient-component', 'error', error);
      this.appLoader.hideLoader();
      this.dialog.open(AppDialogComponent, {
        ariaLabel: this.translate.instant('COMMON.error_dialog_aria_label'),
        data: {
          icon: 'error',
          text: this.translate.instant('MEDIA_DRAWER.failed_access'),
          showCloseButton: true
        }
      });
    }
  }

  async saveSettings() {
    this.defaultMedia.audioInputId = this.previewMedia.audioInputId;
    this.defaultMedia.videoInputId = this.previewMedia.videoInputId;

    this.store.dispatch(
      mediaDeviceActions.setDefaultMediaDevices({
        defaultDevices: this.defaultMedia
      })
    );

    this.changeLanguage(this.langSelectionGroup.value.language);
    this.closeComponent();
  }

  private changeLanguage(lang: AppLang) {
    this.store.dispatch(sessionActions.changeLang({lang}));
  }

  ngOnDestroy(): void {
    this.releasePreviewStream();
    this.onDestroyed$.next(null);
    this.onDestroyed$.complete();
  }
}
