import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({providedIn: 'root'})
export class AppSnackBarService {
  private snackBarDuration = 1000 * 2;

  constructor(private snackBar: MatSnackBar) {}

  createSnackBar(message: string, action: string, snackBarTime?: number) {
    return this.snackBar.open(message, action, {
      duration: snackBarTime ? snackBarTime : this.snackBarDuration
    });
  }
}
