import {AppState} from 'src/app/core/models/app-state.models';
import {createSelector} from '@ngrx/store';

export const selectAppStatus = createSelector(
  (state: AppState) => state,
  (s) => s.status
);

export const selectUserAgentStatus = createSelector(selectAppStatus, (s) => s.userAgent);
export const selectSessionStatus = createSelector(selectAppStatus, (s) => s.session);
export const selectUserStatus = createSelector(selectAppStatus, (s) => s.user);
