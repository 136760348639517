import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AppLang, AppState} from 'src/app/core/models/app-state.models';
import {sessionActions} from 'src/app/store/actions';
import {appSelectors} from 'src/app/store/selectors';

@Component({
  selector: 'app-lang-selector',
  templateUrl: 'lang-selector.component.html',
  styleUrls: ['lang-selector.component.scss']
})
export class AppLangSelectorComponent implements OnInit, OnDestroy {
  private onDestroyed$ = new Subject();
  @Input() changeAppLang = true;
  @Output() langChanged = new EventEmitter();

  lang: AppLang = AppLang.de;

  options = [
    {label: 'English', value: AppLang.en},
    {label: 'Deutsch', value: AppLang.de}
  ];

  langForm = this.fb.group({
    lang: [this.lang]
  });

  constructor(private store: Store<AppState>, private fb: FormBuilder) {
    this.store
      .select(appSelectors.sessionSelectors.selectLang)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => {
        this.lang = r;
        this.langForm.patchValue({lang: this.lang});
      });
  }

  ngOnInit() {
    (this.langForm.get('lang') as FormControl).valueChanges
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => this.changeLanguage(r));
  }

  private changeLanguage(lang: AppLang) {
    this.langChanged.emit(lang);
    if (this.changeAppLang) {
      this.store.dispatch(sessionActions.changeLang({lang}));
    }
  }

  ngOnDestroy() {
    this.onDestroyed$.next(void 0);
    this.onDestroyed$.complete();
  }
}
