import {createAction, props} from '@ngrx/store';
import {Appointment, AppointmentPatientSession} from 'src/app/core/models/appointment.models';

export const updateAppointmentSuccess = createAction(
  '[App] Updated appointment details successfully',
  props<{appointment: AppointmentPatientSession | Appointment}>()
);

export const fetchAppointmentDetails = createAction(
  '[App] Fetching appointment details',
  props<{appointmentUUID: string}>()
);

export const updateAppointmentFailed = createAction('[App] Fetching appointment details');
