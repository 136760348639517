import {Component} from '@angular/core';
import {environment} from 'src/environments/environment';
import {SessionStorageKeys} from '../../models/app-state.models';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss']
})
export class AppHomeComponent {
  logo = environment.logo.default;
  showHeader = true;

  constructor() {
    this.showHeader = sessionStorage.getItem(SessionStorageKeys.HEADER) == 'false' ? false : true;
  }

  goToDoctorPortal() {
    window.open(environment.urls.doctorPortal, '_blank');
  }
}
