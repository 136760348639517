import {NgModule} from '@angular/core';
import {HumanNamePipe} from './name.pipe';
import {PhoneFormatPipe} from './phone-formater.pipe';
import {PrettySecPipe} from './sec-time-formater.pipe';
import {TimeDiffPipe} from './time-diff.pipe';
import {URLSafePipe} from './url-safe.pipe';
import {StripHtmlPipe} from './strip-html.pipe';

@NgModule({
  declarations: [
    HumanNamePipe,
    PrettySecPipe,
    URLSafePipe,
    TimeDiffPipe,
    PhoneFormatPipe,
    StripHtmlPipe
  ],
  imports: [],
  exports: [
    HumanNamePipe,
    PrettySecPipe,
    URLSafePipe,
    TimeDiffPipe,
    PhoneFormatPipe,
    StripHtmlPipe
  ],
  providers: []
})
export class CustomPipesModule {}
