import {createAction, props} from '@ngrx/store';
import {ApiRTCSessionStatus, CCSConnectionStatus} from 'src/app/core/models/apirtc.models';

export const userAgentStatusChanged = createAction(
  '[apiRTC] APIRTC UserAgent Status Changed',
  props<{status: CCSConnectionStatus}>()
);

export const sessionStatusChanged = createAction(
  '[apiRTC] APIRTC Session Status Changed',
  props<{status: ApiRTCSessionStatus}>()
);

export const userStatusChanged = createAction(
  '[apiRTC] User Status Changed',
  props<{status: string}>()
);
