<div class="instructions">
  <mat-tab-group>
    <mat-tab label="{{ 'FIREFOX_PERMISSIONS.computer_tab' | translate }}">
      <mat-list-item>
        <ol>
          <li>
            {{ "FIREFOX_PERMISSIONS.step_one" | translate }}
            <img
              class="mt-2 mb-2 w-100"
              src="assets/img/firefox-computer-permission.png"
              role="presentation"
              alt="firefox-permission" />
          </li>
          <li [innerHTML]="'FIREFOX_PERMISSIONS.step_two' | translate"></li>
          <li [innerHTML]="'FIREFOX_PERMISSIONS.step_three' | translate"></li>
        </ol>
      </mat-list-item>
      <mat-list-item
        [innerHTML]="'FIREFOX_PERMISSIONS.computer_tip' | translate"
        role="heading"></mat-list-item>
      <img
        class="mb-2 mt-2 w-100"
        src="assets/img/firefox-computer-tip.png"
        role="presentation"
        alt="firefox-computer-permission" />
    </mat-tab>

    <mat-tab label="{{ 'FIREFOX_PERMISSIONS.android_tab' | translate }}">
      <mat-list-item>
        {{ "FIREFOX_PERMISSIONS.android_heading" | translate }}
      </mat-list-item>
      <mat-list-item>
        {{ "FIREFOX_PERMISSIONS.android_heading_two" | translate }}
      </mat-list-item>
      <mat-list-item>
        <ol>
          <li>
            {{ "FIREFOX_PERMISSIONS.android_step_one" | translate }}
            <mat-icon [inline]="true">lock</mat-icon>
            <ul>
              <li [innerHTML]="'FIREFOX_PERMISSIONS.android_step_one_if' | translate"></li>
              <img
                class="mt-2 mb-2 androidPermission"
                src="assets/img/firefox-android-permission.png"
                role="presentation"
                alt="firefox-mobile-permissiosn" />
              <li>
                {{ "FIREFOX_PERMISSIONS.android_step_one_else" | translate }}
              </li>
            </ul>
          </li>
          <li>
            {{ "FIREFOX_PERMISSIONS.android_step_two" | translate }}
            <mat-icon [inline]="true">more_vert</mat-icon>
            <mat-icon [inline]="true">chevron_right</mat-icon>
            <mat-icon [inline]="true">settings</mat-icon>
            <span [innerHTML]="'FIREFOX_PERMISSIONS.settings' | translate"></span>
          </li>
          <li [innerHTML]="'FIREFOX_PERMISSIONS.android_step_three' | translate"></li>
        </ol>
      </mat-list-item>
    </mat-tab>

    <mat-tab label="{{ 'FIREFOX_PERMISSIONS.ios_tab' | translate }}">
      <mat-list-item [innerHTML]="'FIREFOX_PERMISSIONS.ios_heading' | translate"> </mat-list-item>
      <mat-list-item>
        <ol>
          <li [innerHTML]="'FIREFOX_PERMISSIONS.ios_step_one' | translate"></li>
          <li [innerHTML]="'FIREFOX_PERMISSIONS.ios_step_two' | translate"></li>
          <li [innerHTML]="'FIREFOX_PERMISSIONS.ios_step_three' | translate"></li>
          <img
            class="mb-2 mt-2 iosPermission"
            src="assets/img/firefox-ios-permission.png"
            role="presentation"
            alt="firefox-ios-permission" />
        </ol>
      </mat-list-item>
      <mat-list-item [innerHTML]="'FIREFOX_PERMISSIONS.ios_tip' | translate"></mat-list-item>
    </mat-tab>
  </mat-tab-group>
</div>
