import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../models/app-state.models';
import {appConfigSelectors} from 'src/app/store/selectors';
import {consultationActions} from 'src/app/store/actions';

@Injectable({providedIn: 'root'})
export class ChatNotificationsService {
  private notificationSound = new Audio('../../../../assets/sounds/notification.mp3');
  private messageWindowOpen = false;

  constructor(private store: Store<AppState>) {
    this.store
      .select(appConfigSelectors.selectMessageWindowStatus)
      .subscribe((r) => (this.messageWindowOpen = r));
  }

  public updateChatNotificationStatus() {
    this.playChatNotificationSound();
    if (this.messageWindowOpen) {
      return true;
    }
    this.store.dispatch(consultationActions.newMessages());
  }

  playChatNotificationSound() {
    this.notificationSound.play();
  }
}
