import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'form-field-error',
  templateUrl: 'field-error.component.html',
  styleUrls: ['field-error.component.scss']
})
export class FieldErrorComponent implements OnInit {
  @Input() parentForm?: FormGroup;
  @Input() controlName?: string;

  get control() {
    if (!this.controlName) {
      return null;
    }
    if (this.parentForm) {
      return this.parentForm.get(this.controlName) as FormControl;
    } else {
      return null;
    }
  }

  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  getErrorMessage() {
    if (this.controlName === 'verify_password' && this.parentForm?.errors) {
      if (this.parentForm.errors.hasOwnProperty('verifyPasswordError')) {
        return this.translate.instant('VALIDATORS.password_mismatch');
      }
    }

    if (!this.control?.errors) {
      return '';
    }

    if (this.control.errors.hasOwnProperty('required')) {
      // return this.translate.instant('VALIDATORS.required');
      return this.translate.instant('FORM_ERRORS.acceptance_error');
    } else if (this.control.errors.hasOwnProperty('durationError')) {
      // return this.translate.instant('VALIDATORS.invalid_duration');
      return this.translate.instant('FORM_ERRORS.duration');
    } else if (this.control.errors.hasOwnProperty('acceptanceError')) {
      // return this.translate.instant('VALIDATORS.required_to_accept_consent')
      return this.translate.instant('FORM_ERRORS.acceptance_error');
    } else {
      return '';
    }
  }
}
