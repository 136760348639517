import {ActionReducerMap} from '@ngrx/store';
import {routerReducer} from '@ngrx/router-store';
import {AppState} from 'src/app/core/models/app-state.models';

import * as fromAppConfigReducer from './app-config.reducer';
import * as fromSessionReducer from './session.reducer';
import * as fromMediaDeviceReducer from './media-devices.reducer';
import * as fromAppStatusReducer from './app-status.reducer';
import * as fromAppointmentReducer from './appointment.reducer';
import * as fromConsultationReducer from './consultation.reducer';
import * as fromOnlineParticipantsReducer from './online-participants.reducer';
import * as fromChatsReducer from './chat.reducer';

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  appConfig: fromAppConfigReducer.reducer,
  session: fromSessionReducer.reducer,
  mediaDevices: fromMediaDeviceReducer.reducer,
  status: fromAppStatusReducer.reducer,
  appointment: fromAppointmentReducer.reducer,
  consultation: fromConsultationReducer.reducer,
  onlineParticipants: fromOnlineParticipantsReducer.reducer,
  chats: fromChatsReducer.reducer
};
