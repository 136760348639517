import {Input, Directive} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';

@Directive()
export abstract class FormElementComponent {
  @Input() parentForm!: FormGroup;
  @Input() controlName!: string;
  @Input() label = '';

  isRequired() {
    let control = this.parentForm.get(this.controlName);

    if (!control) {
      return false;
    }

    if (control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator['required']) {
        return true;
      }
    }

    return false;
  }

  isRequiredLabel(label?: string) {
    if (!label) {
      return;
    }
    if (label[label.length - 1] === '*') {
      return label;
    }
    return label + (this.isRequired() ? '*' : '');
  }

  get Label() {
    if (!this.label) {
      return;
    }
    if (this.label[this.label.length - 1] === '*') {
      return this.label;
    }
    return this.label + (this.isRequired() ? '*' : '');
  }
}
