import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ThemeValues} from 'src/app/core/models/app-config.models';
import {AppState, SessionStorageKeys} from 'src/app/core/models/app-state.models';
import {ThemeService} from 'src/app/core/services/theme.service';
import {appConfigSelectors} from 'src/app/store/selectors';

@Component({
  selector: 'app-theme-selector',
  templateUrl: 'theme-selector.component.html',
  styleUrls: ['theme-selector.component.scss']
})
export class AppThemeSelectorComponent implements OnInit {
  ThemeValues = ThemeValues;
  disableTheme = false;

  constructor(private themeService: ThemeService, private store: Store<AppState>) {
    this.store.select(appConfigSelectors.disableTheme).subscribe((r) => (this.disableTheme = r));
  }

  ngOnInit() {}

  setTheme(t: ThemeValues) {
    sessionStorage.setItem(SessionStorageKeys.THEME, t);
    const systemThemeisDark =
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.themeService.updateTheme(systemThemeisDark);
  }
}
