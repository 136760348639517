import {Injectable} from '@angular/core';
import {ReceivedInvitation, Session} from '@apirtc/apirtc';
import {NGXLogger} from 'ngx-logger';
import {ApiRTCSessionEvents, DataChannelEvents} from '../../models/apirtc.models';
import {ApirtcDataChannelService} from './data-channels.service';
import {ContactsUpdateService} from './events/contacts-update.service';

@Injectable()
export class SessionListenersService {
  constructor(
    private logger: NGXLogger,
    private contactsUpdate: ContactsUpdateService,
    private dataChannelService: ApirtcDataChannelService
  ) {}

  setSessionListeners(session: Session, doctorUUID: string) {
    if (!session) {
      return;
    }

    session.on(ApiRTCSessionEvents.contactListUpdate, (e: any) =>
      this.contactsUpdate.updateOnlineContacts(session.getOnlineContactsArray(doctorUUID))
    );
    session.on(ApiRTCSessionEvents.dataChannelInvitation, (i: ReceivedInvitation) =>
      this.dataChannelService.acceptDataChannel(i)
    );
    session.on(ApiRTCSessionEvents.conversationMessage, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.conversationMessage, e)
    );
    session.on(ApiRTCSessionEvents.conversationData, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.conversationData, e)
    );
    session.on(ApiRTCSessionEvents.contactMessage, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.contactMessage, e)
    );
    session.on(ApiRTCSessionEvents.contactData, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.contactData, e)
    );
    session.on(ApiRTCSessionEvents.rawData, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.rawData, e)
    );
    session.on(ApiRTCSessionEvents.conversationInvitation, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.conversationInvitation, e)
    );
    session.on(ApiRTCSessionEvents.incomingCall, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.incomingCall, e)
    );
    session.on(ApiRTCSessionEvents.disconnect, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.disconnect, e)
    );
    session.on(ApiRTCSessionEvents.error, (e: any) =>
      this.logger.debug(ApiRTCSessionEvents.error, '(not implemented) session:')
    );
    session.on(ApiRTCSessionEvents.reconnection, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.reconnection, e)
    );
    session.on(ApiRTCSessionEvents.reconnecting, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.reconnecting, e)
    );
    session.on(ApiRTCSessionEvents.conversationJoinRequest, (e: any) =>
      this.logger.debug(
        '(not implemented) session:',
        ApiRTCSessionEvents.conversationJoinRequest,
        e
      )
    );
    session.on(ApiRTCSessionEvents.whiteboardInvitation, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.whiteboardInvitation, e)
    );
    session.on(ApiRTCSessionEvents.whiteboardRoomMemberUpdate, (e: any) =>
      this.logger.debug(
        '(not implemented) session:',
        ApiRTCSessionEvents.whiteboardRoomMemberUpdate,
        e
      )
    );
    session.on(ApiRTCSessionEvents.fileTransferInvitation, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.fileTransferInvitation, e)
    );
    session.on(ApiRTCSessionEvents.newMediaAvailableFromContact, (e: any) =>
      this.logger.debug(
        '(not implemented) session:',
        ApiRTCSessionEvents.newMediaAvailableFromContact,
        e
      )
    );
    session.on(ApiRTCSessionEvents.customEvent, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.customEvent, e)
    );
    session.on(ApiRTCSessionEvents.incomingScreenSharingCall, (e: any) =>
      this.logger.debug(
        '(not implemented) session:',
        ApiRTCSessionEvents.incomingScreenSharingCall,
        e
      )
    );
    session.on(ApiRTCSessionEvents.pointerSharingInvitation, (e: any) =>
      this.logger.debug(
        '(not implemented) session:',
        ApiRTCSessionEvents.pointerSharingInvitation,
        e
      )
    );
    session.on(ApiRTCSessionEvents.pointerLocationChanged, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.pointerLocationChanged, e)
    );
    session.on(ApiRTCSessionEvents.incomingScreenSharingCallInvitation, (e: any) =>
      this.logger.debug(
        '(not implemented) session:',
        ApiRTCSessionEvents.incomingScreenSharingCallInvitation,
        e
      )
    );
    session.on(ApiRTCSessionEvents.cloudEventOccurred, (e: any) =>
      this.logger.debug('(not implemented) session:', ApiRTCSessionEvents.cloudEventOccurred, e)
    );
  }

  removeSessionListeners(session: Session) {
    this.logger.debug('(not implemented)  removeSessionListeners');
  }
}
