import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'humanname'
})
export class HumanNamePipe implements PipeTransform {
  transform(
    value: {
      title?: string | null;
      salute?: string | null;
      firstname?: string;
      lastname?: string;
    },
    type?: any
  ): string {
    if (!value) return '';
    if (type === 'onlyName') {
      return this.getFirstname(value) + ' ' + this.getLastname(value);
    }
    if (type === 'noSalute') {
      return this.getTitle(value) + this.getFirstname(value) + ' ' + this.getLastname(value);
    }
    if (type === 'allName') {
      return (
        this.getSalute(value) +
        this.getTitle(value) +
        this.getFirstname(value) +
        ' ' +
        this.getLastname(value)
      );
    }
    if (type === 'onlySalute') {
      return this.getSalute(value);
    }
    if (type === 'onlyTitle') {
      return this.getTitle(value);
    }
    if (!this.getSalute(value) && !this.getTitle(value)) {
      return this.getFirstname(value) + ' ' + this.getLastname(value);
    }
    return this.getSalute(value) + this.getTitle(value) + this.getLastname(value);
  }

  constructor(private translate: TranslateService) {}

  private getSaluteNameFromEnum(datatypeEnumString: string) {
    if (!datatypeEnumString) return '';

    if (datatypeEnumString === 'Mr.') return this.translate.instant('NAME_PIPE.mr');

    if (datatypeEnumString === 'Mrs.') return this.translate.instant('NAME_PIPE.mrs');

    if (datatypeEnumString === 'other') return '';

    return '';
  }

  private getTitle(u): string {
    if (!u) return '';
    if (u.hasOwnProperty('title')) {
      if (u.title) {
        return u.title + ' ';
      }
    }
    return '';
  }

  private getSalute(u): string {
    if (!u) return '';
    if (u.hasOwnProperty('salute') && u.salute) {
      if (u.salute.toLowerCase() !== 'other') return this.getSaluteNameFromEnum(u.salute) + ' ';
    }
    return '';
  }

  private getFirstname(u): string {
    if (!u) return '...';
    if (u.hasOwnProperty('firstname')) {
      if (u.firstname) {
        return u.firstname;
      }
    }
    return '...';
  }

  private getLastname(u): string {
    if (!u) return '...';
    if (u.hasOwnProperty('lastname')) {
      if (u.lastname) {
        return u.lastname;
      }
    }
    return '...';
  }
}
