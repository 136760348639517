import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {Subject, takeUntil} from 'rxjs';
import {environment} from 'src/environments/environment';
import {AppState} from '../models/app-state.models';
import {selectUserMediaDevices} from 'src/app/store/selectors/media-devices.selectors';
import {DefaultMediaDevicesID} from '../models/media-devices.models';
import {mediaDeviceActions} from 'src/app/store/actions';

declare var window;
var AlmedaWebview;
@Injectable()
export class ParentWindowMessagingService {
  private env = environment.environment;
  private defaultMedia: DefaultMediaDevicesID = {
    videoInputId: '',
    audioInputId: ''
  };
  parentMessaging$ = new Subject();

  constructor(private logger: NGXLogger, private store: Store) {
    this.receiveMessage();
    this.store.select(selectUserMediaDevices).subscribe((m) => (this.defaultMedia = m.default));
  }

  sendIamAlive() {
    this.sendMessagesToAll<any>(ParentWindowMessageTypes.keepAlive, {
      id: 1,
      jsonrpc: '2.0',
      method: 'keepAlive',
      params: ''
    } as any);
  }

  private receiveMessage() {
    window.addEventListener('message', (event) => {
      if (
        !/^https:\/\/([a-zA-Z-.]*)\.almeda\.de$/.test(event.origin) &&
        this.env != 'development'
      ) {
        return;
      }

      switch (event?.data?.type) {
        case ParentWindowMessageTypes.closeUploadIFrame:
          this.parentMessaging$.next(ParentWindowMessageTypes.closeUploadIFrame);
          break;
        case ParentWindowMessageTypes.restartVideo:
          this.store.dispatch(
            mediaDeviceActions.setDefaultMediaDevices({
              defaultDevices: this.defaultMedia
            })
          );
          break;
        default:
          // console.log('Unknown event received', event)
          break;
      }
    });
  }

  closeFrame() {
    const type = ParentWindowMessageTypes.closeIFrame;
    const event = {type: ParentWindowMessageTypes.close_iFrame};
    this.sendMessagesToAll<any>(type, event);
  }

  enableFullScreen() {
    const type = ParentWindowMessageTypes.enableFullScreen;
    const event = {type: ParentWindowMessageTypes.enable_full_screen};
    this.sendMessagesToAll<any>(type, event);
  }

  disableFullScreen() {
    const type = ParentWindowMessageTypes.disableFullScreen;
    const event = {type: ParentWindowMessageTypes.disable_full_screen};
    this.sendMessagesToAll<any>(type, event);
  }

  private targetOrigins(): string {
    return environment.allowedFramingDomain || '*';
  }

  sendFileMessage({data, fileName}: {data: string; fileName: string}) {
    const type = ParentWindowMessageTypes.fileDownload;
    const event = {
      type: ParentWindowMessageTypes.file_Download,
      fileData: data,
      fileName: fileName,
      fileDataString: data.substring(data.indexOf(',') + 1)
    };
    this.sendMessagesToAll<any>(type, event);

    this.sendFileMessageAsArray([
      {
        fileData: event.fileData,
        fileName: event.fileName
      }
    ]);
  }

  sendFileMessageAsArray(files: {fileData: string; fileName: string}[]) {
    const type = ParentWindowMessageTypes.file_download_array;
    const event = {
      type: ParentWindowMessageTypes.file_download_array,
      files: files.map((f) => ({
        ...f,
        fileDataString: f.fileData.substring(f.fileData.indexOf(',') + 1)
      }))
    };

    this.sendMessagesToAll<any>(undefined, event);
  }

  private sendMessagesToAll<T>(
    eventAsString: ParentWindowMessageTypes,
    eventAsObject: ParentWindowMessage<T>
  ) {
    if (eventAsString) {
      if (window.webkit) {
        window?.webkit?.messageHandlers?.AlmedaWebView?.postMessage(eventAsString);
      }
      AlmedaWebview?.download(eventAsString);
      window.parent.postMessage(eventAsString, '*');
      window?.flutter_inappwebview?.callHandler('message', eventAsString);
      window?.webkit?.messageHandlers?.cordova_iab?.postMessage(eventAsString);
    }

    window.parent.postMessage(eventAsObject, '*');

    AlmedaWebview?.download(JSON.stringify(eventAsObject));

    if (window.webkit) {
      window?.webkit?.messageHandlers?.AlmedaWebView?.postMessage(eventAsObject);
    }

    // Support for flutter apps
    window?.flutter_inappwebview?.callHandler('message', eventAsObject);

    // support for ionic apps
    window?.webkit?.messageHandlers?.cordova_iab?.postMessage(JSON.stringify(eventAsObject));
  }
}

export interface ParentWindowMessage<T> {
  type: ParentWindowMessageTypes;
}

export enum ParentWindowMessageTypes {
  closeIFrame = 'closeIFrame',
  close_iFrame = 'close_iFrame',
  closeUploadIFrame = 'closeUploadIFrame',
  close_upload_IFrame = 'close_upload_IFrame',
  enableFullScreen = 'enableFullScreen',
  enable_full_screen = 'enable_full_screen',
  disableFullScreen = 'disableFullScreen',
  disable_full_screen = 'disable_full_screen',
  fileDownload = 'fileDownload',
  file_Download = 'file_download',
  fileDownloadArray = 'fileDownloadArray',
  file_download_array = 'file_download_array',
  keepAlive = 'keep_alive',
  restartVideo = 'restart_video'
}
