import {Component, Input} from '@angular/core';
import {ChatMessage} from 'src/app/core/models/chat.models';

@Component({
  selector: 'app-chat-text',
  templateUrl: 'chat-text.component.html',
  styleUrls: ['chat-text.component.scss']
})
export class ChatTextComponent {
  @Input() msg: ChatMessage;
}
