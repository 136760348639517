import {Action, createReducer, on} from '@ngrx/store';
import {AppConfig, ClientTokens, ThemeValues} from 'src/app/core/models/app-config.models';
import {AppointmentFooterConfigurations} from 'src/app/core/models/appointment.models';
import {environment} from '../../../environments/environment';
import {appConfigActions} from '../actions';

const appConfigInitialState: AppConfig = {
  clientConfiguration: {
    name: ClientTokens.ALMEDA,
    logo_url: environment.logo.default,
    footer: AppointmentFooterConfigurations.DEFAULT,
    agent_image_url: environment.agentImageURL,
    hotline_country_code: '+49',
    hotline: '80033355406'
  },
  baseUrl: environment.base_api,
  snackBarDuration: 2000,
  maxWidth: '(max-width: 768px)',
  maxWidthTablet: '(max-width: 1200px)',
  isMobile: false,
  isTablet: false,
  isWebview: false,
  languages: [
    {name: 'English', value: 'en'},
    {name: 'Deutsch', value: 'de'}
  ],
  messageWindowOpen: false,
  iOS: false,
  android: false,
  theme: ThemeValues.SYSTEM,
  isDarkTheme: false,
  disableTheme: false
};

export const appConfigReducer = createReducer(
  appConfigInitialState,
  on(appConfigActions.updateIsMobile, (state, action) => ({
    ...state,
    isMobile: action.status
  })),
  on(appConfigActions.updateIsTablet, (state, action) => ({
    ...state,
    isTablet: action.status
  })),
  on(appConfigActions.updateWebviewStatus, (state, action) => ({
    ...state,
    isWebview: action.value
  })),
  on(appConfigActions.updateClientConfig, (state, action) => ({
    ...state,
    clientConfiguration: action.clientConfig
  })),
  on(appConfigActions.messageWindowOpen, (state, action) => ({
    ...state,
    messageWindowOpen: action.value
  })),
  on(appConfigActions.iOSStatus, (state, action) => ({
    ...state,
    iOS: action.value
  })),
  on(appConfigActions.androidStatus, (state, action) => ({
    ...state,
    android: action.value
  })),
  on(appConfigActions.updateTheme, (state, action) => ({
    ...state,
    theme: action.theme
  })),
  on(appConfigActions.darkThemeStatus, (state, action) => ({
    ...state,
    isDarkTheme: action.isDarkTheme
  })),
  on(appConfigActions.disableThemes, (state, action) => ({
    ...state,
    disableTheme: true
  })),
  on(appConfigActions.enableThemes, (state, action) => ({
    ...state,
    disableTheme: false
  }))
);

export function reducer(state: AppConfig | undefined, action: Action) {
  return appConfigReducer(state, action);
}
