import {Injectable} from '@angular/core';
import {AppState, SessionStorageKeys} from '../models/app-state.models';
import {ThemeValues} from '../models/app-config.models';
import {NGXLogger} from 'ngx-logger';
import {Store} from '@ngrx/store';
import {appConfigActions} from 'src/app/store/actions';

@Injectable({providedIn: 'root'})
export class ThemeService {
  private isDarkMode = false;
  constructor(private logger: NGXLogger, private store: Store<AppState>) {}

  updateTheme(systemThemeisDark) {
    let storedTheme = sessionStorage.getItem(SessionStorageKeys.THEME);
    if (!storedTheme) {
      sessionStorage.setItem(SessionStorageKeys.THEME, ThemeValues.SYSTEM);
      storedTheme = ThemeValues.SYSTEM;
    }

    if (storedTheme === ThemeValues.SYSTEM && systemThemeisDark) {
      this.isDarkMode = true;
      sessionStorage.setItem(SessionStorageKeys.THEME, ThemeValues.SYSTEM);
      this.store.dispatch(appConfigActions.darkThemeStatus({isDarkTheme: true}));
      this.store.dispatch(appConfigActions.updateTheme({theme: ThemeValues.SYSTEM}));
      this.logger.info('System theme is preferred.');
    } else if (storedTheme === ThemeValues.DARK) {
      this.isDarkMode = true;
      sessionStorage.setItem(SessionStorageKeys.THEME, ThemeValues.DARK);
      this.store.dispatch(appConfigActions.darkThemeStatus({isDarkTheme: true}));
      this.store.dispatch(appConfigActions.updateTheme({theme: ThemeValues.DARK}));
      this.logger.info('Dark theme is selected.');
    } else {
      this.isDarkMode = false;
      this.store.dispatch(appConfigActions.darkThemeStatus({isDarkTheme: false}));
      this.store.dispatch(appConfigActions.updateTheme({theme: ThemeValues.LIGHT}));
      sessionStorage.setItem(SessionStorageKeys.THEME, ThemeValues.LIGHT);
      this.logger.info('Light theme is selected.');
    }
  }

  setTheme(t: ThemeValues) {
    sessionStorage.setItem(SessionStorageKeys.THEME, t);
    const systemThemeisDark =
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.updateTheme(systemThemeisDark);
  }
}
