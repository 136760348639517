<mat-dialog-content>
  <div class="closeButton" *ngIf="data?.showCloseButton">
    <button mat-icon-button (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-container>
    <span
      class="material-icons-outlined md-96 alm-success"
      *ngIf="data?.icon == 'success'"
      aria-hidden="true"
      >check_circle</span
    >
    <span
      class="material-icons-outlined md-96 alm-warn"
      *ngIf="data?.icon == 'warning'"
      aria-hidden="true"
      >error_outline</span
    >
    <span
      class="material-icons-outlined md-96 alm-error"
      *ngIf="data?.icon == 'error'"
      aria-hidden="true"
      >cancel</span
    >
    <span aria-hidden="true">
      <img [src]="data?.icon" alt="" />
    </span>
  </ng-container>
  <p class="mt-3" [attr.aria-label]="data?.text" tabindex="0">{{ data?.text }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    appClientStyle
    [defaultStyles]="'mat-button ' + data?.confirmButtonStyle"
    *ngIf="data?.showConfirmButton"
    (click)="onConfirm()"
    [attr.aria-label]="data?.confirmButtonText">
    {{ data?.confirmButtonText }}
  </button>
  <button
    mat-button
    appClientStyle
    [defaultStyles]="'mat-button ' + data?.cancelButtonStyle"
    *ngIf="data?.showCancelButton"
    (click)="onCancel()"
    [attr.aria-label]="data?.cancelButtonText">
    {{ data?.cancelButtonText }}
  </button>
</mat-dialog-actions>
