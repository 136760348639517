import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';
import {AppState} from 'src/app/core/models/app-state.models';
// import { apiRTCActions } from 'src/app/store/actions';

@Component({
  selector: 'app-connection',
  templateUrl: 'connection.component.html',
  styleUrls: ['connection.component.scss']
})
export class AppConnectionComponent implements OnInit, OnDestroy {
  private onDestroyed$ = new Subject();

  @Input() sessionStatus = ''; // 'CONNECTING' | 'CONNECTED' | 'DISCONNECTED'

  constructor(private store: Store<AppState>) {}

  reconnectSession() {
    // this.store.dispatch(apiRTCActions.getRegToken());
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.onDestroyed$.next(null);
    this.onDestroyed$.complete();
  }
}
