<mat-form-field class="mb-3" [style.width]="width">
  <mat-label>
    {{ isRequiredLabel(label) }}
  </mat-label>
  <span matPrefix class="select-icon">
    <mat-icon matPrefix *ngIf="useIcon()">{{ icon }}</mat-icon>
    <mat-icon matPrefix *ngIf="useCustomIcon()" svgIcon="alm_language"></mat-icon>
  </span>
  <mat-select
    [placeholder]="placeholder"
    [value]="value"
    (selectionChange)="selectionChanged($event)"
    [disabled]="disabled"
    role="listbox">
    <mat-option *ngIf="deselectable" [value]="null"></mat-option>
    <mat-option
      *ngFor="let option of options; let indx = index"
      [value]="option.value"
      [attr.aria-label]="
        isiOS
          ? ('COMMON.label_selected' | translate) +
            ' ' +
            getLabel(option) +
            ' ' +
            (indx + 1) +
            ' ' +
            ('COMMON.of' | translate) +
            ' ' +
            options.length
          : getLabel(option)
      "
      >{{ getLabel(option) }}</mat-option
    >
  </mat-select>
  <mat-hint>
    <form-field-error [parentForm]="parentForm" [controlName]="controlName"></form-field-error>
  </mat-hint>
</mat-form-field>
