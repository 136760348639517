import {Injectable} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Store} from '@ngrx/store';
import {AppState} from '../models/app-state.models';
import {appSelectors} from '../../store/selectors';
import {appConfigActions} from '../../store/actions';

@Injectable({providedIn: 'root'})
export class BreakpointsService {
  private maxWidthBP: string = '(max-width: 768px)';
  private maxWidthBPTablet: string = '(max-width: 1200px)';

  constructor(private breakpointObserver: BreakpointObserver, private store: Store<AppState>) {
    this.store.select(appSelectors.appConfigSelectors.selectMaxWidth).subscribe((width) => {
      this.maxWidthBP = width;
      this.store.dispatch(
        appConfigActions.updateIsMobile({
          status: this.breakpointObserver.isMatched(this.maxWidthBP)
        })
      );
    });

    this.breakpointObserver
      .observe(this.maxWidthBP)
      .subscribe((result) =>
        this.store.dispatch(appConfigActions.updateIsMobile({status: result.matches}))
      );

    this.store.select(appSelectors.appConfigSelectors.selectMaxWidthTablet).subscribe((width) => {
      this.maxWidthBPTablet = width;
      this.store.dispatch(
        appConfigActions.updateIsTablet({
          status: this.breakpointObserver.isMatched(this.maxWidthBPTablet)
        })
      );
    });

    this.breakpointObserver
      .observe(this.maxWidthBPTablet)
      .subscribe((result) =>
        this.store.dispatch(appConfigActions.updateIsTablet({status: result.matches}))
      );
  }
}
