import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {AppLang} from 'src/app/core/models/app-state.models';

@Directive({selector: '[ariaDateLabel]'})
export class AriaDateLabelDirective implements AfterViewInit, OnChanges {
  @Input() date: string = '';
  @Input() lang: AppLang = AppLang.de;
  constructor(private el: ElementRef, private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateDateLabel(
      changes?.date?.currentValue || this.date,
      changes?.lang?.currentValue || this.lang
    );
  }

  ngAfterViewInit(): void {
    this.updateDateLabel(this.date, this.lang);
  }

  private updateDateLabel(date, lang) {
    this.el.nativeElement.ariaLabel = moment(date)
      .locale(lang)
      .format(`Do ${lang === AppLang.en ? 'of' : ''} MMMM YYYY`);
  }
}
