import {createAction, props} from '@ngrx/store';
import {AppLang} from 'src/app/core/models/app-state.models';

export const changeLang = createAction('[Session] Update Language', props<{lang: AppLang}>());

export const headerStatus = createAction(
  '[Session] Update Header Status',
  props<{status: boolean | 'true' | 'false'}>()
);

export const startAppointmentSession = createAction(
  '[Appointment Component] Start appointment session',
  props<{appointmentToken: string}>()
);
