<div class="instructions">
  <mat-tab-group>
    <mat-tab label="{{ 'CHROME_PERMISSIONS.computer_tab' | translate }}">
      {{ "CHROME_PERMISSIONS.heading" | translate }}
      <mat-list-item>
        <ol>
          <li>
            {{ "CHROME_PERMISSIONS.step_one" | translate }}
            <ul>
              <li>
                {{ "CHROME_PERMISSIONS.lock" | translate }}
                <mat-icon [inline]="true">lock</mat-icon>
              </li>
              <li>
                {{ "CHROME_PERMISSIONS.info" | translate }}
                <mat-icon [inline]="true">info</mat-icon>
              </li>
              <li>
                {{ "CHROME_PERMISSIONS.dangerous" | translate }}
                <mat-icon class="warningIcon" [inline]="true">warning</mat-icon>
              </li>
            </ul>
          </li>
          <li [innerHTML]="'CHROME_PERMISSIONS.step_two' | translate"></li>
          <li>{{ "CHROME_PERMISSIONS.step_three" | translate }}</li>
        </ol>
      </mat-list-item>
      <mat-list-item
        [innerHTML]="'CHROME_PERMISSIONS.tips' | translate"
        role="heading"></mat-list-item>
      <mat-list-item>
        <ul>
          <li>{{ "CHROME_PERMISSIONS.camera_toggle_tip" | translate }}</li>
          <li [innerHTML]="'CHROME_PERMISSIONS.reset_tip' | translate"></li>
          <img
            class="mb-2 mt-2 w-100"
            src="assets/img/chrome-computer-permission.png"
            role="presentation"
            alt="chrome-computer-permission" />
        </ul>
      </mat-list-item>
    </mat-tab>

    <mat-tab label="{{ 'CHROME_PERMISSIONS.android_tab' | translate }}">
      {{ "CHROME_PERMISSIONS.heading" | translate }}
      <mat-list-item>
        <ol>
          <li>{{ "CHROME_PERMISSIONS.android_step_one" | translate }}</li>
          <li>
            <span>
              {{ "CHROME_PERMISSIONS.android_step_two" | translate }}
              <mat-icon [inline]="true">lock</mat-icon>
              <mat-icon [inline]="true">keyboard_arrow_right</mat-icon>
              <span [innerHTML]="'CHROME_PERMISSIONS.permissions' | translate"></span>
            </span>
          </li>
          <li>
            {{ "CHROME_PERMISSIONS.android_step_three" | translate }}
            <ul>
              <li [innerHTML]="'CHROME_PERMISSIONS.android_reset' | translate"></li>
              <img
                class="mt-2 mb-2 androidPermission"
                src="assets/img/chrome-android-permission.png"
                role="presentation"
                alt="chrome-mobile-permissions" />
            </ul>
          </li>
        </ol>
      </mat-list-item>
    </mat-tab>

    <mat-tab label="{{ 'CHROME_PERMISSIONS.ios_tab' | translate }}">
      <mat-list-item [innerHTML]="'CHROME_PERMISSIONS.ios_heading' | translate"> </mat-list-item>
      <mat-list-item>
        <ol>
          <li [innerHTML]="'CHROME_PERMISSIONS.ios_step_one' | translate"></li>
          <li [innerHTML]="'CHROME_PERMISSIONS.ios_step_two' | translate"></li>
          <li [innerHTML]="'CHROME_PERMISSIONS.ios_step_three' | translate"></li>
          <img
            class="mt-2 mb-2 iosPermission"
            src="assets/img/chrome-ios-permission.png"
            role="presentation"
            alt="chrome-ios-permission" />
        </ol>
      </mat-list-item>
      <mat-list-item [innerHTML]="'CHROME_PERMISSIONS.ios_tip' | translate"></mat-list-item>
    </mat-tab>
  </mat-tab-group>
</div>
