import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {MediaDevicesService} from 'src/app/core/services/media-devices.service';

@Injectable()
export class CanActivateMediaPermission implements CanActivate {
  constructor(private mediaDevicesService: MediaDevicesService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.mediaDevicesService.checkMediaPermissions().then((r) => {
      if (!r) {
        const module = route?.data?.module;
        const appointmentUUID = route.params?.appointmentUUID || route.params?.appointmentToken;
        if (!appointmentUUID) {
          console.error('Missing appointmentUUID in route', route.params);
          return false;
        }
        if (!module) {
          console.error(
            'Missing module(doctor|patient) in route data, please pass the data when using this route',
            route.data
          );
          return false;
        }
        if (module === 'doctor') {
          this.router.navigate(['c', appointmentUUID, 'media-permissions']);
        } else if (module === 'patient') {
          this.router.navigate(['a', appointmentUUID, 'media-permissions']);
        }
      }
      return r;
    });
  }
}
