import {Component, Input, OnInit} from '@angular/core';
import {ChatMessage, ChatType} from 'src/app/core/models/chat.models';
import {ChatService} from 'src/app/core/services/chat.service';
import {ParentWindowMessagingService} from 'src/app/core/services/parent-messaging.service';

@Component({
  selector: 'app-chat-file',
  templateUrl: 'chat-file.component.html',
  styleUrls: ['chat-file.component.scss']
})
export class ChatFileComponent implements OnInit {
  @Input() msg: ChatMessage;
  get ChatType() {
    return ChatType;
  }
  constructor(private chatService: ChatService, private parentMsg: ParentWindowMessagingService) {}

  ngOnInit() {}

  requestMobileDownload(msg: ChatMessage) {
    this.parentMsg.sendFileMessage({
      fileName: msg.fileName,
      data: msg.msg
    });
  }
}
