import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map} from 'rxjs/operators';
import {ClientTokens, ThemeValues} from 'src/app/core/models/app-config.models';
import {SessionStorageKeys} from 'src/app/core/models/app-state.models';
import {appConfigActions} from '../actions';
import {ThemeService} from 'src/app/core/services/theme.service';

declare const whitelabel;
declare let SVGInject;

@Injectable()
export class AppConfigEffects {
  private clientConfig$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(appConfigActions.updateClientConfig),
        map((action) => {
          let client = action?.clientConfig?.token;
          if (!client) {
            client = ClientTokens.ALMEDA;
          }
          sessionStorage.setItem(SessionStorageKeys.CLIENT, client);
          whitelabel.setClient(client);
        })
      ),
    {dispatch: false}
  );
  private disableTheme$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(appConfigActions.disableThemes),
        map((action) => {
          this.themeService.setTheme(ThemeValues.LIGHT);
        })
      ),
    {dispatch: false}
  );
  constructor(private action$: Actions, private themeService: ThemeService) {}
}
