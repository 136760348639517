<div class="wrapper">
  <p class="mb-0">{{ "CONNECTION.network_error" | translate }}</p>
  <button
    mat-button
    class="ml-2 mr-4"
    (click)="reconnectSession()"
    [disabled]="sessionStatus === 'CONNECTING'">
    {{ "CONNECTION.btn_reconnect" | translate }}
  </button>
</div>
