import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.scss']
})
export class AppLoaderComponent implements OnInit {
  @Input() text = '';

  constructor() {}

  ngOnInit() {}
}
