<div class="row no-gutters" [ngClass]="{'hide-brand': hideBrand}">
  <div class="col-12 col-lg-4">
    <div class="brandContainer">
      <img [src]="src" alt="logo" srcset="" />
    </div>
  </div>
  <div class="col-12 col-lg-8 projectedContent">
    <ng-content></ng-content>
  </div>
</div>
