import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import * as apiRTC from '@apirtc/apirtc';
import {Stream} from '@apirtc/apirtc';
import {Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {Subject, takeUntil} from 'rxjs';
import {DefaultMediaDevicesID, UserMediaDevices} from 'src/app/core/models/media-devices.models';
import {
  AppStreamsService,
  LocalStreamEvents,
  RemoteStreamEvents
} from 'src/app/core/services/streams.service';
import {mediaDeviceActions} from 'src/app/store/actions';
import {consultationSelectors, userMediaDevicesSelectors} from 'src/app/store/selectors';
import {selectUserMediaDevices} from 'src/app/store/selectors/media-devices.selectors';

@Component({
  selector: 'app-video',
  templateUrl: 'video.component.html',
  styleUrls: ['video.component.scss']
})
export class VideoComponent implements OnInit, AfterViewInit, OnDestroy {
  private onDestroyed$ = new Subject();
  @ViewChild('localStreamMediaEl') private localStreamMediaEl: ElementRef;
  @Input() showSelf = true;

  waitingForRemote = true;
  isScreenSharing = false;
  localStream: Stream = null;

  private mediaDevices: UserMediaDevices;
  videoInputsArray: apiRTC.MediaDevice[] = [];

  private defaultMedia: DefaultMediaDevicesID = {
    videoInputId: '',
    audioInputId: ''
  };

  constructor(
    private store: Store,
    private logger: NGXLogger,
    private streamsService: AppStreamsService
  ) {
    this.store
      .select(selectUserMediaDevices)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((m) => {
        this.mediaDevices = m;
        this.videoInputsArray = Object.values(this.mediaDevices?.videoInputs);
      });

    this.store
      .select(consultationSelectors.selectConsultation)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((r) => {
        this.waitingForRemote = r?.waitingForRemote;
      });

    this.store
      .select(userMediaDevicesSelectors.selectMediaConstraints)
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe((c) => {
        if (c?.screensharing) {
          this.isScreenSharing = true;
        } else {
          this.isScreenSharing = false;
        }
      });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.addLocalStream();
    this.streamsService.localStreamEvents$.pipe(takeUntil(this.onDestroyed$)).subscribe((r) => {
      switch (r) {
        case LocalStreamEvents.CREATED:
          this.addLocalStream();
          break;
        case LocalStreamEvents.RELEASED:
          this.removeLocalStream();
          break;
      }
    });

    this.streamsService.remoteStreamEvents$
      .pipe(takeUntil(this.onDestroyed$))
      .subscribe(this.updateRemoteStream.bind(this));
  }

  private async updateRemoteStream(e: RemoteStreamEvents) {
    if (!this.streamsService?.remoteStreams) {
      return;
    }

    const streamsList = Object.values(this.streamsService.remoteStreams);
    if (streamsList.length < 1) {
      return;
    }

    for (let i = 0; i < streamsList.length; i++) {
      const stream: Stream = streamsList[i];
      const videoElId = `conversation-remote-${stream.getContact().getUserData().get('uuid')}`;
      const isDuplicate = document.getElementById(videoElId) ? true : false;

      if (isDuplicate) {
        stream.removeFromDiv('remote-stream', videoElId);
      }
      stream.addInDiv(
        'remote-stream',
        videoElId,
        {window: '100%', height: '100%', width: '100%'},
        false
      );
    }

    // if (e === RemoteStreamEvents.ADDED) {
    // } else if (e === RemoteStreamEvents.REMOVED) {
    // }
  }

  private async addLocalStream() {
    this.localStream = await this.streamsService.localStream;
    await new Promise((f) => setTimeout(f, 100)); // to set localStream in iOS
    if (this.localStream?.hasVideo()) {
      const localStreamEl: any = this.localStreamMediaEl.nativeElement;
      localStreamEl.autoplay = true;
      localStreamEl.muted = true;
      this.localStream.attachToElement(localStreamEl);
      localStreamEl.load();
    }
  }

  private async removeLocalStream() {
    this.localStream = null;
  }

  async onChangeCameraSrc() {
    let currentFacingMode: VideoFacingModeEnum = await this.localStream
      ?.getCapabilities()
      ?.then((r: apiRTC.MediaStreamCapabilities) => {
        return (r?.video as MediaTrackCapabilities)?.facingMode[0] as
          | VideoFacingModeEnum
          | undefined;
      });

    const oppositeVideoDevice = this.getOppositeVideoDeviceID({currentFacingMode});

    let nextDeviceId;
    if (!oppositeVideoDevice) {
      const currentDeviceId = this.mediaDevices.default.videoInputId;
      const currentDeviceIndex = this.videoInputsArray.findIndex(
        (device) => device.id === currentDeviceId
      );

      const nextDeviceIndex =
        currentDeviceIndex < this.videoInputsArray.length - 1 ? currentDeviceIndex + 1 : 0;

      nextDeviceId = this.videoInputsArray[nextDeviceIndex].id;
    } else {
      nextDeviceId = oppositeVideoDevice.id;
    }

    this.defaultMedia = {
      ...this.defaultMedia,
      videoInputId: nextDeviceId
    };

    this.store.dispatch(
      mediaDeviceActions.setDefaultMediaDevices({
        defaultDevices: this.defaultMedia
      })
    );
  }

  private getOppositeVideoDeviceID({currentFacingMode}: {currentFacingMode: VideoFacingModeEnum}) {
    for (let i = 0; i < this.videoInputsArray.length; i++) {
      const videoInput = this.videoInputsArray[i];
      if (
        (videoInput as any)?.capabilities?.facingMode[0] &&
        (videoInput as any)?.capabilities?.facingMode[0] !== currentFacingMode
      ) {
        return videoInput;
      }
    }
    return null;
  }

  ngOnDestroy() {
    this.onDestroyed$.next(null);
    this.onDestroyed$.complete();
  }
}
