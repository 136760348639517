import {Action, createReducer, on} from '@ngrx/store';
import {UserMediaDevices} from 'src/app/core/models/media-devices.models';
import {mediaDeviceActions} from '../actions';

const mediaDeviceInitialState: UserMediaDevices = {
  audioInputs: {},
  audioOutputs: {},
  videoInputs: {},
  default: {
    audioInputId: null,
    audioOutputId: null,
    videoInputId: null
  },
  permissionGranted: null,
  constraints: null
};

export const userMediaDevicesReducer = createReducer(
  mediaDeviceInitialState,
  on(mediaDeviceActions.updateMediaDevices, (state, action) => {
    const defaultIDs = state.default;
    let micID = null;
    for (const i of Object.keys(action.devices.audioInputs)) {
      if (defaultIDs.audioInputId === i) {
        micID = i;
        break;
      }
    }

    let camID = null;
    for (const i of Object.keys(action.devices.videoInputs)) {
      if (defaultIDs.videoInputId === i) {
        camID = i;
        break;
      }
    }

    if (micID === null) {
      micID = Object.keys(action.devices.audioInputs)[0];
    }
    if (camID === null) {
      camID = Object.keys(action.devices.videoInputs)[0];
    }

    return {
      ...state,
      ...action.devices,
      default: {
        audioInputId: micID,
        videoInputId: camID,
        audioOutputId: state.default.audioOutputId
      }
    };
  }),
  on(mediaDeviceActions.setDefaultAudioInput, (state, action) => {
    let defaultId = {...state.default};
    defaultId.audioInputId = action.id;
    return {...state, default: {...defaultId}};
  }),
  on(mediaDeviceActions.setDefaultAudioOutput, (state, action) => {
    let defaultId = {...state.default};
    defaultId.audioOutputId = action.id;
    return {...state, default: {...defaultId}};
  }),
  on(mediaDeviceActions.setDefaultVideoInput, (state, action) => {
    let defaultId = {...state.default};
    defaultId.videoInputId = action.id;
    return {...state, default: {...defaultId}};
  }),
  on(mediaDeviceActions.setDefaultMediaDevices, (state, action) => ({
    ...state,
    default: {
      ...state.default,
      audioInputId: action.defaultDevices.audioInputId,
      videoInputId: action.defaultDevices.videoInputId
    }
  })),
  on(mediaDeviceActions.setMediaDevicePermission, (state, action) => ({
    ...state,
    permissionGranted: action.status
  })),
  on(mediaDeviceActions.updateStreamConstraints, (state, action) => ({
    ...state,
    constraints: {...action.constraints}
  }))
);

export function reducer(state: UserMediaDevices | undefined, action: Action) {
  return userMediaDevicesReducer(state, action);
}
