<div class="mt-2">
  <mat-checkbox
    class="w-100"
    [checked]="value"
    (change)="changedStatus($event)"
    [disabled]="disabled"
    #checkBox>
    <span class="label">
      <ng-content></ng-content>
    </span>
  </mat-checkbox>
  <mat-hint>
    <form-field-error [parentForm]="parentForm" [controlName]="controlName"></form-field-error>
  </mat-hint>
</div>
