import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AppLoaderService {
  observeRequestLoader = new Subject<{status: boolean; label?: string}>();

  constructor() {}

  showLoader(label = '') {
    this.observeRequestLoader.next({status: true, label});
  }

  hideLoader() {
    this.observeRequestLoader.next({status: false, label: ''});
  }
}
