import {Injectable} from '@angular/core';
import * as apiRTC from '@apirtc/apirtc';
import {UserAgent} from '@apirtc/apirtc';
import {Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {appStatusActions} from 'src/app/store/actions';
import {environment} from 'src/environments/environment';
import {CCSConnectionStatus, UserAgentEvents} from '../../models/apirtc.models';
import {AppState} from '../../models/app-state.models';
import {MediaDevicesService} from '../media-devices.service';

@Injectable()
export class ApiRTCService {
  private ua: UserAgent = null;

  get userAgent() {
    return this.ua;
  }

  constructor(
    private mediaDevicesService: MediaDevicesService,
    private store: Store<AppState>,
    private logger: NGXLogger
  ) {
    this.createUserAgent();
  }

  async createUserAgent() {
    if (this.ua !== null) {
      return;
    }

    this.ua = new apiRTC.UserAgent({
      uri: environment.apiRTC_key
      // apiRTCMediaDeviceDetectionEnabled: true, //This option can be use on Chrome/Android or Safari as event ondevicechange is not supported on these browsers
      // apiRTCMediaDeviceDetectionDelay: 7000,
    });

    this.ua.enableMeshRoomMode(true);

    // user agent listeners
    this.ua.on(UserAgentEvents.mediaDeviceChanged, () => {
      this.mediaDevicesService.updateMediaDeviceList(this.ua.getUserMediaDevices());
    });

    this.ua.on(UserAgentEvents.ccsConnectionStatus, (e: {status: CCSConnectionStatus}) => {
      this.store.dispatch(
        appStatusActions.userAgentStatusChanged({
          status: e.status as CCSConnectionStatus
        })
      );
      if (e.status === CCSConnectionStatus.ERROR) {
        // TODO: Connection should be manually tried to reconnect at this point
        this.logger.debug('Connection to ccs is disconnected, retry manually');
      }
    });

    this.ua.on(UserAgentEvents.externalJsLoadingStatus, (s: any) => {
      this.logger.debug('(not implemented) useragent:', UserAgentEvents.externalJsLoadingStatus, s);
    });
  }
}
