import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs';
import {environment} from '../environments/environment';
import {AppLang, SessionStorageKeys} from './core/models/app-state.models';
import {BreakpointsService} from './core/services/breakpoints.service';
import {AppLoaderService} from './core/services/loader.service';
import {appConfigActions, sessionActions} from './store/actions';
import {NGXLogger} from 'ngx-logger';
import {ThemeService} from './core/services/theme.service';
import {appConfigSelectors} from './store/selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  showloader = false;
  loader: string = '';

  /**
   * @deprecated Teledoktor/barmer will be removed, use almeda/webview
   */
  private barmerUserAgent = 'Teledoktor/barmer';
  private webviewUserAgent = 'almeda/webview';
  isDarkMode = false;

  constructor(
    private store: Store,
    private translate: TranslateService,
    private appLoaderService: AppLoaderService,
    private breakPointService: BreakpointsService,
    private titleService: Title,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private activateRoute: ActivatedRoute,
    private logger: NGXLogger,
    private themeService: ThemeService
  ) {
    translate.addLangs(['en', 'de']);
    translate.setDefaultLang('de');

    const useragent = navigator.userAgent;
    this.logger.info(useragent);
    if (useragent.includes(this.barmerUserAgent) || useragent.includes(this.webviewUserAgent)) {
      this.store.dispatch(appConfigActions.updateWebviewStatus({value: true}));
    }
    this.getiOSStatus();
    this.getAndroidStatus();
    this.addCustomMatIcons();

    this.store.select(appConfigSelectors.isDarkTheme).subscribe((r) => (this.isDarkMode = r));

    // Check if the browser prefers a dark or light color scheme
    const systemThemeisDark =
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.themeService.updateTheme(systemThemeisDark);

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => this.themeService.updateTheme(e.matches));
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit(): void {
    this.setDefaultLang();

    this.setTitle(environment.title);

    this.appLoaderService.observeRequestLoader.subscribe((value) => {
      this.loader = value.label;
      this.showloader = value.status;
    });
  }

  ngAfterViewInit(): void {
    this.activateRoute.queryParams.pipe(filter((params) => params.lang)).subscribe((params) => {
      if (params?.lang === AppLang.de || params?.lang === AppLang.en) {
        this.store.dispatch(sessionActions.changeLang({lang: params.lang}));
      }
    });
  }

  private setDefaultLang(): void {
    const sessionLang: AppLang =
      sessionStorage.getItem(SessionStorageKeys.LANG) == AppLang.en ? AppLang.en : AppLang.de;
    this.store.dispatch(sessionActions.changeLang({lang: sessionLang}));
  }

  getiOSStatus() {
    const userAgent = navigator.userAgent || navigator?.vendor;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      this.store.dispatch(appConfigActions.iOSStatus({value: true}));
      return true;
    }
    this.store.dispatch(appConfigActions.iOSStatus({value: false}));
    return false;
  }

  getAndroidStatus() {
    const userAgent = navigator.userAgent || navigator?.vendor;
    let isAndroid = false;
    if (/Android/i.test(userAgent)) {
      isAndroid = true;
    }
    this.store.dispatch(appConfigActions.androidStatus({value: isAndroid}));
    return isAndroid;
  }

  private addCustomMatIcons() {
    this.matIconRegistry.addSvgIcon(
      'alm_language',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/svg/icons/alm_language.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'user_offline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/svg/icons/circle_false.svg')
    );

    this.matIconRegistry.addSvgIcon(
      'user_online',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/svg/icons/circle_ok.svg')
    );
  }

  ngOnDestroy() {}
}
