import {Session} from 'src/app/core/models/session.models';
import {createReducer, on, Action} from '@ngrx/store';
import {sessionActions} from '../actions';
import {AppLang, SessionStorageKeys} from 'src/app/core/models/app-state.models';

const sessionInitialState: Session = {
  lang: AppLang.de,
  header: true
};

export const sessionReducer = createReducer(
  sessionInitialState,
  on(sessionActions.changeLang, (state, action) => {
    window.sessionStorage.setItem(SessionStorageKeys.LANG, action.lang);
    return {...state, lang: action.lang};
  }),
  on(sessionActions.headerStatus, (state, action) => {
    window.sessionStorage.setItem(SessionStorageKeys.HEADER, String(action.status));
    const status =
      typeof action.status == 'boolean' ? action.status : action.status === 'true' ? true : false;
    return {...state, header: status};
  })
);

export function reducer(state: Session | undefined, action: Action) {
  return sessionReducer(state, action);
}
