import {ClientTokens} from './app-config.models';
import {DefaultListParams} from './app-state.models';
import {User} from './user.models';

export interface AppointmentSessionDetails {
  token?: string;
  doctor?: User;
  patient?: User;
  appointment?: Appointment;
}

export interface Appointment {
  uuid?: string;
  creator_uid?: string;
  client_uuid?: string;
  schedule?: string;
  duration?: number;
  require_participant_consent?: boolean;
  participants?: User[];
  appointment_messages?: AppointmentMessage[];
  status?: AppointmentStatus;
  photo_upload_url?: string | null;
  photo_count?: number | null;
  session_type?: AppointmentSessionTypes | null;
  cancel_url?: string | null;
  configuration?: AppointmentConfiguration;
  created_at?: string;
  updated_at?: string | null;
  tokens?: {
    apirtc: string;
  };
}

export enum AppointmentSessionTypes {
  DERMA = 'derma',
  CHILD = 'child'
}

export enum AppointmentStatus {
  'CANCELLED' = 'cancelled',
  'SCHEDULED' = 'scheduled',
  'COMPLETED' = 'completed',
  'IN_PROGRESS' = 'in_progress'
}

export interface AppointmentConfiguration {
  name?: string | null;
  token?: ClientTokens | null;
  logo_url?: string | null;
  footer?: AppointmentFooterConfigurations;
  agent_image_url?: string | null;
  hotline_country_code?: string | null;
  hotline?: string | null;
  allow_doc_download?: boolean;
  allow_frame_close?: boolean;
  photo_upload_btn?: boolean;
  consent_page?: boolean;
}

export enum AppointmentFooterConfigurations {
  DEFAULT = 'default',
  INVISIBLE = 'invisible',
  CUSTOM = 'custom'
}

export interface AppointmentMessage {
  id?: number;
  uuid?: string;
  appointment_uuid?: string;
  message?: string | null;
  read_at?: string | null;
  created_at?: string;
  updated_at?: string | null;
  deleted_at?: string | null;
}

export interface AppointmentPatientSession extends Appointment {
  participants: Omit<User, 'notifications'>[];
  tokens: {
    notifier: string;
    apirtc: string;
  };
}

export interface AppointmentDoctorSession extends Appointment {}

export interface AppointmentLog {
  uuid: string;
  appointment_uuid: string;
  conversation_id: string;
  user_uuid: string;
  start: string;
  end: string | null;
  video_rating: number | null;
  audio_rating: number | null;
  appointment: Omit<Appointment, 'appointment_message'> & {
    doctor?: User;
    patient?: User;
  };
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export enum AppointmentLogSortableColumns {
  created_at = 'created_at',
  updated_at = 'updated_at'
}

export interface AppointmentLogList extends DefaultListParams<AppointmentLogSortableColumns> {
  appointment_logs: Array<AppointmentLog>;
  offset?: number;
  limit?: number;
  sort_by?: string;
  sortable_columns?: AppointmentLogSortableColumns;
  total?: number;
  order?: string;
  filters?: {
    user_uuid?: string;
    search?: string;
  };
}
