import {AppState} from 'src/app/core/models/app-state.models';
import {createSelector} from '@ngrx/store';
import {UserMediaDevices} from 'src/app/core/models/media-devices.models';

export const selectUserMediaDevices = createSelector(
  (state: AppState) => state.mediaDevices,
  (mediaDevices: UserMediaDevices) => mediaDevices
);

export const selectVideoInputDevices = createSelector(
  selectUserMediaDevices,
  (media) => media.videoInputs
);

export const selectAudioInputDevices = createSelector(
  selectUserMediaDevices,
  (media) => media.audioInputs
);

export const selectAudioOutputDevices = createSelector(
  selectUserMediaDevices,
  (media) => media.audioOutputs
);

export const selectDefaultDevices = createSelector(
  selectUserMediaDevices,
  (media) => media.default
);

export const selectMediaConstraints = createSelector(
  selectUserMediaDevices,
  (media) => media.constraints
);
