<mat-form-field appearance="standard" class="w-100 mb-3">
  <mat-label>{{ Label }}</mat-label>
  <input
    matInput
    [type]="type"
    [placeholder]="placeholder"
    autocomplete="{{ autocomplete }}"
    [value]="value"
    [disabled]="disabled"
    (input)="onInput($event)"
    (blur)="touched()"
    [matAutocomplete]="inputSuggestions"
    #inputEl
    [maxLength]="maxLength" />
  <section class="icons" matSuffix>
    <mat-icon class="checkIcon" *ngIf="icon"> {{ icon }} </mat-icon>
    <mat-icon *ngIf="isPassword" class="passwordStatusBtn" (click)="togglePassword()">
      <span *ngIf="type === 'password'">visibility</span>
      <span *ngIf="type === 'text'">visibility_off</span>
    </mat-icon>
  </section>
  <mat-hint>
    <ng-container *ngIf="!getControl?.errors || getControl?.touched === false">{{
      hint
    }}</ng-container>
    <form-field-error [parentForm]="parentForm" [controlName]="controlName"></form-field-error>
  </mat-hint>
</mat-form-field>

<mat-autocomplete #inputSuggestions="matAutocomplete" (optionSelected)="optionSelected($event)">
  <mat-option *ngFor="let option of filteredOptions" [value]="option.value">{{
    option.label
  }}</mat-option>
</mat-autocomplete>
