import {Injectable} from '@angular/core';
import {Contact} from '@apirtc/apirtc';
import {Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {UserDataExtended} from 'src/app/core/models/apirtc.models';
import {AppState} from 'src/app/core/models/app-state.models';
import {OnlineParticipants} from 'src/app/core/models/participants.models';
import {onlineParticipantsActions} from 'src/app/store/actions';

@Injectable()
export class ContactsUpdateService {
  constructor(private logger: NGXLogger, private store: Store<AppState>) {}

  updateOnlineContacts(e: Contact[]) {
    let onlineParticipants: OnlineParticipants = {};
    e.forEach((c) => {
      if (!onlineParticipants[c.getUserData().get('uuid')]) {
        onlineParticipants[c.getUserData().get('uuid')] = {};
      }
      onlineParticipants[c.getUserData().get('uuid')][c.getId()] =
        c.getUserData() as UserDataExtended;
    });
    this.store.dispatch(onlineParticipantsActions.participantIdList({onlineParticipants}));
  }
}
