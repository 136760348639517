export enum HTMLAutocomplete {
  off = 'off',
  on = 'on',
  name = 'name',
  honorificPrefix = 'honorific-prefix',
  givenName = 'given-name',
  additionalName = 'additional-name',
  familyName = 'family-name',
  honorificSuffix = 'honorific-suffix',
  nickname = 'nickname',
  organizationTitle = 'organization-title',
  username = 'username',
  newPassword = 'new-password',
  currentPassword = 'current-password',
  oneTimeCode = 'one-time-code',
  organization = 'organization',
  streetAddress = 'street-address',
  addressLine1 = 'address-line1',
  addressLine2 = 'address-line2',
  addressLine3 = 'address-line3',
  addressLevel4 = 'address-level4',
  addressLevel3 = 'address-level3',
  addressLevel2 = 'address-level2',
  addressLevel1 = 'address-level1',
  country = 'country',
  countryName = 'country-name',
  postalCode = 'postal-code',
  ccName = 'cc-name',
  ccGivenname = 'cc-given-name',
  ccAdditionalname = 'cc-additional-name',
  ccFamilyname = 'cc-family-name',
  ccNumber = 'cc-number',
  ccExp = 'cc-exp',
  ccExpmonth = 'cc-exp-month',
  ccExpyear = 'cc-exp-year',
  ccCsc = 'cc-csc',
  ccType = 'cc-type',
  transactionCurrency = 'transaction-currency',
  transactionAmount = 'transaction-amount',
  language = 'language',
  bday = 'bday',
  bdayDay = 'bday-day',
  bdayMonth = 'bday-month',
  bdayYear = 'bday-year',
  sex = 'sex',
  url = 'url',
  photo = 'photo',
  tel = 'tel',
  telCountryCode = 'tel-country-code',
  telNational = 'tel-national',
  telAreaCode = 'tel-area-code',
  telLocal = 'tel-local',
  telLocalPrefix = 'tel-local-prefix',
  telLocalSuffix = 'tel-local-suffix',
  telExtension = 'tel-extension',
  email = 'email',
  impp = 'impp'
}
