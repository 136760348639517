<div class="wrapper">
  <ng-container *ngIf="msg.chatType === ChatType.image">
    <img [src]="msg.msg | urlSafe: 'url'" alt="" srcset="" />
    <a
      [href]="msg.msg | urlSafe: 'url'"
      target="_blank"
      download="{{ msg.fileName }}"
      (click)="requestMobileDownload(msg)"
      class="mt-2">
      <p class="sr-only">{{ "CHAT_FILE.file" | translate }}</p
      >{{ msg.fileName }}
    </a>
    <ng-container
      *ngTemplateOutlet="progressTemp; context: {progress: msg.transferProgress}"></ng-container>
  </ng-container>

  <ng-container *ngIf="msg.chatType === ChatType.file">
    <div class="d-flex flex-column">
      <a
        class="fileType"
        [href]="msg.msg | urlSafe: 'url'"
        target="_blank"
        download="{{ msg.fileName }}"
        (click)="requestMobileDownload(msg)">
        <p class="sr-only">{{ "CHAT_FILE.file" | translate }}</p
        >{{ msg.fileName }}
        <mat-icon class="ml-2">insert_drive_file</mat-icon>
      </a>
      <ng-container
        *ngTemplateOutlet="progressTemp; context: {progress: msg.transferProgress}"></ng-container>
    </div>
  </ng-container>
</div>

<ng-template #progressTemp let-progress="progress">
  <div class="progressBar mt-2" *ngIf="progress < 100">
    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
  </div>
</ng-template>
