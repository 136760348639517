import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import {exhaustMap, map, switchMap, take} from 'rxjs/operators';
import {mediaDeviceActions} from 'src/app/store/actions';
import {Store} from '@ngrx/store';
import {AppState} from 'src/app/core/models/app-state.models';
import {appSelectors} from '../selectors';
import {AppStreamsService} from 'src/app/core/services/streams.service';

@Injectable()
export class UserMediaDevicesEffects {
  private mediaDevicesUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mediaDeviceActions.updateMediaDevices),
      exhaustMap((action) =>
        this.store.select(appSelectors.userMediaDevicesSelectors.selectUserMediaDevices).pipe(
          take(1),
          switchMap((d) => {
            let micID = this.getDeviceId(Object.keys(d.audioInputs), null, d.default.audioInputId);
            let camID = this.getDeviceId(Object.keys(d.videoInputs), null, d.default.videoInputId);

            return [
              mediaDeviceActions.setDefaultAudioInput({id: micID}),
              mediaDeviceActions.setDefaultVideoInput({id: camID})
            ];
          })
        )
      )
    )
  );
  private enableAudio$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mediaDeviceActions.enableLocalStreamAudio),
        map((action) => this.streamsService.enableLocalStreamAudio())
      ),
    {dispatch: false}
  );

  private disableAudio$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mediaDeviceActions.disableLocalStreamAudio),
        map((action) => this.streamsService.disableLocalStreamAudio())
      ),
    {dispatch: false}
  );

  private enableVideo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mediaDeviceActions.enableLocalStreamVideo),
        map((action) => this.streamsService.enableLocalStreamVideo())
      ),
    {dispatch: false}
  );

  private disableVideo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mediaDeviceActions.disableLocalStreamVideo),
        map((action) => this.streamsService.disableLocalStreamVideo())
      ),
    {dispatch: false}
  );

  private getDeviceId(
    ids: Array<string>,
    settingsId: string | any,
    defaultId: string | any
  ): string {
    let id = ids.indexOf(settingsId) >= 0 ? settingsId : null;
    id = id || defaultId || (!id || id === 'NA' ? ids[0] : id);

    return id;
  }

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private streamsService: AppStreamsService
  ) {}
}
