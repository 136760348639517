import {createReducer, on, Action} from '@ngrx/store';
import {onlineParticipantsActions} from '../actions';
import {OnlineParticipants} from 'src/app/core/models/participants.models';

const initialState: OnlineParticipants = {};

export function reducer(state: OnlineParticipants, action: Action) {
  return createReducer(
    initialState,
    on(onlineParticipantsActions.participantIdList, (state, action) => ({
      ...action.onlineParticipants
    }))
  )(state, action);
}
