<div class="banner">
  <p
    [innerHTML]="
      'DOCTOR_BANNER.text'
        | translate
          : {
              privacy_doctor: urls.privacy
            }
    "></p>
</div>
